import { useContext, Suspense } from 'react';
import PageLayout from 'components/layout';
import { DataGrid } from 'components/tables';
import {
  StyledGrid,
  Container,
  StyledTypography,
} from 'routes/sources-page/sources-content/sources-page-styles';
import { Grid } from '@mui/material';
import { formatDate } from 'utils';
import { capitalize } from 'lodash';
import { SourcesContext } from 'context';
import GlobalSourcesTitle from '../sources-titles/global-sources-title';

function GlobalSourcesTable() {
  const { isLoading, globalSources } = useContext(SourcesContext);
  const sourcesColumns = [
    {
      flex: 0.35,
      field: 'title',
      headerName: 'Source Name',
      type: 'string',
      valueGetter: ({ value }) => capitalize(value),
    },
    {
      flex: 0.2,
      field: 'reference',
      headerName: 'Reference',
      hideable: false,
      valueGetter: ({ value }) => value,
    },
    {
      flex: 0.2,
      field: 'sourceType',
      headerName: 'Source Type',
      hideable: false,
      valueGetter: ({ value }) => value.name,
    },
    {
      flex: 0.15,
      field: 'dateDay',
      headerName: 'Publication/Revision',
      hideable: false,
      valueGetter: params => {
        if (
          params.row.dateDay === null ||
          params.row.dateMonth === null ||
          params.row.dateYear === null
        ) {
          return 'Invalid date';
        }
        return `${formatDate(
          `${params.row.dateYear}-${
            params.row.dateMonth > 9
              ? params.row.dateMonth
              : `0${params.row.dateMonth}`
          }-${params.row.dateDay}`,
          undefined
        )}`;
      },
    },
    {
      flex: 0.15,
      field: 'createdAt',
      headerName: 'Source created',
      hideable: false,
      valueGetter: ({ value }) => formatDate(value, undefined),
    },
    {
      flex: 0.15,
      field: 'updatedAt',
      headerName: 'Last modified',
      hideable: false,
      valueGetter: ({ value }) => formatDate(value, undefined),
    },
  ];

  const dataGridHeight = window.innerHeight - 142;

  return (
    <PageLayout ariaLabel="sources-page">
      <Container>
        <StyledGrid item>
          <GlobalSourcesTitle />
        </StyledGrid>
        <StyledTypography>
          Select and import sources to this project
        </StyledTypography>
        <Grid item sx={{ flex: 1, overflow: 'auto' }}>
          <Suspense fallback={null}>
            <DataGrid
              sx={{
                borderRadius: 2,
                marginTop: theme => theme.spacing(0.5),
                marginLeft: theme => theme.spacing(2),
                marginRight: theme => theme.spacing(2),
              }}
              loading={isLoading}
              density="standard"
              columns={sourcesColumns}
              rows={globalSources}
              rowHeight={32}
              height={dataGridHeight}
              aria-label="sources-management-table"
              noRowsMessage="No Sources Found"
              onRowClick={() => {}}
              columnBuffer={10}
              searchToolbar
            />
          </Suspense>
        </Grid>
      </Container>
    </PageLayout>
  );
}

GlobalSourcesTable.propTypes = {};

export default GlobalSourcesTable;
