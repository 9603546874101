import PT from 'prop-types';
import { Add, Clear } from '@mui/icons-material';
import { Grid, Skeleton } from '@mui/material';
import { formatTitle } from 'utils';
import TagChip from 'components/chips/tag-chip';
import SimpleMenu from 'components/menu';
import WarningDialog from 'components/dialogs/warning';
import { useContext } from 'react';
import { ThemeContext } from 'context';
import { capitalize } from 'lodash';
import useTags from './hooks/use-tags';

function Tags({
  referenceTable,
  title,
  tagType,
  existingTags,
  setExistingTags,
  id,
  globalTags,
  loading,
  permission,
}) {
  const {
    addTag,
    deleteTag,
    anchorEl,
    setAnchorEl,
    openMenu,
    deleteDiscipline,
    addDiscipline,
    deleteTagWarning,
    setDeleteTagWarning,
  } = useTags(existingTags, setExistingTags, id, referenceTable);
  const { localTheme } = useContext(ThemeContext);

  const parameterTagsPerType = () =>
    existingTags.filter(tag => tag?.tagType?.id === tagType?.id);

  const availableTags = () =>
    globalTags?.filter(globalTag => {
      return !parameterTagsPerType().some(
        existingTag => existingTag.id === globalTag.id
      );
    });

  if (loading) return <Skeleton width="100%" height={30} />;

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item data-testid="tag-type-holder">
        <TagChip
          palette={localTheme}
          label={`${capitalize(formatTitle(tagType.name).toLowerCase())} Tag`}
          tagType={tagType.name}
          holder
        />
      </Grid>
      {parameterTagsPerType().map(tag => (
        <Grid item key={tag.name} data-testid="parameter-tag">
          {permission ? (
            <TagChip
              palette={localTheme}
              label={tag.name}
              tagType={tagType.name}
              onDelete={() => {
                setDeleteTagWarning({ open: true, tag });
              }}
              deleteIcon={<Clear aria-label="delete-tag" />}
            />
          ) : (
            <TagChip
              palette={localTheme}
              label={tag.name}
              tagType={tagType.name}
            />
          )}
        </Grid>
      ))}
      {permission && (
        <Grid item data-testid="add-tag-chip">
          <TagChip
            palette={localTheme}
            icon={<Add />}
            onClick={openMenu}
            tagType="addTag"
          />
        </Grid>
      )}
      <Grid item data-testid="available-tag-menu">
        <SimpleMenu
          aria-label="available-tag-menu"
          options={availableTags()}
          handleSelection={title !== 'disciplines' ? addTag : addDiscipline}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
      </Grid>
      {deleteTagWarning.open && (
        <WarningDialog
          open={deleteTagWarning.open}
          handleAction={() => {
            if (title !== 'disciplines') {
              deleteTag(deleteTagWarning.tag);
            } else {
              deleteDiscipline(deleteTagWarning.tag);
            }
            setDeleteTagWarning({ open: false });
          }}
          handleClose={() => {
            setDeleteTagWarning({ open: false });
          }}
          title={`Delete tag ${tagType.name}`}
          body="Are you sure you want to remove this tag?"
          primaryButtonText="Accept"
          secondaryButton
          secondaryButtonText="Cancel"
        />
      )}
    </Grid>
  );
}

Tags.propTypes = {
  permission: PT.bool,
  referenceTable: PT.string,
  tagType: PT.shape({ name: PT.string.isRequired, id: PT.string }),
  title: PT.string,
  existingTags: PT.arrayOf(
    PT.oneOfType([
      PT.shape({
        id: PT.string.isRequired,
        name: PT.string.isRequired,
        tagType: PT.shape({
          id: PT.string.isRequired,
          name: PT.string.isRequired,
        }).isRequired,
      }),
      PT.shape({ name: PT.string.isRequired }),
    ])
  ).isRequired,
  setExistingTags: PT.func.isRequired,
  loading: PT.bool.isRequired,
  id: PT.string,
  globalTags: PT.arrayOf(
    PT.shape({ name: PT.string.isRequired, id: PT.string.isRequired })
  ).isRequired,
};

Tags.defaultProps = {
  referenceTable: 'parameter',
  title: '',
  tagType: PT.shape({ id: '' }),
  id: '',
  permission: true,
};

export default Tags;
