import PT from 'prop-types';
import { Add } from '@mui/icons-material';
import { isEmpty } from 'lodash';
import { parameterType } from 'types';
import FinalForm from 'components/forms';
import { Fab } from 'components/buttons';
import {
  Autocomplete,
  DatePicker,
  Select,
  TextField,
} from 'components/pickers/final-form-fields';
import getOptionLabel from 'routes/parameters-page/helpers/get-option-label';
import useEditEntry from './hooks/use-edit-entry';

const EditEntry = ({
  parameter,
  setParameter,
  setOpenEditingForm,
  setOpenAddSource,
  selectedSource,
  setError: setActionError,
  setSelectedSource,
  formValues,
  setFormValues,
  heldFormValues,
  setHeldFormValues,
}) => {
  const {
    unitTypes,
    sendUpdatedValues,
    allSources,
    isLoading,
    areSourcesLoading,
    optionsLoading,
    valueOptions,
    validateEditing,
    error,
    setError,
  } = useEditEntry(setParameter, parameter, setOpenEditingForm, setActionError);

  const getValueFormField = () => {
    if (valueOptions.length) {
      return {
        size: 8,
        field: (
          <Autocomplete
            label="Value"
            options={[...valueOptions]}
            autoFocus
            required
            name="value"
            clearOnBlur={false}
            aria-label="select-value"
            key={option => option.value}
            loading={optionsLoading}
            getOptionValue={option => option.value}
            getOptionLabel={option => option.value}
          />
        ),
      };
    }
    if (parameter?.parameterType?.dataType === 'boolean') {
      return {
        size: 8,
        field: (
          <Select
            autoFocus
            aria-label="select-boolean"
            label="New value"
            name="value"
            options={[
              { id: true, name: 'True' },
              { id: false, name: 'False' },
            ]}
            getOptionValue={option => option.value}
            getOptionLabel={option => option.value}
          />
        ),
      };
    }
    if (parameter?.parameterType?.dataType === 'date') {
      return {
        size: 8,
        field: (
          <DatePicker
            autoFocus
            aria-label="select-date"
            placeholder="use calendar to select a date"
            label="Value"
            name="value"
          />
        ),
      };
    }
    return {
      size: 8,
      type: parameter.parameterType.dataType,

      field: <TextField label="New value" autoFocus name="value" type="text" />,
    };
  };

  const valueFormField = getValueFormField();
  const initialValues = {
    value:
      heldFormValues.value || heldFormValues.value === false
        ? heldFormValues.value
        : parameter?.selectedEntry?.values[0]?.value || '',
    unitId:
      heldFormValues?.unitId || parameter?.selectedEntry?.values[0]?.unit?.id,
    sourceId:
      selectedSource?.id || parameter?.selectedEntry?.source?.id || null,
    comment: heldFormValues.comment || '',
  };
  return (
    <FinalForm
      aria-label="edit-entry-form"
      isLoading={isLoading || areSourcesLoading || optionsLoading}
      title="Edit Parameter"
      initialValues={initialValues}
      selectedSourceId={selectedSource?.id}
      formFields={[
        valueFormField,
        {
          size: 4,
          field: !isEmpty(unitTypes) && (
            <Select
              label="Select unit"
              name="unitId"
              options={unitTypes}
              required
            />
          ),
        },
        {
          size: 9,
          field: (
            <Autocomplete
              label="Source of new value"
              required
              name="sourceId"
              options={allSources}
              getOptionValue={source => source.id}
              groupBy={option => option.sourceType.name}
              getOptionLabel={getOptionLabel}
              onInputChange={(event, value) => {
                // eslint-disable-next-line no-unused-expressions
                value ? setError(false) : setError(true);
              }}
              clearOnBlur={false}
              error={error}
              helperText={error ? 'Required' : ''}
            />
          ),
        },
        {
          size: 3,
          field: (
            <Fab
              title="Add a new source"
              variant="circular"
              size="small"
              style={{ transform: 'scale(0.8)' }}
              aria-label="Add"
              onClick={() => {
                setHeldFormValues({ ...formValues });
                setOpenAddSource(true);
                setOpenEditingForm(false);
              }}
            >
              <Add />
            </Fab>
          ),
        },
        {
          size: 12,
          field: <TextField label="Comment" name="comment" />,
        },
      ]}
      validate={validateEditing}
      onClose={() => {
        setOpenEditingForm(false);
        setActionError(undefined);
        setHeldFormValues({});
        setSelectedSource(parameter.selectedEntry?.source || {});
      }}
      onSubmit={sendUpdatedValues}
      onChange={props => {
        // eslint-disable-next-line react/prop-types
        if (props.dirty) {
          // eslint-disable-next-line react/prop-types
          setFormValues({ ...props.values });
        }
      }}
    />
  );
};

EditEntry.propTypes = {
  parameter: parameterType.isRequired,
  setParameter: PT.func.isRequired,
  setOpenEditingForm: PT.func.isRequired,
  setOpenAddSource: PT.func.isRequired,
  setError: PT.func.isRequired,
  selectedSource: PT.shape({ id: PT.string }),
  setSelectedSource: PT.func.isRequired,
  formValues: PT.shape({
    value: PT.string || PT.bool,
    sourceId: PT.string,
    unitId: PT.string,
    comment: PT.string,
  }),
  setFormValues: PT.func.isRequired,
  heldFormValues: PT.shape({
    value: PT.string || PT.bool,
    sourceId: PT.string,
    unitId: PT.string,
    comment: PT.string,
  }),
  setHeldFormValues: PT.func.isRequired,
};

EditEntry.defaultProps = {
  selectedSource: {},
  formValues: {},
  heldFormValues: {},
};

export default EditEntry;
