import { Typography, Grid, Box } from '@mui/material';
import { BasicDropdown } from 'components/pickers';
import { useContext } from 'react';
import Alert from 'components/alerts';
import { ReportingContext } from 'context';

const SelectReport = () => {
  const { templates, isLoading, error, setReport, report } =
    useContext(ReportingContext);

  return (
    <Grid container direction="column" alignContent="left" spacing={2} pt={5}>
      <Grid item container direction="row" alignItems="center">
        <Typography variant="h5">
          Select template for report generation
        </Typography>
        <Grid
          sx={{ marginLeft: 'auto' }}
          color="primary"
          display="flex"
          alignItems="center"
        >
          <Typography variant="h6" paddingRight={1}>
            Powered by
          </Typography>
          <img src="/logos/hotdocs.png" alt="hotdocs logo" />
        </Grid>
      </Grid>
      <Grid item pb={1}>
        <Typography color="GrayText" sx={{ width: '60%' }}>
          Hotdocs templates must be live and linked to DDB work group in Hotdocs
          Advance server.
        </Typography>
      </Grid>
      {error && (
        <Grid item>
          <Box>
            <Alert
              title={
                error.name === 'ApiError' && !error.msg
                  ? error.name
                  : error?.msg
              }
              text={
                error.name === 'ApiError' && !error.msg
                  ? 'Automated Reporting is currently unavailable for large project datasets due to Hotdocs server limitations. We are working to resolve this'
                  : error?.details
              }
            />
          </Box>
        </Grid>
      )}
      <Grid item>
        <BasicDropdown
          loading={isLoading}
          value={report?.name ? report : {}}
          onChange={(e, value, reason) => {
            if (reason === 'clear') setReport({});
            else {
              setReport(value);
            }
          }}
          variant="outlined"
          options={templates}
          getOptionLabel={template => (template?.name ? template?.name : '')}
          getOptionValue={template => template}
          isOptionEqualToValue={(option, value) => option?.name === value?.name}
          data-testid="reportTemplateDropdown"
        />
      </Grid>
    </Grid>
  );
};

export default SelectReport;
