/* eslint-disable no-nested-ternary */
import PT from 'prop-types';
import TextField from 'components/pickers/final-form-fields/text-field';
import { parameterType } from 'types';
import FinalForm from 'components/forms';
import useCommentForm from './hooks/use-reject-entry';

const CommentForm = ({
  parameter,
  setParameter,
  setOpenCommentForm,
  setError,
  formType,
}) => {
  const { validateRejection, rejectParameterValue } = useCommentForm(
    parameter,
    setParameter,
    setOpenCommentForm,
    setError,
    formType
  );
  return (
    <FinalForm
      title="Add Comment"
      initialValues={{ comment: '' }}
      formFields={[
        {
          size: 12,
          field: (
            <TextField
              label={
                formType === 'reject'
                  ? 'Reason Rejected'
                  : formType === 'reset'
                  ? 'Reason to reset to answered'
                  : formType === 'approve'
                  ? 'Optional approval comment'
                  : 'Optional check comment'
              }
              name="comment"
              required={formType === 'reset' || formType === 'reject'}
            />
          ),
        },
      ]}
      validate={validateRejection}
      onClose={() => {
        setOpenCommentForm(false);
        setError(undefined);
      }}
      onSubmit={rejectParameterValue}
      enableSubmitButton={formType !== 'reject'}
    />
  );
};

CommentForm.propTypes = {
  parameter: parameterType.isRequired,
  setOpenCommentForm: PT.func.isRequired,
  setParameter: PT.func.isRequired,
  setError: PT.func.isRequired,
  formType: PT.string.isRequired,
};

export default CommentForm;
