import { Checkbox, Button, Grid, Tooltip } from '@mui/material';
import PT from 'prop-types';
import { FieldArray } from 'react-final-form-arrays';
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from '@mui/icons-material';
import { BasicDropdown, ListBoxComponent } from 'components/pickers';
import BasicTable from 'components/tables/table';
import { isEmpty } from 'lodash';
import useAddTags from './hooks/use-add-tags';

const AddTags = ({ values }) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const {
    addTagsToSelectedParameters,
    formatFields,
    formatHeaders,
    setTags,
    setDisciplines,
    allTags,
    allDisciplines,
    disciplines,
    tags,
  } = useAddTags(values);

  return (
    <>
      <Grid container direction="column" spacing={1}>
        <Grid item xs>
          <BasicDropdown
            data-cy="select-tags"
            size="small"
            disableCloseOnSelect
            multiple
            ListboxComponent={ListBoxComponent}
            renderGroup={params => params}
            aria-label="select-tags"
            value={tags}
            options={allTags}
            onChange={(e, value) => setTags(value)}
            getOptionLabel={option => option.name}
            groupBy={option => option.tagType.name}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            label="Select tags"
            ChipProps={{ color: 'default' }}
            renderOption={(optionProps, option, { selected }) => (
              <li key={option.id} {...optionProps}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8, backgroundColor: 'primary' }}
                  checked={selected}
                />
                {option.name}
              </li>
            )}
          />
        </Grid>
        <Grid item xs>
          <BasicDropdown
            data-cy="select-disciplines"
            size="small"
            disableCloseOnSelect
            multiple
            ListboxComponent={ListBoxComponent}
            value={disciplines}
            aria-label="select-disciplines"
            options={allDisciplines}
            onChange={(e, value) => setDisciplines(value)}
            getOptionLabel={option => `${option.name} - ${option.code}`}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            label="Select disciplines"
            ChipProps={{ color: 'default' }}
            renderOption={(optionProps, option, { selected }) => (
              <li key={option.id} {...optionProps}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8, backgroundColor: 'primary' }}
                  checked={selected}
                />
                {`${option.name} - ${option.code}`}
              </li>
            )}
          />
        </Grid>
      </Grid>
      <FieldArray name="parameterTypes" aria-label="add-tags-step">
        {({ fields }) => {
          const currentFields = fields.value;
          const data = formatFields(currentFields, fields);
          const headers = formatHeaders(fields);
          return (
            <>
              <Grid container item justifyContent="flex-end">
                <Tooltip
                  title={
                    // eslint-disable-next-line no-nested-ternary
                    isEmpty(tags) && isEmpty(disciplines)
                      ? 'Select Tags'
                      : currentFields.some(param => param.checked)
                      ? 'Add'
                      : 'Select Parameter to add tags'
                  }
                >
                  <span>
                    <Button
                      data-cy="add-tags"
                      sx={{ margin: theme => theme.spacing(1, 0) }}
                      aria-label="add-button"
                      variant="contained"
                      color="primary"
                      disabled={
                        !currentFields.some(param => param.checked) ||
                        (isEmpty(tags) && isEmpty(disciplines))
                      }
                      onClick={() =>
                        addTagsToSelectedParameters(currentFields, fields)
                      }
                    >
                      Add
                    </Button>
                  </span>
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <BasicTable
                  headers={headers}
                  data={data}
                  dense
                  skippingHeaders={['status']}
                />
              </Grid>
            </>
          );
        }}
      </FieldArray>
    </>
  );
};

AddTags.propTypes = {
  values: PT.shape({ parameterTypes: PT.arrayOf(PT.shape({})) }).isRequired,
};

export default AddTags;
