import { BugReport as BugReportIcon } from '@mui/icons-material';
import PT from 'prop-types';
import { MenuItem, Typography, Button, useMediaQuery } from '@mui/material';
import config from 'config';
import { StyledIconButton } from '../../../buttons';

function Bugs({ title, handleClose }) {
  const isSmallScreen = useMediaQuery('(max-width:1100px)');

  return (
    <>
      {title && (
        <MenuItem
          component="a"
          color="inherit"
          data-cy="bug-button"
          onClick={() => {
            window.open(config.url.bugs, '_blank');
            handleClose();
          }}
          aria-label="Report Issue"
        >
          <BugReportIcon data-testid="bug" />
          <Typography
            variant="button"
            sx={{ paddingLeft: theme => theme.spacing(1) }}
          >
            {title}
          </Typography>
        </MenuItem>
      )}
      {!title && isSmallScreen && (
        <StyledIconButton
          title="Report Issue"
          aria-label="Report Issue"
          data-cy="bug-button"
          component="a"
          onClick={() => window.open(config.url.bugs, '_blank')}
          icon={<BugReportIcon data-testid="feedback" />}
        />
      )}
      {!title && !isSmallScreen && (
        <Button
          title="Report Issue"
          component="a"
          onClick={() => window.open(config.url.bugs, '_blank')}
          aria-label="Report Issue"
          data-cy="bug-button"
          startIcon={<BugReportIcon data-testid="bug" />}
        >
          REPORT ISSUE
        </Button>
      )}
    </>
  );
}

Bugs.propTypes = {
  title: PT.string,
  handleClose: PT.func,
};

Bugs.defaultProps = {
  title: null,
  handleClose: null,
};

export default Bugs;
