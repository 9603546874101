import { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useImmer } from 'use-immer';
import { isEmpty } from 'lodash';
import { referenceDataApi, handleApiError } from 'api';
import config from 'config';

const useAddSource = (
  openAddSource,
  setOpenAddSource,
  setOpenEditingForm,
  setSelectedSource
) => {
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [allSourceTypes, setAllSourceTypes] = useImmer([]);
  const { projectId } = useParams();
  const baseUrl = config.url.api.base;
  useEffect(() => {
    let didCancel = false;
    const source = axios.CancelToken.source();

    const getSources = async after => {
      const query = {
        visible_only: true,
        sort_by: 'name',
        order: 'asc',
      };
      if (after) query.after = after;
      const { sourceTypes, paging } = await referenceDataApi(
        'getSourceTypes',
        query,
        source.token
      ).catch(handleApiError);
      if (!didCancel && sourceTypes) {
        setAllSourceTypes(curr => [...curr, ...sourceTypes]);
      }
      if (paging?.cursors?.after) getSources(paging?.cursors?.after);
    };
    if (openAddSource && isEmpty(allSourceTypes)) getSources();
    return () => {
      didCancel = true;
      source.cancel();
    };
  }, [openAddSource, allSourceTypes, setAllSourceTypes]);

  const validateAddSourceInputs = values => {
    const errors = {};
    if (!values.title) {
      errors.title = 'Required';
    }
    if (!values.reference) {
      errors.reference = 'Required';
    }
    if (!values.sourceType) {
      errors.sourceType = 'Required';
    }
    if (!values.publicationDate) {
      errors.publicationDate = 'Required';
    }
    if (
      values?.publicationDate &&
      !(
        values?.publicationDate instanceof Date &&
        // eslint-disable-next-line no-restricted-globals
        !isNaN(values?.publicationDate)
      )
    ) {
      errors.publicationDate = 'Invalid Date';
    }
    if (
      values?.publicationDate &&
      values?.publicationDate instanceof Date &&
      values?.publicationDate?.getFullYear() < 1000
    ) {
      errors.publicationDate = 'Invalid Date';
    }

    if (
      !values.url &&
      values.sourceType !== 'f266bbf1-0126-40b9-8fda-9f92a2731af9' &&
      values.sourceType !== '6a1292c7-a245-41cf-8872-46feb9a7fd11'
    ) {
      errors.url = 'Required';
    }
    return errors;
  };

  const postNewSource = ({
    title,
    sourceType,
    reference,
    url,
    publicationDate,
    comment,
  }) => {
    const postSource = async () => {
      const body = {
        source_type_id: sourceType,
        title,
        reference,
        url,
        reference_id: projectId,
        reference_table: 'project',
        reference_url: `${baseUrl}/api/projects`,
        ...(comment && { comment }),
      };
      if (publicationDate) {
        body.date_day = publicationDate.getDate().toString();
        body.date_month = (publicationDate.getMonth() + 1).toString();
        body.date_year = publicationDate.getFullYear().toString();
      }
      const { source } = await referenceDataApi('addSource', {
        body,
      }).catch(handleApiError);

      if (source) {
        setOpenAddSource(false);
        setSelectedSource(source);
        setOpenEditingForm(true);
        setOpenSnackBar(true);
      }
      return source;
    };
    postSource();
  };

  return {
    baseUrl,
    projectId,
    allSourceTypes,
    setAllSourceTypes,
    validateAddSourceInputs,
    postNewSource,
    openSnackBar,
  };
};

export default useAddSource;
