import PT from 'prop-types';
import { MenuItem, Menu, ListItemIcon } from '@mui/material';
import {
  ExitToApp,
  ManageAccounts as ManageAccountsIcon,
} from '@mui/icons-material';
import { useIsAuthenticated } from '@azure/msal-react';

function UserMenu({ anchorEl, handleClose, navigate, userLogout }) {
  const isAuthenticated = useIsAuthenticated();

  return (
    <Menu
      id="menu-user-account"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <MenuItem
        data-cy="go-manage-account"
        onClick={() => {
          handleClose();
          navigate('/user-settings/details');
        }}
      >
        <ListItemIcon>
          <ManageAccountsIcon />
        </ListItemIcon>
        Manage Account
      </MenuItem>
      {isAuthenticated && (
        <MenuItem onClick={userLogout} data-cy="logout-button">
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          Logout
        </MenuItem>
      )}
    </Menu>
  );
}

UserMenu.propTypes = {
  anchorEl: PT.oneOfType([PT.object, PT.func]),
  handleClose: PT.func.isRequired,
  navigate: PT.func.isRequired,
  userLogout: PT.func.isRequired,
};

UserMenu.defaultProps = {
  anchorEl: null,
};

export default UserMenu;
