import { Box, Typography } from '@mui/material';
import { PersonCard } from 'components/microsoft';
import { withAppInsights } from 'app/app-insights';

function UserDetails() {
  return (
    <Box
      sx={{ padding: theme => theme.spacing(5) }}
      data-testid="user-settings-details"
      data-cy="user-settings-details"
    >
      <Typography variant="h4" component="h1" mb={3} gutterBottom>
        Details
      </Typography>
      <PersonCard personQuery="me" isExpanded />
    </Box>
  );
}

export default withAppInsights(UserDetails, 'UserDetailsPage');
