import React, { useState, useEffect } from 'react';
import {
  useParams,
  useNavigate,
  useSearchParams,
  useLocation,
} from 'react-router-dom';
import axios from 'axios';
import { useImmer } from 'use-immer';
import PT from 'prop-types';
import { uniqBy, isEmpty } from 'lodash';
import { templateApi } from 'api';
import qs from 'qs';

export const DataTemplatesContext = React.createContext();

export const DataTemplatesProvider = ({ children }) => {
  const { dataTemplateId } = useParams();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [dataTemplates, setDataTemplates] = useImmer([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const parsedSearch = qs.parse(search, { ignoreQueryPrefix: true });
  const [dataTemplate, setDataTemplate] = useState({});
  const [templateAssets, setTemplateAssets] = useState([]);
  const [loadingAssets, setLoadingAssets] = useState(false);
  const query = { ...parsedSearch };
  const { projectId } = useParams();
  const [openTemplateWizard, setOpenTemplateWizard] = useState(false);
  const [assetNodes, setAssetNodes] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [assetTypesWithParentAndSubTypes, setAssetTypesWithParentAndSubTypes] =
    useState({});

  const handleOpenCloseWizard = () => {
    setOpenTemplateWizard(!openTemplateWizard);
  };

  const mapTemplateAssetsByTypeAndSubTypes = templateAssetsArr => {
    const result = {};

    templateAssetsArr.forEach(templateAsset => {
      const assetTypeId = templateAsset?.assetType?.id;
      const { parentAssetPlaceholderId } = templateAsset;
      const assetSubTypes = templateAsset.assetSubType ? [templateAsset] : [];

      if (!result[assetTypeId]) {
        result[assetTypeId] = {};
      }

      if (!result[assetTypeId][parentAssetPlaceholderId]) {
        result[assetTypeId][parentAssetPlaceholderId] = {
          assetSubTypes: [],
        };
      }
      result[assetTypeId][parentAssetPlaceholderId].assetSubTypes.push(
        ...assetSubTypes
      );
    });
    return result;
  };

  useEffect(() => {
    setAssetNodes([]);
    const source = axios.CancelToken.source();
    const getDataTemplates = async after => {
      setLoading(true);
      if (after) query.after = after;
      if (query.show_active_instances === 'true') {
        query.project_id = projectId;
        delete query.show_active_instances;
      } else {
        delete query.project_id;
        delete query.show_active_instances;
      }
      try {
        const response = await templateApi('getTemplates', query, source.token);
        if (response) {
          const { templates, paging } = response;
          setDataTemplates(currentDataTemplates =>
            uniqBy([...currentDataTemplates, ...templates], 'id')
          );
          if (paging?.cursors?.after) {
            await getDataTemplates(paging?.cursors?.after);
          } else {
            setLoading(false);
          }
        }
      } catch (err) {
        if (err.response?.status === 503)
          navigate('/error', {
            replace: true,
            state: {
              status: err.response.status,
              ...err.response.data,
            },
          });
        else {
          setLoading(false);
          setError(err.response?.data);
        }
      }
    };

    if (
      (pathname.includes('/templates') && search) ||
      (isEmpty(dataTemplates) && dataTemplateId) ||
      (isEmpty(dataTemplates) && pathname.includes('/templates'))
    ) {
      setDataTemplates([]);
      setAssetNodes([]);
      getDataTemplates();
    }

    return () => {
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    const getTemplate = () => {
      const filteredParameterSetTemplate = dataTemplates.find(
        setType => setType.id === dataTemplateId
      );
      setDataTemplate(filteredParameterSetTemplate);
      setRowSelectionModel([filteredParameterSetTemplate?.id]);
      setLoading(false);
    };

    if (
      (dataTemplateId || pathname.includes(dataTemplateId)) &&
      dataTemplates.length
    ) {
      getTemplate();
    } else if (
      !(dataTemplateId || pathname.includes(dataTemplateId)) &&
      dataTemplates.length
    ) {
      setLoading(false);
      setDataTemplate({});
      setRowSelectionModel([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTemplateId, dataTemplates]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const getTemplateAssets = async after => {
      setLoadingAssets(true);
      const assetQuery = { template_id: dataTemplateId, sort_by: 'name' };
      if (after) query.after = after;
      try {
        const response = await templateApi(
          'getAssetPlaceholders',
          assetQuery,
          source.token
        );
        if (response) {
          const { assetPlaceholders, paging } = response;
          const mappedPlaceholders = assetPlaceholders.map(
            ({ count, maxCount, ...rest }) => {
              return {
                maxCount: maxCount || count,
                ...rest,
              };
            }
          );

          setTemplateAssets(curr =>
            uniqBy([...curr, ...mappedPlaceholders], 'id')
          );
          if (paging?.cursors?.after) {
            await getTemplateAssets(paging?.cursors?.after);
          }
        }
        setLoadingAssets(false);
      } catch (err) {
        setError(err?.response?.data);
        setLoadingAssets(false);
      }
    };

    if (dataTemplateId && !isEmpty(dataTemplate)) {
      setTemplateAssets([]);
      setAssetNodes([]);
      getTemplateAssets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTemplate]);

  useEffect(() => {
    if (templateAssets.length) {
      const mapResult = mapTemplateAssetsByTypeAndSubTypes(templateAssets);
      setAssetTypesWithParentAndSubTypes(mapResult);
    }
  }, [templateAssets, dataTemplateId]);

  return (
    <DataTemplatesContext.Provider
      value={{
        dataTemplates,
        setDataTemplates,
        loading,
        error,
        dataTemplate,
        query,
        openTemplateWizard,
        setOpenTemplateWizard,
        handleOpenCloseWizard,
        templateAssets,
        loadingAssets,
        assetNodes,
        setAssetNodes,
        rowSelectionModel,
        setRowSelectionModel,
        assetTypesWithParentAndSubTypes,
      }}
    >
      {children}
    </DataTemplatesContext.Provider>
  );
};

DataTemplatesProvider.propTypes = {
  children: PT.oneOfType([PT.arrayOf(PT.node), PT.node]).isRequired,
};
