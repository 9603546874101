import { Grid, Chip } from '@mui/material';
import {
  useLocation,
  useNavigate,
  createSearchParams,
  useParams,
} from 'react-router-dom';
import qs from 'qs';

const Items = () => {
  const { search, pathname } = useLocation();
  const { assetId } = useParams();
  const { project_parameter: projectParameters } = {
    ...qs.parse(search, { ignoreQueryPrefix: true }),
  };
  const navigate = useNavigate();

  const paramsValues = {};
  paramsValues.order = 'asc';
  paramsValues.sort_by = 'parameter_type_name';

  return (
    <Grid container direction="row" p={1}>
      {[
        {
          ariaLabel: 'all-parameters-chip',
          dataCypress: 'all-parameters-chip',
          label: 'All Parameters',
          onClick: () => {
            navigate({
              pathname: `parameters`,
              search: `?${createSearchParams(paramsValues)}`,
            });
          },
          isActive: !(
            !assetId &&
            !projectParameters &&
            pathname.includes('/parameters')
          ),
        },
      ].map(chip => {
        return (
          <Chip
            role="button"
            key={chip.label}
            data-cy={chip.dataCypress}
            aria-label={chip.ariaLabel}
            size="small"
            label={chip.label}
            onClick={chip.onClick}
            variant={chip.isActive ? 'outlined' : 'filled'}
            sx={{
              margin: theme => theme.spacing(0.15),
              color: theme => theme.palette.text.primary,
              fontWeight: chip.isActive ? null : '500',
            }}
          />
        );
      })}
    </Grid>
  );
};

export default Items;
