import { Link, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const ContactOnboarding = ({ isMdUp }) => {
  return (
    <Typography
      variant={isMdUp ? 'subtitle1' : 'h6'}
      component="h2"
      fontWeight={theme => theme.typography.fontWeightRegular}
      sx={{
        paddingBottom: theme => theme.spacing(8),
      }}
    >
      {'Interested in using DDB for your project? '}
      <Link
        target="_blank"
        href="https://forms.office.com/Pages/ResponsePage.aspx?id=QYvkSjcBmUWGYfxkH-d76jWhSs5jgN9IqtOZf5LAmspUODU2M1FUQTdBM0lDNjlQNkg0VE5OUVJGQyQlQCN0PWcu"
      >
        Contact the DDB onboarding team
      </Link>{' '}
      {'to get started!'}
    </Typography>
  );
};

ContactOnboarding.propTypes = {
  isMdUp: PropTypes.bool.isRequired,
};

export default ContactOnboarding;
