/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import PT from 'prop-types';
import { Edit, Done, Clear, AddCircle } from '@mui/icons-material';
import { useState, useContext } from 'react';
import { Typography, TextField, Tooltip, Grid, Chip } from '@mui/material';
import { StyledIconButton } from 'components/buttons';
import { ThemeContext } from 'context';
import { isEmpty } from 'lodash';
import { StyledTreeItem, StyledBox } from './tree-item-styles';

const TreeItem = props => {
  const {
    textWeight,
    bgColor,
    color,
    iconColor,
    labelIcon,
    labelInfo,
    labelText,
    isAssetInstance,
    onLabelClick,
    dataSet,
    onAddAssetClick,
    node,
    onEditClick,
    onDoneClick,
    textFieldError,
    onClearClick,
    onRemoveAssetClick,
    deleted,
    isTemplateDetails,
    ...other
  } = props;
  const [editingValue, setEditingValue] = useState(labelText);
  const { localTheme } = useContext(ThemeContext);
  const projectDirectChildrenAssetTypesIds = [
    '8882fd17-20bb-4e55-8d36-4a54dca1599a',
    'a98d79a1-4f4a-42e5-ac28-0773387b3dfe',
    'c8057e13-2d29-4841-b64a-b6e1c95d7b5d',
    'f61f7053-b28e-457a-b719-c04d7e1536a3',
    'fb7e2a70-3f23-44c0-a95e-79fd53f611f5',
  ]; // generic, site, material, masterplanning area, network link

  return (
    <StyledTreeItem
      mode={localTheme}
      aria-label="tree-item"
      data-cy="tree-item"
      label={
        <StyledBox onClick={onLabelClick}>
          {labelIcon && labelIcon}
          {node?.edited ? (
            <TextField
              data-cy="wizard-child-name"
              aria-label="edited-item"
              autoFocus
              error={textFieldError}
              sx={{ width: 120 }}
              id={node.id}
              value={editingValue}
              onChange={e => setEditingValue(e.target.value)}
              helperText={textFieldError}
            />
          ) : (
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid
                item
                container
                alignItems="center"
                xs={12}
                sm={isTemplateDetails ? 12 : 9}
                md={isTemplateDetails ? 12 : 9}
                lg={isTemplateDetails ? 12 : 9}
                xl={isTemplateDetails ? 12 : 9}
              >
                {node?.new && (
                  <Grid
                    item
                    paddingLeft={
                      dataSet
                        ? theme => theme.spacing(0)
                        : theme => theme.spacing(1)
                    }
                  >
                    <Chip
                      aria-label="new-chip"
                      size="small"
                      color="secondary"
                      label="NEW"
                      sx={{
                        marginRight: 1,
                        maxWidth: 50,
                        fontSize: '10px',
                      }}
                    />
                  </Grid>
                )}
                <Grid item>
                  <Typography
                    data-cy="tree-item-label"
                    variant="body2"
                    color={color || 'primary'}
                    component="div"
                    sx={{
                      fontWeight: textWeight,
                      flexGrow: 1,
                    }}
                  >
                    {labelText}
                  </Typography>
                </Grid>
              </Grid>

              {!isAssetInstance && dataSet && (
                <Grid item>
                  {labelInfo && (
                    <Typography variant="caption" color="inherit">
                      {labelInfo}
                    </Typography>
                  )}
                  <StyledIconButton
                    tooltipProps={{ placement: 'left' }}
                    sx={{ padding: 0.5 }}
                    aria-label="add-icon"
                    onClick={() => {
                      onAddAssetClick(node);
                    }}
                    title={
                      !node.setInfo[0]?.parentAssetPlaceholderId &&
                      !projectDirectChildrenAssetTypesIds.includes(
                        node.assetType?.id
                      )
                        ? `New assets cannot be added at the top level of the template. Please use the Asset Wizard to add new assets of type '${node.assetType.name}'`
                        : !isEmpty(node.assetSubType) &&
                          !isEmpty(node.children) &&
                          node.assetSubType.length === node.children.length
                        ? 'The required asset sub-type(s) already exists at this level'
                        : isEmpty(node.children)
                        ? 'No assets exist at this level. Add required assets'
                        : 'Add'
                    }
                    disabled={
                      (!node.setInfo[0]?.parentAssetPlaceholderId &&
                        !projectDirectChildrenAssetTypesIds.includes(
                          node.assetType?.id
                        )) ||
                      (!isEmpty(node.assetSubType) &&
                        !isEmpty(node.children) &&
                        node.assetSubType.length === node.children.length)
                    }
                    data-cy="wizard-column-item-add"
                    data-testid="wizard-column-item-add"
                    icon={
                      isEmpty(node.children) ? (
                        <Chip
                          label="Add"
                          icon={<AddCircle />}
                          color="primary"
                        />
                      ) : (
                        <AddCircle data-cy="wizard-addItem" />
                      )
                    }
                  />
                </Grid>
              )}
              {isAssetInstance && dataSet && !node.edited && node?.new && (
                <Grid item>
                  <StyledIconButton
                    tooltipProps={{ placement: 'left' }}
                    sx={{ padding: 0.5 }}
                    title="Remove"
                    icon={<Clear />}
                    data-cy="wizard-remove-child"
                    color="primary"
                    onClick={() => {
                      onRemoveAssetClick(node);
                    }}
                    aria-label="delete-icon"
                  />
                  <StyledIconButton
                    tooltipProps={{ placement: 'right' }}
                    sx={{ padding: 0.5 }}
                    title={
                      !isEmpty(node.assetSubType)
                        ? 'You cannot edit a predefined asset sub-type'
                        : 'Edit'
                    }
                    icon={<Edit />}
                    data-cy="wizard-edit-child"
                    color="primary"
                    disabled={!isEmpty(node.assetSubType)}
                    onClick={() => {
                      onEditClick(node.id);
                    }}
                    aria-label="edit-icon"
                  />
                </Grid>
              )}
            </Grid>
          )}

          {isAssetInstance && dataSet && node.edited && (
            <>
              <Tooltip title="Save">
                <Done
                  sx={{ color: theme => theme.palette.secondary.dark }}
                  onClick={() => {
                    onDoneClick(node, editingValue);
                  }}
                  data-cy="wizard-save-child"
                  aria-label="accept-icon"
                />
              </Tooltip>
              <Tooltip title="Cancel">
                <Clear
                  sx={{ color: theme => theme.palette.secondary.dark }}
                  onClick={() => {
                    onClearClick(node.id);
                  }}
                  data-cy="wizard-clear-child"
                  aria-label="cancel-icon"
                />
              </Tooltip>
            </>
          )}
          {!dataSet && labelInfo && (
            <Typography variant="caption" color="inherit">
              {labelInfo}
            </Typography>
          )}
        </StyledBox>
      }
      {...other}
    />
  );
};

TreeItem.propTypes = {
  textWeight: PT.number,
  bgColor: PT.oneOfType([PT.string, PT.func]),
  color: PT.oneOfType([PT.string, PT.func]),
  iconColor: PT.oneOfType([PT.string, PT.func]),
  labelIcon: PT.any,
  labelInfo: PT.oneOfType([PT.string, PT.func]),
  labelText: PT.oneOfType([PT.string, PT.element]).isRequired,
  isAssetInstance: PT.bool,
  onLabelClick: PT.func,
  dataSet: PT.bool,
  onAddAssetClick: PT.func,
  node: PT.oneOfType([
    PT.shape({ edited: PT.bool, id: PT.string, new: PT.bool }),
    PT.func,
  ]),
  onEditClick: PT.func,
  onDoneClick: PT.func,
  textFieldError: PT.string,
  onClearClick: PT.func,
  deleted: PT.string || null,
  onRemoveAssetClick: PT.func,
  isTemplateDetails: PT.bool,
};

TreeItem.defaultProps = {
  textWeight: 400,
  bgColor: '',
  color: 'primary',
  iconColor: 'primary',
  labelIcon: null,
  labelInfo: '',
  isAssetInstance: false,
  onLabelClick: () => {},
  dataSet: false,
  onAddAssetClick: () => {},
  onEditClick: () => {},
  onDoneClick: () => {},
  textFieldError: '',
  onClearClick: () => {},
  deleted: null,
  onRemoveAssetClick: () => {},
  node: {},
  isTemplateDetails: false,
};

export default TreeItem;
