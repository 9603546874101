import { useState } from 'react';
import PT from 'prop-types';
import { FileCopy } from '@mui/icons-material';
import StyledIconButton from '../styled';

function CopyButton({ toBeCopied, toolTipText }) {
  const [showCopied, setShowCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(toBeCopied);
    setShowCopied(true);
  };

  const handleCloseToolTip = () => {
    setTimeout(() => setShowCopied(false), 500);
  };

  return (
    <StyledIconButton
      title={showCopied ? 'Copied' : `Copy ${toolTipText} to clipboard`}
      tooltipProps={{ leaveDelay: 0, onClose: handleCloseToolTip }}
      variant="outlined"
      onClick={handleCopy}
      disabled={!toBeCopied}
      aria-label={showCopied ? 'Copied' : `Copy ${toolTipText} to clipboard`}
      icon={<FileCopy />}
    />
  );
}

CopyButton.defaultProps = {
  toBeCopied: null,
  toolTipText: null,
};

CopyButton.propTypes = {
  toBeCopied: PT.string,
  toolTipText: PT.string,
};

export default CopyButton;
