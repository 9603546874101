/* eslint-disable no-param-reassign */
import { useState } from 'react';
import { findIndex, isEmpty, uniqBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { validateAssetName, createUniqueName, findSubTree } from 'utils/index';

export default (
  assetNodes,
  formattedAssets,
  setExpanded,
  selectedAssets,
  setSelectedAssets,
  allAssets,
  setAllAssets,
  templateAssets
) => {
  const [textFieldError, setTextFieldError] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState({});

  const getChildren = (childAssets, childArray) => {
    if (!isEmpty(childAssets)) {
      childAssets.forEach(asset => {
        if (asset?.isAssetInstance) childArray.push(asset);
        getChildren(asset?.children, childArray);
      });
    }
    return childArray;
  };
  const findParentNode = (assetNodesObj, parentAssetId) => {
    if (assetNodesObj.id === parentAssetId) return assetNodesObj;
    if (!isEmpty(assetNodesObj.children)) {
      const results = assetNodesObj.children.map(child =>
        findParentNode(child, parentAssetId)
      );
      const foundResult = results.find(result => result !== null);
      return foundResult || null;
    }
    return null;
  };

  const uniqSelectedAssets = (array1, array2) =>
    uniqBy([...array1, ...array2], 'id');

  const createNewAssets = (newTemplateAssets, parentId, newAssets) => {
    const createAsset = templateAsset => {
      const newOne = {
        id: uuidv4(),
        name: `${
          !isEmpty(templateAsset.assetSubType)
            ? `${templateAsset.assetSubType.name}`
            : createUniqueName(
                templateAsset.defaultName,
                templateAsset.children
                  ? templateAsset.children.map(child => child?.name)
                  : []
              )
        }`,
        new: true,
        assetType: templateAsset.assetType,
        assetSubType: templateAsset.assetSubType,
        parent: parentId || null,
        children: [],
        deletedAt: null,
        parentAssetPlaceholderId: templateAsset.parentAssetPlaceholderId,
      };

      newAssets.push(newOne);
      const parentIndex = findIndex(newAssets, asset => asset.id === parentId);
      if (parentIndex !== -1) {
        newAssets[parentIndex].children.push(newOne.id);
      }
      if (!isEmpty(templateAsset.children)) {
        createNewAssets(templateAsset.children, newOne.id, newAssets);
      }
    };

    newTemplateAssets.forEach(templateAsset => {
      createAsset(templateAsset);
    });
  };

  const getChildStructure = (
    tree,
    typeId,
    assetSubTypeId,
    parentId,
    parentAssetPlaceholderId,
    newAssets
  ) => {
    if (
      (tree?.assetType?.id === typeId || tree?.assetTypeId === typeId) &&
      !isEmpty(tree.children) &&
      parentAssetPlaceholderId === tree.parentAssetPlaceholderId &&
      tree.assetSubType?.id === assetSubTypeId
    ) {
      createNewAssets(tree.children, parentId, newAssets);
    } else if (!isEmpty(tree.children)) {
      tree.children?.forEach(childTree => {
        getChildStructure(
          childTree,
          typeId,
          childTree.assetSubType?.id,
          parentId,
          childTree.parentAssetPlaceholderId,
          newAssets
        );
      });
    }
  };

  const onAddAssetClick = node => {
    const newAssets = [];
    if (node.assetSubType.length > 1) {
      const existingChildrenSubTypes = node.children.map(
        child => child.assetSubType.id
      );
      const newSubTypesToAdd = node.assetSubType.filter(
        nodeSubType =>
          !existingChildrenSubTypes.includes(nodeSubType.assetSubType.id)
      );
      newSubTypesToAdd.forEach(assetPlaceholderSubType => {
        const newOne = {
          id: uuidv4(),
          name: `${assetPlaceholderSubType.assetSubType.name}`,
          new: true,
          assetType: {
            ...assetPlaceholderSubType.assetType,
          },
          assetSubType: { ...assetPlaceholderSubType.assetSubType },
          parent: node.parent,
          children: [],
          deletedAt: null,
          parentAssetPlaceholderId: node.parentAssetPlaceholderId,
        };
        newAssets.push(newOne);

        const templateSubTree = findSubTree(
          formattedAssets,
          assetPlaceholderSubType.assetType.id,
          assetPlaceholderSubType.assetSubType.id,
          assetPlaceholderSubType.parentAssetPlaceholderId
        );

        getChildStructure(
          templateSubTree,
          node.assetType.id,
          assetPlaceholderSubType.assetSubType.id,
          newOne.id,
          node.parentAssetPlaceholderId,
          newAssets
        );
      });
    } else {
      const newOne = {
        id: uuidv4(),
        name: `${
          !isEmpty(node.assetSubType)
            ? `${node.assetSubType[0].assetSubType.name}`
            : createUniqueName(
                node.setInfo[0].defaultName,
                node.children ? node.children.map(child => child?.name) : []
              )
        }`,
        new: true,
        assetType: {
          ...node.assetType,
        },
        assetSubType: { ...node?.assetSubType[0]?.assetSubType } || null,
        parent: node.parent || null,
        children: [],
        deletedAt: null,
        parentAssetPlaceholderId: node.parentAssetPlaceholderId,
      };
      newAssets.push(newOne);
      const templateSubTree = findSubTree(
        formattedAssets,
        node.assetType.id,
        node.assetSubType[0]?.assetSubType.id,
        node.parentAssetPlaceholderId
      );

      getChildStructure(
        templateSubTree,
        node.assetType.id,
        node.assetSubType[0]?.assetSubType.id,
        newOne.id,
        node.parentAssetPlaceholderId,
        newAssets
      );
    }
    setAllAssets(curr => [...curr, ...newAssets]);
    const newExpandedNodes = newAssets.map(asset => asset.id);
    setExpanded(curr => [...curr, ...newExpandedNodes, node.id]);
  };

  const onEditClick = assetId => {
    const index = findIndex(allAssets, asset => asset.id === assetId);
    setAllAssets(curr => {
      curr[index].edited = true;
    });
  };

  const onRemoveAssetClick = node => {
    const childrenIds = getChildren(node.children, [node]).map(({ id }) => id);
    setAllAssets(curr => {
      const childrenIdSet = new Set(childrenIds);
      const allAssetsWithoutRemoved = curr.filter(asset => {
        return !childrenIdSet.has(asset.id);
      });
      return [...allAssetsWithoutRemoved];
    });

    setSelectedAssets(curr => {
      const childrenIdSet = new Set(childrenIds);
      const selectedAssetsWithoutRemoved = curr.filter(asset => {
        return !childrenIdSet.has(asset.id);
      });
      return [...selectedAssetsWithoutRemoved];
    });
  };

  const onClearClick = assetId => {
    const index = findIndex(allAssets, asset => asset.id === assetId);
    setAllAssets(curr => {
      curr[index].edited = false;
    });
  };

  const onDoneClick = (node, editingValue) => {
    const errors = validateAssetName(
      editingValue,
      node.parent || null,
      node.assetType.id,
      allAssets
    );
    setTextFieldError(errors);
    if (!errors) {
      const index = findIndex(allAssets, asset => asset.id === node.id);
      setAllAssets(curr => {
        curr[index].edited = false;
        curr[index].name = editingValue;
        curr[index].editedName = true;
      });
      const selectedAssetIndex = findIndex(
        selectedAssets,
        asset => asset.id === node.id
      );
      if (selectedAssetIndex > -1) {
        setSelectedAssets(curr => {
          curr[selectedAssetIndex].name = editingValue;
          return [...curr];
        });
      }
    }
  };

  const getParent = (parentId, parentArray) => {
    if (parentId) {
      const parent = allAssets.find(asset => {
        return asset.id === parentId;
      });

      const parentNode = findParentNode(assetNodes[0], parentId);
      if (parent?.id !== undefined) {
        parentArray.push(parentNode);
      }
      getParent(parent?.parent, parentArray);
    }
    return parentArray;
  };

  const updateSelection = node => {
    const assetIndex = findIndex(selectedAssets, asset => asset.id === node.id);
    if (assetIndex > -1) {
      const removeAsset = getChildren(node.children, [node]);
      setSelectedAssets(curr => {
        const assetIdsToRemove = new Set(removeAsset.map(item => item.id));
        const filteredArray = curr.filter(
          item => !assetIdsToRemove.has(item.id)
        );
        return filteredArray;
      });
    } else {
      const checkAssetTypeAlreadySelected = selectedAssets.filter(asset => {
        return (
          (asset.assetType.id === node.assetType.id &&
            asset?.assetSubType?.id === node?.assetSubType?.id) ||
          asset.parentAssetPlaceholderId === node.parentAssetPlaceholderId
        );
      });
      const findParentAssetPlaceholder = templateAssets.find(
        templateAsset => templateAsset.id === node.parentAssetPlaceholderId
      );
      if (
        checkAssetTypeAlreadySelected.length >= 1 &&
        node.assetTypeCount === 1 &&
        !node.multipleSubtypes &&
        node.parentAssetPlaceholderId ===
          checkAssetTypeAlreadySelected[0].parentAssetPlaceholderId &&
        findParentAssetPlaceholder?.maxCount !== null
      ) {
        const parentNode = findParentNode(
          assetNodes[0],
          checkAssetTypeAlreadySelected[0].id
        );
        const nodeChildrenToRemove = getChildren(parentNode.children, [
          parentNode,
        ]);
        const nodeParentsToRemove = getParent(
          checkAssetTypeAlreadySelected[0].parent,
          [checkAssetTypeAlreadySelected[0]]
        );
        setSelectedAssets(curr => {
          const assetIdsToRemove = new Set(
            nodeChildrenToRemove
              .concat(nodeParentsToRemove)
              .map(item => item.id)
          );
          const filteredArray = curr.filter(item => {
            return !assetIdsToRemove.has(item.id);
          });
          const parents = getParent(node.parent, [node]);

          const updatedSelectedAssets = uniqSelectedAssets(
            filteredArray,
            parents
          );

          return updatedSelectedAssets;
        });
      } else if (
        checkAssetTypeAlreadySelected.length >= 1 &&
        node.assetTypeCount === 1 &&
        node.multipleSubtypes
      ) {
        setSelectedAssets(curr => {
          const assetsAlreadySelectedWithDiffParent =
            checkAssetTypeAlreadySelected.filter(x => x.parent !== node.parent);
          const parentsToRemove = assetsAlreadySelectedWithDiffParent
            .map(x => getParent(x.parent, [x]))
            .flat(2);
          const childrenToRemove = parentsToRemove
            .map(parent => getChildren(parent.children, [parent]))
            .flat(2);
          const nodeParentsToAdd = getParent(node.parent, [node]);
          const assetIdsToRemove = new Set(
            childrenToRemove.map(item => item.id)
          );
          const filteredArray = curr.filter(item => {
            return !assetIdsToRemove.has(item.id);
          });
          const updatedSelectedAssets = uniqSelectedAssets(
            filteredArray,
            nodeParentsToAdd
          );
          return updatedSelectedAssets;
        });
      } else {
        const parents = getParent(node.parent, [node]);
        const parentsSameAssetType = parents
          .map(parent => {
            let sameAssetTypes = selectedAssets.filter(
              asset =>
                asset.assetType.id === parent.assetType.id &&
                asset.id !== parent.id &&
                asset.assetTypeCount === 1 &&
                !asset.multipleSubtypes &&
                asset.parentAssetPlaceholderId ===
                  parent.parentAssetPlaceholderId
            );
            // get the parent of sameAssetType
            // if the parent has assetTypeCount as null, then remove the sameAssetType
            // as it means more than one parent can be selected and therefore its children
            // don't have to be deselected
            const parentOfSameAssetType = selectedAssets.find(sa => {
              return sa.id === sameAssetTypes[0]?.parent;
            });
            if (parentOfSameAssetType?.assetTypeCount === null) {
              sameAssetTypes = [];
            }
            const updatedNodesInfo = sameAssetTypes.map(asset => {
              return findParentNode(assetNodes[0], asset.id);
            });
            const childrenOfSameAssetType = updatedNodesInfo.map(
              sameAssetType =>
                getChildren(sameAssetType.children, [sameAssetType])
            );
            return childrenOfSameAssetType;
          })
          .flat(2);
        setSelectedAssets(curr => {
          const assetIdsToRemove = new Set(
            parentsSameAssetType.map(item => item.id)
          );

          const filteredArray = curr.filter(item => {
            return !assetIdsToRemove.has(item.id);
          });
          const updatedSelectedAssets = uniqSelectedAssets(
            filteredArray,
            parents
          );
          return updatedSelectedAssets;
        });
      }
    }
  };

  return {
    updateSelection,
    onAddAssetClick,
    onEditClick,
    onDoneClick,
    onClearClick,
    textFieldError,
    selectedAsset,
    setSelectedAsset,
    onRemoveAssetClick,
  };
};
