import { Button } from '@mui/material';
import PT from 'prop-types';
import steps from '../steps';
import useButtons from './hooks/useButtons';
import { StyledGrid } from './buttons-styles';

const Buttons = ({ activeStep, setActiveStep }) => {
  const { handleBack, handleNext, isNextDisabled } = useButtons(
    activeStep,
    setActiveStep
  );
  return (
    <StyledGrid aria-label="buttons" container p={2}>
      <Button
        variant="contained"
        color="inherit"
        disabled={activeStep === 0}
        onClick={handleBack}
        sx={{ mr: theme => theme.spacing(1) }}
        data-cy="reporting-back-button"
      >
        Back
      </Button>
      {activeStep !== steps.length - 1 && (
        <Button
          onClick={handleNext}
          disabled={isNextDisabled}
          variant="contained"
          sx={{ mr: theme => theme.spacing(1) }}
          data-cy="reporting-next-button"
        >
          Next
        </Button>
      )}
    </StyledGrid>
  );
};

Buttons.propTypes = {
  activeStep: PT.number.isRequired,
  setActiveStep: PT.func.isRequired,
};
export default Buttons;
