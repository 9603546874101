import {
  Grid,
  Typography,
  Box,
  Chip,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import Alert from 'components/alerts/index';
import { Done } from '@mui/icons-material';
import { capitalize, isEmpty } from 'lodash';
import { lighten, darken } from '@mui/material/styles';
import PT from 'prop-types';

const AssetRequirementsCard = ({
  formattedAssets,
  updateSelection,
  requiredAssetsSelected,
  prefixCounter,
  validationError,
}) => {
  const prefix = String.fromCharCode(97 + prefixCounter);
  const completedSelection = (selectedAssetsArray, assetPh) => {
    const matchAssetsWithTypes = selectedAssetsArray.filter(
      selectedAsset =>
        selectedAsset.assetType.id === assetPh.assetType.id &&
        selectedAsset?.assetSubType?.id === assetPh?.assetSubType?.id &&
        selectedAsset.parentAssetPlaceholderId ===
          assetPh.parentAssetPlaceholderId
    );
    if (
      matchAssetsWithTypes.length === assetPh.maxCount ||
      (matchAssetsWithTypes.length > 0 && assetPh.maxCount === null) ||
      (matchAssetsWithTypes.length > 1 &&
        assetPh?.assetSubType?.id &&
        assetPh.maxCount === 1)
    ) {
      return true;
    }
    return false;
  };

  const generateChips = (selectedAssetsArray, assetPh) => {
    const matchAssetsWithTypes = selectedAssetsArray.filter(
      selectedAsset =>
        selectedAsset.assetType.id === assetPh.assetType.id &&
        selectedAsset?.assetSubType?.id === assetPh?.assetSubType?.id &&
        selectedAsset.parentAssetPlaceholderId ===
          assetPh.parentAssetPlaceholderId
    );

    return matchAssetsWithTypes.map(asset => {
      return (
        <Chip
          aria-label="asset-chip"
          key={asset.id}
          label={asset.name}
          sx={{ marginLeft: 1, marginBottom: 1 }}
          onDelete={
            asset.assetTypeCount === null ? () => updateSelection(asset) : null
          }
        />
      );
    });
  };

  const findValidationError =
    !isEmpty(validationError) &&
    validationError.find(
      error =>
        error.assetTypeId === formattedAssets.assetType?.id &&
        error.parentAssetPlaceholderId ===
          formattedAssets.parentAssetPlaceholderId &&
        error.assetSubTypeId === formattedAssets.assetSubType?.id
    );

  return (
    <Grid item container key={formattedAssets.id}>
      {formattedAssets?.assetType !== null && (
        <Box
          key={formattedAssets.id}
          marginBottom={1}
          sx={{
            backgroundColor:
              completedSelection(requiredAssetsSelected, formattedAssets) &&
              isEmpty(findValidationError)
                ? theme => {
                    if (theme.palette.mode === 'dark')
                      return darken(theme.palette.primary.main, 0.6);
                    return lighten(theme.palette.primary.main, 0.85);
                  }
                : theme => theme.palette.background.paper,
          }}
          minWidth="100%"
          aria-label="asset-placeholder-info-box"
        >
          <Grid
            item
            key={formattedAssets.id}
            container
            wrap="nowrap"
            justifyContent="space-between"
          >
            <Grid item>
              <Typography
                marginLeft={formattedAssets.maxCount === 1 ? 1 : 3}
                variant="subtitle2"
                aria-label="asset-placeholder-name"
              >
                {`${prefix}. ${'Select'} ${
                  formattedAssets.maxCount !== null
                    ? formattedAssets.maxCount
                    : '1 or more'
                } - ${
                  !isEmpty(formattedAssets?.assetSubType)
                    ? formattedAssets.assetSubType.name
                    : formattedAssets.name
                }`}
              </Typography>
              {!isEmpty(findValidationError) && (
                <Alert
                  ariaLabel="asset-tree-validation-error"
                  severity="error"
                  variant="outlined"
                  text={findValidationError.details}
                  key={findValidationError.details}
                  boxStyle={{
                    marginLeft: 1,
                    marginRight: 1,
                    paddingRight: 0.5,
                    paddingLeft: 0.5,
                    paddingTop: 0,
                    paddingBottom: 0,
                    marginBottom: 0,
                  }}
                />
              )}

              <List
                aria-label="asset-placeholder-description"
                key={formattedAssets.id}
                role="list"
                sx={{ paddingTop: 0 }}
              >
                <ListItem
                  sx={{
                    pt: 0,
                    pb: 0,
                    pl: formattedAssets.maxCount === 1 ? 2 : 4,
                  }}
                  key={formattedAssets.id}
                >
                  <ListItemText
                    sx={{ pl: 1 }}
                    primaryTypographyProps={{ variant: 'body2' }}
                    secondaryTypographyProps={{
                      variant: 'body2',
                      textTransform: 'capitalize',
                      color: theme => theme.palette.black,
                    }}
                    primary={
                      <>
                        <Typography
                          variant="body2"
                          component="span"
                          fontWeight="medium"
                          aria-label="placeholder-description-bold"
                        >
                          Description
                        </Typography>
                        {formattedAssets.description}
                      </>
                    }
                    secondary={
                      <>
                        <Typography
                          variant="body2"
                          component="span"
                          fontWeight="medium"
                          aria-label="asset-type-name-bold"
                        >
                          {`${capitalize('Asset Type')}: `}
                        </Typography>
                        {formattedAssets.assetType.name}
                        {formattedAssets.assetSubType?.name
                          ? ` (${formattedAssets.assetSubType.name})`
                          : ''}
                      </>
                    }
                  />
                </ListItem>
              </List>
              <Grid
                item
                sx={{ marginLeft: formattedAssets.maxCount === 1 ? 1 : 3 }}
              >
                {generateChips(requiredAssetsSelected, formattedAssets)}
              </Grid>
            </Grid>
            <Grid item>
              {completedSelection(requiredAssetsSelected, formattedAssets) &&
                isEmpty(findValidationError) && (
                  <Done
                    aria-label="complete-asset-card"
                    fontSize="small"
                    sx={{
                      marginTop: 1,
                      marginRight: 1,
                      color: theme => theme.palette.success.main,
                    }}
                  />
                )}
            </Grid>
          </Grid>
        </Box>
      )}
      {!isEmpty(formattedAssets?.children) &&
        formattedAssets.children.map(child => (
          <AssetRequirementsCard
            key={child.id}
            formattedAssets={child}
            updateSelection={updateSelection}
            requiredAssetsSelected={requiredAssetsSelected}
            prefixCounter={prefixCounter + 1}
            validationError={validationError}
          />
        ))}
    </Grid>
  );
};

AssetRequirementsCard.propTypes = {
  formattedAssets: PT.shape({
    id: PT.string,
    name: PT.string,
    defaultName: PT.string,
    maxCount: PT.number,
    children: PT.arrayOf(PT.shape({})),
    description: PT.string,
    assetType: PT.shape({ id: PT.string, name: PT.string }),
    assetSubType: PT.shape({ id: PT.string, name: PT.string }),
    parentAssetPlaceholderId: PT.string,
  }),
  requiredAssetsSelected: PT.arrayOf(PT.shape({})),
  updateSelection: PT.func,
  prefixCounter: PT.number,
  validationError: PT.arrayOf(PT.shape({})),
};

AssetRequirementsCard.defaultProps = {
  formattedAssets: {},
  requiredAssetsSelected: [],
  updateSelection: () => {},
  prefixCounter: 0,
  validationError: [],
};

export default AssetRequirementsCard;
