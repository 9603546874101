import PT from 'prop-types';
import { Box } from '@mui/material';
import FinalForm from 'components/forms';
import TextField from 'components/pickers/final-form-fields/text-field';
import useEditEntry from './hooks/use-edit-asset';

const EditAsset = ({
  setOpenEditingForm,
  setAssetError,
  loading,
  asset,
  setAsset,
}) => {
  const { sendUpdatedValues, validateEditing } = useEditEntry(
    setOpenEditingForm,
    setAssetError,
    asset,
    setAsset
  );

  return (
    <Box sx={{ padding: theme => theme.spacing(1), width: '100%' }}>
      <FinalForm
        isLoading={loading}
        title="Edit Asset Name"
        initialValues={{
          newAssetName: '',
          value: asset.name,
        }}
        formFields={[
          {
            size: 20,
            field: <TextField label="New Asset Name" name="value" />,
          },
        ]}
        validate={validateEditing}
        onClose={() => {
          setOpenEditingForm(false);
          setAssetError(false);
        }}
        onSubmit={sendUpdatedValues}
      />
    </Box>
  );
};

EditAsset.propTypes = {
  setOpenEditingForm: PT.func.isRequired,
  setAssetError: PT.func.isRequired,
  setAsset: PT.func.isRequired,
  loading: PT.bool.isRequired,
  asset: PT.shape({
    id: PT.string,
    name: PT.string,
    new: PT.bool,
    assetTypeId: PT.string,
    children: PT.arrayOf(PT.string),
    deletedAt: PT.string,
    assetType: PT.shape({ assetSubType: PT.bool }),
    parent: PT.string,
    editedName: PT.string,
  }).isRequired,
};

export default EditAsset;
