import { Grid } from '@mui/material';
import { FavouritesList } from 'components/favourites';
import { StyledTitleTypography } from './styles';

const FavouriteAndRecentProjects = () => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="left"
      paddingBottom={theme => theme.spacing(8)}
    >
      <Grid item md={12}>
        <StyledTitleTypography variant="h3" component="h1" color="inherit">
          My Projects
        </StyledTitleTypography>
      </Grid>
      <Grid item md={6} paddingTop={theme => theme.spacing(4)}>
        <FavouritesList />
      </Grid>
    </Grid>
  );
};

export default FavouriteAndRecentProjects;
