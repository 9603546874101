import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledButton = styled(Button)(() => ({
  textTransform: 'none',
  fontStyle: 'italic',
  textDecoration: 'underline',
}));

const CookiesButton = props => {
  return (
    <StyledButton {...props} variant="contained">
      Accept
    </StyledButton>
  );
};
export default CookiesButton;
