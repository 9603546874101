import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useParams, useLocation } from 'react-router-dom';
import qs from 'qs';
import { isEmpty } from 'lodash';
import { handleApiError, parametersApi } from 'api';
import { ProjectContext, DataTemplatesContext } from 'context';

export default (
  open,
  wizard,
  handleToggleOpen,
  handleSubmit,
  lastStep,
  setError
) => {
  const { projectId, assetId, dataTemplateId } = useParams();

  const { project, loading: projectDetailLoading } = useContext(ProjectContext);
  const { dataTemplates } = useContext(DataTemplatesContext);
  const { search } = useLocation();
  const { project_parameter: projectParameter } = {
    ...qs.parse(search, { ignoreQueryPrefix: true }),
  };
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [warning, setWarning] = useState({
    open: false,
  });
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  useEffect(() => {
    if (projectId && !projectDetailLoading && wizard === 'assets') {
      setBreadcrumbs([{ name: project.shortTitle }]);
      setIsLoading(false);
    }
  }, [projectId, project.shortTitle, projectDetailLoading, wizard]);

  useEffect(() => {
    if (projectId && wizard === 'dataSets') {
      const type = dataTemplates.filter(types => types.id === dataTemplateId);
      setBreadcrumbs([{ name: type[0]?.name }]);
      setIsLoading(false);
    }
  }, [dataTemplateId, dataTemplates, projectId, wizard]);

  useEffect(() => {
    let didCancel = false;
    const source = axios.CancelToken.source();

    const getAssetHierarchy = async () => {
      const response = await parametersApi(
        'getAssetHierarchy',
        {
          asset_ids: [assetId],
        },
        source.token
      ).catch(err => handleApiError(err, []));

      if (!didCancel && response) {
        const { hierarchies } = response;
        if (!isEmpty(hierarchies)) setBreadcrumbs(...hierarchies);
        setIsLoading(false);
      }
    };

    if (open && assetId && wizard === 'parameters') getAssetHierarchy();
    if (open && !assetId && projectParameter && wizard === 'parameters') {
      setBreadcrumbs([]);
      setIsLoading(false);
    }

    return () => {
      didCancel = true;
      source.cancel();
    };
  }, [
    open,
    assetId,
    projectParameter,
    projectId,
    wizard,
    projectDetailLoading,
  ]);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    if (setError) setError(undefined);
    setActiveStep(activeStep - 1);
  };

  const handleFinish = () => {
    if (activeStep === lastStep) handleSubmit();
    setActiveStep(lastStep);
  };

  const onClose = () => {
    handleToggleOpen();
  };

  const handleClose = () => {
    setWarning({
      open: true,
      title: 'Are you sure you want to close?',
      body: 'All your progress will be lost, are you sure you want to exit without saving?',
      onClose: () => setWarning({ warning: false }),
      onAccept: onClose,
    });
  };

  return {
    activeStep,
    setActiveStep,
    warning,
    handleBack,
    handleNext,
    handleClose,
    handleFinish,
    onClose,
    isLoading,
    breadcrumbs,
  };
};
