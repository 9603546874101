import { useState, useContext } from 'react';
import { UserContext } from 'context';

export default () => {
  const { login } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const userLogin = () => {
    handleClose();
    login();
  };

  return { anchorEl, userLogin };
};
