/* eslint-disable no-param-reassign */
import {
  CheckBoxOutlineBlank,
  CheckBox as CheckBoxIcon,
} from '@mui/icons-material';
import { Checkbox, Typography } from '@mui/material';

export const renderCheckboxOption = (props, item, { selected }) => (
  <li {...props}>
    <Checkbox
      icon={<CheckBoxOutlineBlank fontSize="small" />}
      checkedIcon={<CheckBoxIcon fontSize="small" />}
      style={{ marginRight: 8 }}
      checked={selected}
    />
    <Typography noWrap>{item && item?.name}</Typography>
  </li>
);

export const formatValue = (value, key) => {
  if (!value) {
    return [];
  }
  if (Array.isArray(value)) {
    return value.map(val => ({ [key]: val }));
  }
  if (!Array.isArray(value)) {
    return [{ [key]: value }];
  }
  return value;
};

export const formatCurrentFilters = filters =>
  Object.entries(filters).reduce((finalFilters, [key, value]) => {
    if (key === 'sort_by') finalFilters[key] = value.sortBy;
    else if (key === 'qa_status')
      finalFilters[key] = value.map(({ name }) => name);
    else finalFilters[key] = value.map(({ id }) => id);
    return finalFilters;
  }, {});

export const updateFiltersFromSearch = (search, filterOptions) => {
  return Object.entries(search).reduce((updated, [key, value]) => {
    const ids = formatValue(value, 'id');
    if (filterOptions[key]) {
      updated[key] = filterOptions[key].filter(item =>
        ids.some(type => item.id === type.id)
      );
    }
    return updated;
  }, {});
};
