import { Paper } from '@mui/material';
import { DataGridPremium as MuiDataGrid } from '@mui/x-data-grid-premium';
import PT from 'prop-types';
import CustomNoRowsOverlay from './no-rows-overlay';
import CustomToolbar from './toolbar';

function DataGrid({
  exportName,
  showToolbar,
  loading,
  rows,
  columns,
  disableColumnMenu,
  hideFooterPagination,
  density,
  sortingOrder,
  height,
  autoHeight,
  rowHeight,
  noRowsMessage,
  setSelectedRowId,
  searchToolbar,
  toggleToolbar,
  DetailPanelContentComponent,
  ...rest
}) {
  const slots = {
    toolbar: showToolbar && CustomToolbar,
    NoRowsOverlay: () => CustomNoRowsOverlay(noRowsMessage, loading),
  };
  if (rest?.footer) {
    slots.footer = rest.footer;
  }

  return (
    <Paper
      sx={{
        height: autoHeight ? '100%' : height || undefined,
        width: '100%',
      }}
    >
      <MuiDataGrid
        experimentalFeatures={{ ariaV7: true }}
        rows={rows}
        columns={columns}
        getDetailPanelContent={DetailPanelContentComponent}
        getDetailPanelHeight={() => 'auto'}
        autoHeight={autoHeight}
        loading={loading}
        sortingOrder={sortingOrder}
        slots={{ ...slots }}
        slotProps={{
          toolbar: {
            exportName,
            searchToolbar,
            toggleToolbar,
          },
        }}
        disableColumnMenu={disableColumnMenu}
        hideFooterPagination={hideFooterPagination}
        // eslint-disable-next-line no-unneeded-ternary
        getRowHeight={() => (rowHeight ? rowHeight : 'auto')}
        getEstimatedRowHeight={() => 200}
        density={density}
        throttleRowsMs={1000}
        onRowSelectionModelChange={rowId => {
          setSelectedRowId(rowId[0]);
        }}
        keepNonExistentRowsSelected
        {...rest}
        localeText={{
          // List of translation options for future reference:
          // https://github.com/mui/mui-x/blob/HEAD/packages/grid/x-data-grid/src/constants/localeTextConstants.ts
          // Root
          noResultsOverlayLabel: 'No results found.',
          // toolbar button names
          toolbarColumns: 'Columns',
          toolbarFilters: 'Filters',
          toolbarExport: 'Export',
          // Export options
          toolbarExportCSV: 'Download as CSV',
          toolbarExportPrint: 'Print',
          // Filters toolbar button text
          toolbarFiltersLabel: 'Show filters',
          toolbarFiltersTooltipHide: 'Hide filters',
          toolbarFiltersTooltipShow: 'Show filters',
          // eslint-disable-next-line no-confusing-arrow
          toolbarFiltersTooltipActive: count =>
            count !== 1
              ? `${count} ${'active filter'}`
              : `${count} ${'active filters'}`,
          // Columns panel text
          columnsPanelTextFieldLabel: 'Find column',
          columnsPanelTextFieldPlaceholder: 'Column title',
          columnsPanelShowAllButton: 'Show all',
          columnsPanelHideAllButton: 'Hide all',
          // Filter panel text
          filterPanelDeleteIconLabel: 'Delete',
          filterPanelOperators: 'Operator',
          filterPanelOperatorAnd: 'And',
          filterPanelOperatorOr: 'Or',
          filterPanelColumns: 'Columns',
          filterPanelInputLabel: 'Value',
          filterPanelInputPlaceholder: 'Column title',
          filterOperatorContains: 'contains',
          filterOperatorEquals: 'equals',
          filterOperatorStartsWith: 'starts with',
          filterOperatorEndsWith: 'ends with',
          filterOperatorIsEmpty: 'is empty',
          filterOperatorIsNotEmpty: 'is not empty',
          filterOperatorIsAnyOf: 'is any of',
        }}
      />
    </Paper>
  );
}

DataGrid.propTypes = {
  exportName: PT.string,
  height: PT.oneOfType([PT.string, PT.number]),
  showToolbar: PT.bool,
  toggleToolbar: PT.bool,
  autoHeight: PT.bool,
  disableColumnMenu: PT.bool,
  hideFooterPagination: PT.bool,
  sortingOrder: PT.arrayOf(PT.string),
  loading: PT.bool.isRequired,
  columns: PT.arrayOf(
    PT.shape({
      field: PT.string.isRequired,
      headerName: PT.string.isRequired,
      type: PT.string,
    })
  ).isRequired,
  rows: PT.arrayOf(
    PT.shape({
      id: PT.string,
      userId: PT.string,
    })
  ).isRequired,
  density: PT.oneOf(['comfortable', 'compact', 'standard']),
  noRowsMessage: PT.string,
  rowHeight: PT.oneOfType([PT.number, PT.string]),
  setSelectedRowId: PT.func,
  searchToolbar: PT.bool,
  DetailPanelContentComponent: PT.func,
};

DataGrid.defaultProps = {
  exportName: '',
  sortingOrder: ['desc', 'asc'],
  showToolbar: true,
  toggleToolbar: false,
  disableColumnMenu: true,
  hideFooterPagination: false,
  density: 'compact',
  height: 300,
  autoHeight: false,
  noRowsMessage: 'No Rows',
  rowHeight: 'auto',
  setSelectedRowId: () => {},
  searchToolbar: false,
  DetailPanelContentComponent: undefined,
};

export default DataGrid;
