import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { parametersApi } from 'api';
import { isEmpty } from 'lodash';

export default (push, availableParameterTypes, values, remove) => {
  const [rowSelectionModel, setRowSelectionModel] = useState(
    values?.parameterTypes?.map(({ parameterTypeId }) => parameterTypeId) || []
  );
  const [openParameterCreator, setOpenParameterCreator] = useState(false);
  const [parameterTypeRows, setParameterTypeRows] = useState([]);
  const [afterKey, setAfterKey] = useState(null);
  const [rowsLoading, setRowsLoading] = useState(true);
  const { projectId, assetId } = useParams();
  const [searchString, setSearchString] = useState(null);
  const [allLoaded, setAllLoaded] = useState(false);
  const [error, setError] = useState(undefined);

  const getAllParameterTypes = async (after, searchQuery) => {
    let query = {
      page_limit: 100,
      sort_by: 'name',
      order: 'asc',
      scope_id: projectId,
      not_project_id: projectId,
      show_local: true,
      show_global: true,
    };

    if (after) {
      query.after = after;
    }
    if (assetId) {
      query.not_asset_id = assetId;
    }
    if (!assetId) {
      query.not_asset_id = '';
    }
    if (searchQuery?.search?.length) {
      query = {
        ...query,
        ...searchQuery,
      };
    }
    try {
      const response = await parametersApi('getAllParameterTypes', query);
      if (response) {
        const { parameterTypes: data, paging } = response;
        const mappedResponse = data.map(type => {
          return {
            id: uuidv4(),
            parameterTypeId: type.id,
            parameterTypeName: type.name,
            dataType: type.dataType,
            globalParameter: type.globalParameter,
            status: 'unanswered',
            value: null,
            unitTypeId: type.unitType?.id || null,
            unit: null,
            name: type.name,
            source: null,
            tags: [],
            disciplines: [],
            checked: true,
            currentAvailableParameter: !!availableParameterTypes.find(
              param => param.parameterTypeId === type.id
            ),
            isNewItemType: !availableParameterTypes.find(
              param => param.parameterTypeId === type.id
            ),
            isNewParameterType: false,
          };
        });
        setParameterTypeRows(curr => [...curr, ...mappedResponse]);
        if (paging?.cursors?.after) {
          setAfterKey(paging.cursors.after);
        } else {
          setAllLoaded(true);
          setAfterKey(null);
        }
        setRowsLoading(false);
      }
    } catch (err) {
      setAllLoaded(true);
      setError(err);
      setRowsLoading(false);
    }
  };

  useEffect(() => {
    // custom parameter creator close resets table
    if (!openParameterCreator && parameterTypeRows.length) {
      setParameterTypeRows([]);
      setAfterKey(null);
      setSearchString(null);
      setAllLoaded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openParameterCreator, projectId]);

  const handleSearch = () => {
    setAfterKey(null);
    setParameterTypeRows([]);
    setAllLoaded(false);
  };

  useEffect(() => {
    // search string updated
    if (searchString && !afterKey && isEmpty(parameterTypeRows) && !allLoaded) {
      getAllParameterTypes(null, { search: searchString });
    }
    // 1st load or after deleting search string or after custom creator closed
    if (
      !searchString &&
      !afterKey &&
      isEmpty(parameterTypeRows) &&
      !allLoaded
    ) {
      getAllParameterTypes(null, {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString, parameterTypeRows, afterKey, allLoaded]);

  // TODO add options functionality once design agreed
  // const itemForImport = values?.itemsImport;
  // useEffect(() => {
  //   const getAllOptions = async () => {
  //     setOptionsLoading(true);
  //     const query = {
  //       parameter_type_id: itemForImport[itemForImport.length - 1]?.id,
  //     };
  //     const itemOptions = [];
  //     const allOptions = await parametersApi('getAllItemTypes', query).catch(
  //       handleApiError
  //     );
  //     if (allOptions) {
  //       const { itemTypes } = allOptions;
  //       const itemTypesWithOptions = itemTypes.filter(
  //         itemType => itemType.hasOptions === true
  //       );
  //       if (itemTypesWithOptions.length !== 0) {
  //         const optionPromises = await Promise.all(
  //           itemTypesWithOptions.map(async itemType => {
  //             const { options } = await parametersApi('getOptions', {
  //               item_type_id: itemType.id,
  //             });
  //             return options;
  //           })
  //         );
  //         optionPromises.forEach(options => {
  //           options.forEach(option => {
  //             const existingOption = itemOptions.find(
  //               // eslint-disable-next-line max-nested-callbacks
  //               item => item.value === option.value
  //             );
  //             if (!existingOption) {
  //               itemOptions.push(option);
  //             }
  //           });
  //         });
  //       }
  //       setValueOptions(itemOptions);
  //       setOptionsLoading(false);
  //     }
  //   };
  //   if (values?.itemsImport) {
  //     setValueOptions([]);
  //     getAllOptions();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [itemForImport]);

  const updateSelectionModel = newRowSelectionModel => {
    const { parameterTypes, itemsImport, parameterTypesImport } = values;
    const filtered = parameterTypeRows.filter(param => {
      return (
        newRowSelectionModel.includes(param.parameterTypeId) &&
        !parameterTypes?.find(
          ({ parameterTypeId }) => parameterTypeId === param.parameterTypeId
        )
      );
    });
    if (filtered.length) {
      filtered.forEach(parameterType => {
        if (!parameterType.currentAvailableParameter) {
          push('itemsImport', parameterType);
        }
        push('parameterTypes', parameterType);
      });
    } else {
      const toRemove = parameterTypes?.filter(({ parameterTypeId }) => {
        return !newRowSelectionModel.includes(parameterTypeId);
      });
      toRemove?.forEach(({ parameterTypeId }) => {
        const parameterIndex = parameterTypes?.findIndex(
          param => param.parameterTypeId === parameterTypeId
        );
        if (parameterIndex >= 0) remove('parameterTypes', parameterIndex);

        const itemsIndex = itemsImport?.findIndex(
          param => param.parameterTypeId === parameterTypeId
        );
        if (itemsIndex >= 0) remove('itemsImport', itemsIndex);

        const parameterTypeIndex = parameterTypesImport?.findIndex(
          paramType => paramType.id === parameterTypeId
        );
        if (parameterTypeIndex >= 0)
          remove('parameterTypesImport', parameterTypeIndex);
      });
    }
    setRowSelectionModel(newRowSelectionModel);
  };

  return {
    rowSelectionModel,
    setRowSelectionModel,
    updateSelectionModel,
    openParameterCreator,
    setOpenParameterCreator,
    parameterTypeRows,
    setParameterTypeRows,
    afterKey,
    setAfterKey,
    rowsLoading,
    setRowsLoading,
    getAllParameterTypes,
    searchString,
    setSearchString,
    handleSearch,
    error,
  };
};
