import { Grid, Typography, Button, CircularProgress } from '@mui/material';
import AppFooter from 'components/footer';
import {
  StyledContainer,
  StyledGrid,
  StyledTypography,
} from './unauthenticated-styles';

function Authorizing() {
  return (
    <>
      <main id="main-content" tabIndex="-1" data-testid="authorising-page">
        <StyledContainer maxWidth="lg">
          <StyledGrid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={4}
          >
            <Grid item sm={12} md={6}>
              <StyledTypography
                variant="h3"
                component="h1"
                color="inherit"
                gutterBottom
              >
                Digital Design Brief
              </StyledTypography>
              <Typography variant="h5" component="p" color="inherit">
                Digital Design Brief (DDB) is Arup&apos;s platform for storing
                and managing the assets and parameters that define our projects.
              </Typography>
              <Button
                variant="outlined"
                disabled
                data-cy="login-in-progress"
                startIcon={<CircularProgress size={30} thickness={6} />}
                sx={{ marginTop: theme => theme.spacing(4) }}
              >
                Login in progress
              </Button>
            </Grid>
            <Grid item xs>
              <Typography>See below with help to login</Typography>
              <ul>
                <li>
                  <Typography>
                    Please ensure popups are unblocked when logging in
                  </Typography>
                </li>
                <li>
                  <Typography>
                    You need to be invited to use the application before logging
                    in
                  </Typography>
                </li>
              </ul>
            </Grid>
          </StyledGrid>
        </StyledContainer>
      </main>
      <AppFooter />
    </>
  );
}

export default Authorizing;
