import { formatValueUnit, formatRelativeDate } from 'utils';

export default function formatParameterInfo(parameter) {
  const status = parameter?.selectedEntry?.status || 'unanswered';
  const values = parameter?.selectedEntry?.values;
  const createdAt = parameter?.selectedEntry?.createdAt;
  const createdBy = parameter?.selectedEntry?.createdBy;
  const name = parameter?.parameterType?.name || '';
  const parents = parameter?.parents;
  const source = parameter?.selectedEntry?.source || {};
  const currValue = values?.[0];
  const value =
    currValue?.value.toString() || (currValue?.value === false ? 'false' : '');
  const parameterValue = value;
  const units = currValue?.unit?.symbol || '';
  const valueWithUnit = formatValueUnit(parameterValue, units);
  const assetName = parents?.[0]?.name || '';
  const staffName = createdBy?.staffName || '';
  const staffNameWithDate = `${staffName} ${formatRelativeDate(
    createdAt,
    undefined
  )}`;

  return {
    id: parameter.id,
    status,
    name,
    parameterValue,
    units,
    valueWithUnit,
    assetName,
    staffName,
    createdAt,
    staffNameWithDate,
    source,
    deletedAt: parameter.deletedAt,
  };
}
