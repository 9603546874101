/* eslint-disable no-nested-ternary */
import { parameterSetTemplateType } from 'types';
import { Grid, CardHeader, Typography, Skeleton } from '@mui/material';
import PT from 'prop-types';
import { capitalize, isEmpty } from 'lodash';
import HeaderButtons from 'routes/parameters-page/common/header-buttons/headerButtons';

const DataTemplateHeader = ({
  dataTemplate,
  loading,
  headerActions,
  label,
}) => {
  return (
    <CardHeader
      sx={{ bgcolor: theme => theme.palette.action.hover }}
      aria-label="template-details-header"
      title={
        loading ? (
          <Skeleton
            variant="text"
            width="100%"
            height="40px"
            aria-label="loading"
          />
        ) : (
          <Grid container>
            <Typography variant="h5" aria-label="template-name">
              {!dataTemplate.name
                ? 'No template selected'
                : !dataTemplate.publishedAt
                ? `${capitalize(dataTemplate?.name)} (${'Draft'})`
                : capitalize(dataTemplate?.name)}
            </Typography>
            {!isEmpty(headerActions) && (
              <HeaderButtons headerActions={headerActions} />
            )}
          </Grid>
        )
      }
      subheader={
        label !== 'dataSet' && (
          <Typography variant="caption" aria-label="set-description">
            {capitalize(dataTemplate?.description) ||
              'The selected template description will show here'}
          </Typography>
        )
      }
      titleTypographyProps={{ color: theme => theme.palette.secondary }}
    />
  );
};

DataTemplateHeader.propTypes = {
  dataTemplate: parameterSetTemplateType,
  loading: PT.bool.isRequired,
  headerActions: PT.arrayOf(PT.node),
  label: PT.string,
};

DataTemplateHeader.defaultProps = {
  dataTemplate: {},
  headerActions: [],
  label: '',
};

export default DataTemplateHeader;
