import PT from 'prop-types';
import Alert from 'components/alerts';

function ErrorHandling({ addingProjectErrors }) {
  const errorHandler = err => {
    if (err.status === 409 && !err.isConfidential)
      return 'This project has already been added to DDB. Please cancel or add another project.';
    if (err.isConfidential)
      return 'This project has already been added to DDB but may be confidential. Contact the PM or PD to request access';
    return err.msg || err.message;
  };
  return (
    <>
      <br />
      <Alert text={errorHandler(addingProjectErrors)} />
    </>
  );
}

ErrorHandling.propTypes = {
  addingProjectErrors: PT.shape({
    status: PT.number,
    message: PT.string,
    msg: PT.string,
  }).isRequired,
};

export default ErrorHandling;
