import { useContext } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Typography,
  Box,
  Container,
} from '@mui/material';
import { ReportingContext } from 'context';
import steps from '../steps';
import Buttons from '../buttons';
import { StyledBox } from './stepper-styles';
import useButtons from '../buttons/hooks/useButtons';

const HorizontalStepper = () => {
  const { activeStep, setActiveStep } = useContext(ReportingContext);
  const { isNextDisabled } = useButtons(activeStep, setActiveStep);
  return (
    <Box
      sx={{
        width: '100%',
        height: 'calc(100vh - 164px)',
      }}
    >
      <StyledBox aria-label="horizontal-stepper">
        <Stepper activeStep={activeStep} aria-label="stepper">
          {steps.map(({ label, optional }, index) => {
            const stepProps = {};
            const labelProps = {};
            if (optional) {
              labelProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
            }
            return (
              <Step key={label} {...stepProps} aria-label="step">
                <StepLabel
                  {...labelProps}
                  aria-label="step-label"
                  sx={{
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                  onClick={() => {
                    if (
                      (index === activeStep + 1 && !isNextDisabled) ||
                      index < activeStep
                    ) {
                      setActiveStep(index);
                    }
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </StyledBox>

      <Container fixed aria-label="step-content">
        <Box>{steps[activeStep].content}</Box>
      </Container>
      <Buttons activeStep={activeStep} setActiveStep={setActiveStep} />
    </Box>
  );
};

export default HorizontalStepper;
