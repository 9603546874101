import PT from 'prop-types';
import {
  DialogActions,
  DialogContent,
  useMediaQuery,
  Typography,
  Skeleton,
} from '@mui/material';
import WarningDialog from 'components/dialogs/warning';
import {
  StyledDialog,
  StyledGridWrapper,
  StyledGrid,
  StyledDialogTitle,
  StyledHeaderGrid,
} from './wizard.styles';
import useWizard from './hooks';
import Title from './title';
import Actions from './actions';
import HorizontalStepper from './horizontal-stepper';
import useActions from './actions/hooks/useActions';

const Wizard = ({
  wizard,
  values,
  open,
  steps,
  handleToggleOpen,
  submitting,
  handleSubmit,
  errors,
  setError,
  ...rest
}) => {
  const fullScreen = useMediaQuery(theme => theme.breakpoints.down('lg'));
  const {
    activeStep,
    setActiveStep,
    warning,
    handleBack,
    handleNext,
    handleClose,
    handleFinish,
    isLoading,
    breadcrumbs,
  } = useWizard(
    open,
    wizard,
    handleToggleOpen,
    handleSubmit,
    steps.length - 1,
    setError
  );
  const { header, content: StepContent } = steps[activeStep];
  const { actionButtons } = useActions(
    activeStep,
    handleClose,
    handleBack,
    handleNext,
    handleFinish,
    values,
    errors,
    submitting
  );

  return (
    <>
      <StyledDialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        aria-label={`${wizard}-wizard`}
      >
        <HorizontalStepper
          activeStep={activeStep}
          steps={steps}
          setActiveStep={setActiveStep}
          actionButtons={actionButtons}
          wizard={wizard}
        />
        <StyledDialogTitle>
          {isLoading ? (
            <Skeleton height={40} width={200} aria-label="title is loading" />
          ) : (
            <Title
              wizard={wizard}
              activeStep={activeStep}
              breadcrumbs={breadcrumbs}
            />
          )}
        </StyledDialogTitle>
        <DialogContent aria-label="dialog-content">
          <StyledGridWrapper container>
            <StyledHeaderGrid item xs={12} sm={12} md={11}>
              <Typography
                variant="h6"
                sx={{ fontWeight: 'light' }}
                aria-label="step-description"
              >
                {header}
              </Typography>
            </StyledHeaderGrid>
            <StyledGrid item xs={12} sm={12} md={11} wizard={wizard}>
              <StepContent values={values} open={open} {...rest} />
            </StyledGrid>
          </StyledGridWrapper>
        </DialogContent>
        <DialogActions
          sx={{
            borderTop: theme => `1px solid ${theme.palette.divider}`,
            marginTop: 'auto',
          }}
          aria-label="dialog-actions"
        >
          <Actions
            wizard={wizard}
            activeStep={activeStep}
            handleClose={handleClose}
            handleBack={handleBack}
            handleFinish={handleFinish}
            handleNext={handleNext}
            submitting={submitting}
            values={values}
            errors={errors}
            actionButtons={actionButtons}
          />
        </DialogActions>
      </StyledDialog>
      {warning.open && (
        <WarningDialog
          open={warning.open}
          handleAction={warning.onAccept}
          handleClose={warning.onClose}
          title={warning.title}
          body={warning.body}
          primaryButtonText={
            warning.primaryButtonText ? warning.primaryButtonText : 'Close'
          }
          secondaryButtonText="Cancel"
        />
      )}
    </>
  );
};

Wizard.propTypes = {
  wizard: PT.string.isRequired,
  open: PT.bool.isRequired,
  submitting: PT.bool,
  steps: PT.arrayOf(
    PT.shape({
      label: PT.string.isRequired,
      optional: PT.bool.isRequired,
      header: PT.string.isRequired,
      content: PT.func.isRequired,
    })
  ).isRequired,
  handleToggleOpen: PT.func.isRequired,
  setError: PT.func,
  handleSubmit: PT.func.isRequired,
  values: PT.shape({}) || PT.array,
  errors: PT.shape({ parameterTypes: PT.arrayOf(PT.shape({})) }),
};

Wizard.defaultProps = {
  submitting: false,
  errors: { parameterTypes: [] },
  values: {},
  setError: () => {},
};

export default Wizard;
