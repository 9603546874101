import PT from 'prop-types';
import { Login as LoginIcon } from '@mui/icons-material';
import { MenuItem, Typography, Button, useMediaQuery } from '@mui/material';
import { StyledIconButton } from '../../../buttons';
import { handleLogin } from '../hooks';

function Login({ title, handleClose }) {
  const { userLogin } = handleLogin();
  const isSmallScreen = useMediaQuery('(max-width:1100px)');

  return (
    <>
      {title && (
        <MenuItem
          component="a"
          color="inherit"
          data-cy="login"
          onClick={() => {
            userLogin();
            handleClose();
          }}
          aria-label="login"
        >
          <LoginIcon data-testid="login" />
          <Typography
            variant="button"
            sx={{ paddingLeft: theme => theme.spacing(1) }}
          >
            {title}
          </Typography>
        </MenuItem>
      )}
      {!title && isSmallScreen && (
        <StyledIconButton
          title="login"
          aria-label="login"
          data-cy="login"
          component="a"
          onClick={userLogin}
          icon={<LoginIcon data-testid="login-icon" />}
        />
      )}
      {!title && !isSmallScreen && (
        <Button
          title="login"
          component="a"
          onClick={userLogin}
          aria-label="login"
          data-cy="login"
          startIcon={<LoginIcon data-testid="login" />}
        >
          LOG IN
        </Button>
      )}
    </>
  );
}

Login.propTypes = {
  title: PT.string,
  handleClose: PT.func,
};

Login.defaultProps = {
  title: null,
  handleClose: null,
};

export default Login;
