import React from 'react';
import { ProjectPermissionContext } from 'context';

export default () => {
  const {
    writePermission,
    checkPermission,
    approvePermission,
    adminPermission,
  } = React.useContext(ProjectPermissionContext);

  const renderTooltipDeleteIcon = type => {
    if (!writePermission) {
      return ["You don't have permission to do this", true];
    }
    if (type === 'asset') {
      return ['Remove Asset - you can restore this later', false];
    }
    if (type === 'source') {
      return ['Delete Source', false];
    }
    if (type === 'dataSet') {
      return ['Remove Data Set', false];
    }
    return ['Remove', false];
  };

  const renderTooltipEditCloseIcon = (isEditing, type) => {
    if (!writePermission) {
      return ["You don't have permission to do this", true];
    }
    if (isEditing) {
      return ['Cancel Editing', false];
    }
    if (type === 'Asset') {
      return ['Edit Asset Name', false];
    }
    if (type === 'Source') {
      return ['Edit Source Name', false];
    }
    if (type === 'dataSet') {
      return ['Edit Data Set', false];
    }
    return ['Edit Parameter', false];
  };

  const renderTooltipReject = status => {
    if (status === 'answered' && (checkPermission || writePermission))
      return ['Reject', false];
    if (status === 'checked' && (approvePermission || writePermission))
      return ['Reject', false];
    if (status === 'approved' && (adminPermission || approvePermission))
      return ['Reject', false];
    return ["You don't have permission to do this", true];
  };

  const renderTooltipDoneIcon = status => {
    if (status === 'answered' && checkPermission) {
      return ['Mark revision status as checked', false];
    }
    if (status === 'checked' && approvePermission) {
      return ['Mark revision status as approved', false];
    }
    if (status === 'approved') {
      return ['Parameter approved', true];
    }
    if (status === 'rejected' && (checkPermission || writePermission)) {
      return ['Reset revision status to answered', false];
    }

    return ["You don't have permission to do this", true];
  };

  const renderTooltipRestore = () => {
    if (writePermission) return ['Restore', false];
    return ["You don't have permission to do this", true];
  };

  return {
    renderTooltipDeleteIcon,
    renderTooltipEditCloseIcon,
    renderTooltipReject,
    renderTooltipDoneIcon,
    renderTooltipRestore,
  };
};
