import { useState } from 'react';
import { DynamicFeed } from '@mui/icons-material';
import { Typography } from '@mui/material';
import DataTemplateDetails from 'components/data-template-details';
import DataDrawerGrid from '../../routes/parameters-page/common/grid';

function Versions() {
  const [openVersions, setOpenVersions] = useState(false);
  const handleOpen = () => setOpenVersions(!openVersions);

  // TO DO: Versions content

  return (
    <DataDrawerGrid
      icon={
        <DynamicFeed
          data-testid="versions-icon"
          sx={{
            color: theme => theme.palette.primary.black,
          }}
        />
      }
      caption={
        <Typography
          variant="caption"
          color="textPrimary"
          data-testid="versions-label"
        >
          Versions
        </Typography>
      }
      content={
        <DataTemplateDetails
          open={openVersions}
          handleOpen={handleOpen}
          content="Versions content"
          subtitle="Versions"
        />
      }
    />
  );
}

Versions.propTypes = {};

export default Versions;
