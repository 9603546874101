import { useContext } from 'react';
import { SourcesContext } from 'context';
import { emojiValidation, stringValidation } from 'utils';
import { findIndex } from 'lodash';
import { referenceDataApi } from 'api';

const useEditSource = () => {
  const {
    setOpenEditingForm,
    selectedSource,
    setSelectedSource,
    setAllSources,
    setSourceErrorOrSuccess,
  } = useContext(SourcesContext);

  const patchSelectedSource = values => {
    setSourceErrorOrSuccess({});
    const patchSource = async () => {
      try {
        const body = {
          title: values.sourceName,
          reference: values.sourceReference,
        };
        if (values.revisionDate) {
          body.date_day = values.revisionDate.getDate().toString();
          body.date_month = (values.revisionDate.getMonth() + 1).toString();
          body.date_year = values.revisionDate.getFullYear().toString();
        }
        if (values.url) {
          body.url = values.url;
        }
        const response = await referenceDataApi('patchSource', {
          id: selectedSource.id,
          body,
        });
        if (response) {
          const { source } = response;
          setSelectedSource(source);
          setOpenEditingForm(false);
          setAllSources(curr => {
            const indexOfUpdate = findIndex(
              curr,
              existing => existing.id === source.id
            );
            curr[indexOfUpdate] = { ...source }; // eslint-disable-line no-param-reassign
          });
        }
      } catch (error) {
        setSourceErrorOrSuccess({
          msg: error?.response?.data?.msg,
          details: error?.response?.data?.details,
        });
      }
    };
    patchSource();
  };

  const validateSourceInputs = values => {
    const typesWithoutUrl = ['Assumption', 'Derived Value'];
    const errors = {};
    if (!values.sourceName) {
      errors.sourceName = 'Required';
    }
    if (!values.sourceReference) {
      errors.sourceReference = 'Required';
    }
    if (!values.revisionDate) {
      errors.revisionDate = 'Required';
    }
    if (
      values?.revisionDate &&
      !(
        values?.revisionDate instanceof Date &&
        // eslint-disable-next-line no-restricted-globals
        !isNaN(values?.revisionDate)
      )
    ) {
      errors.revisionDate = 'Invalid Date';
    }
    if (
      values?.revisionDate &&
      values?.revisionDate instanceof Date &&
      values?.revisionDate?.getFullYear() < 1000
    ) {
      errors.revisionDate = 'Invalid Date';
    }
    if (!values.url && !typesWithoutUrl.includes(values.sourceType)) {
      errors.url = 'Required';
    }
    if (emojiValidation(values.sourceName)) {
      errors.sourceName = 'Invalid Character';
    }
    if (emojiValidation(values.url)) {
      errors.url = 'Invalid Character';
    }
    if (emojiValidation(values.sourceReference)) {
      errors.sourceReference = 'Invalid Character';
    }
    if (!stringValidation(values.sourceName)) {
      errors.sourceName = 'Required';
    }
    if (
      !stringValidation(values.url) &&
      !typesWithoutUrl.includes(values.sourceType)
    ) {
      errors.url = 'Required';
    }
    if (!stringValidation(values.sourceReference)) {
      errors.sourceReference = 'Required';
    }
    return errors;
  };

  return {
    validateSourceInputs,
    patchSelectedSource,
  };
};

export default useEditSource;
