import axios from 'axios';
import { chunk } from 'lodash';
import { getGraphToken, getToken } from 'auth';

const BASE_URL = 'https://graph.microsoft.com/v1.0';
const ARUP_STAFF_GROUP_ID = 'e0963b47-e2b3-4083-87de-2752a2bd9f2e';
const ARUP_CASUAL_STAFF_GROUP_ID = '0fd7a585-b5b6-42d7-8fac-24816df5ffc6';

const parseJwt = inputToken => {
  if (inputToken) {
    const base64Url = inputToken.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }
  return {};
};

export const getUserInformation = async () => {
  const token = await getGraphToken();
  const response = await axios.get(`${BASE_URL}/me`, {
    cache: true,
    headers: { Authorization: `Bearer ${token}` },
  });
  const ddbToken = await getToken();
  const ddbUser = parseJwt(ddbToken);
  return { ...response.data, roles: ddbUser.roles || [] };
};

// https://graph.microsoft.com/v1.0/sites/arup.sharepoint.com:/sites/DigitalDesignBriefApplication
const siteId =
  'arup.sharepoint.com,8911f928-17a5-4817-9fb5-726514b099e0,1d5bd7af-0d1e-4a52-b3fd-d228045cb1ae';

export const getSharepointInfo = async id => {
  const token = await getGraphToken();
  const response = await axios.get(
    `${BASE_URL}/sites/${siteId}/lists/${id}/items?expand=fields`,
    {
      cache: true,
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

const getTenantUsers = async (emails, token) => {
  const url = `${BASE_URL}/users`;
  const headers = { Authorization: token || (await getGraphToken()) };
  try {
    const users = [];
    const batchedEmails = chunk(emails, 7); //  max query size is 15
    await Promise.all(
      batchedEmails.map(async batchedEmail => {
        const params = {
          $select: 'id,displayName,givenName,mail,userPrincipalName',
          $filter: batchedEmail
            .map(
              email => `mail eq '${email}' or userPrincipalName eq '${email}'`
            )
            .join(' or '),
        };
        const { data } = await axios.get(url, { params, headers });
        users.push(data.value);
      })
    );
    return users.flat();
  } catch (err) {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      status: err.response.status,
      msg: err.message,
      details: err.response.data,
    });
  }
};

export const checkInternalUsers = async emails => {
  const token = await getGraphToken();
  const url = `${BASE_URL}/users`;
  try {
    const headers = { Authorization: `Bearer ${token}` };

    const users = await getTenantUsers(emails, headers.Authorization);

    const params = {
      $filter: `id in ('${ARUP_STAFF_GROUP_ID}', '${ARUP_CASUAL_STAFF_GROUP_ID}')`,
      $count: 'true',
    };

    const internalUsersChecks = await Promise.all(
      users.map(async user => {
        const internal = await axios.get(`${url}/${user.id}/memberOf`, {
          headers: { ...headers, ConsistencyLevel: 'eventual' },
          params,
        });
        return {
          ...user,
          internalUser: internal?.data.value.length > 0,
        };
      })
    );
    return internalUsersChecks;
  } catch (err) {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      status: err.response.status,
      msg: err.message,
      details: err.response.data,
    });
  }
};
