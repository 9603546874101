import { Folder as FolderIcon } from '@mui/icons-material';
import PT from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { forwardRef } from 'react';
import {
  MenuItem,
  Typography,
  Button,
  Link,
  useMediaQuery,
} from '@mui/material';
import { StyledIconButton } from '../../../buttons';

const ProjectsLink = forwardRef((props, ref) => {
  const path = '/projects?sort_by=created_at&order=desc';
  return <Link to={path} ref={ref} {...props} component={RouterLink} />;
});

ProjectsLink.displayName = 'ProjectsLink';

function Projects({ title }) {
  const isSmallScreen = useMediaQuery('(max-width:1100px)');

  return (
    <>
      {title && (
        <MenuItem
          component={ProjectsLink}
          color="inherit"
          data-cy="projects"
          aria-label="projects"
        >
          <FolderIcon data-testid="projects" />
          <Typography
            variant="button"
            sx={{ paddingLeft: theme => theme.spacing(1) }}
          >
            {title}
          </Typography>
        </MenuItem>
      )}
      {!title && isSmallScreen && (
        <StyledIconButton
          title="projects"
          aria-label="projects"
          data-cy="projects"
          component={ProjectsLink}
          icon={<FolderIcon data-testid="projects" />}
        />
      )}
      {!title && !isSmallScreen && (
        <Button
          title="projects"
          component={ProjectsLink}
          aria-label="projects"
          data-cy="projects"
          startIcon={<FolderIcon data-testid="projects" />}
        >
          PROJECTS
        </Button>
      )}
    </>
  );
}

Projects.propTypes = {
  title: PT.string,
};

Projects.defaultProps = {
  title: null,
};

export default Projects;
