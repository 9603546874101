import { Suspense } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { Skeleton, Stack, useMediaQuery } from '@mui/material';
import Chatbot from 'components/chatbot';
import Feedback from './feedback';
import Docs from './docs';
import Bugs from './bugs';
import Environment from './environment';
import Contact from './contact';
import Login from './login';
import ManageAccount from './manage-account';
import Projects from './projects';

function MainMenu() {
  const isAuthenticated = useIsAuthenticated();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));

  return isAuthenticated ? (
    <div data-testid="mainMenu">
      <Suspense fallback={<Skeleton width={80} height={40} />}>
        <Stack direction="row" spacing={isSmallScreen ? 0 : 1}>
          <Environment />
          <Chatbot />
          <Projects />
          <Docs />
          <Feedback />
          <Bugs />
        </Stack>
      </Suspense>
    </div>
  ) : (
    <div>
      <Suspense fallback={<Skeleton width={80} height={40} />}>
        <Stack direction="row" spacing={1}>
          <Contact />
          <ManageAccount />
          <Login />
        </Stack>
      </Suspense>
    </div>
  );
}

export default MainMenu;
