import { Typography, Box, Skeleton } from '@mui/material';
import PT from 'prop-types';
import { parameterEventsPropTypes } from 'routes/history-page/history-content/prop-types/prop-types';
import useParameterAccordionDetails from './hooks/use-parameter-accordion-details';

const ParameterAccordionDetails = ({ history, expanded }) => {
  const { commentContent, isCommentLoaded } = useParameterAccordionDetails(
    history.comment,
    expanded
  );

  return (
    <Box aria-label="parameter-accordion-details">
      {history.eventType.endsWith('parameter.created') && (
        <Box
          sx={{ pb: theme => theme.spacing(0.5) }}
          aria-label="parameter-created-details"
        >
          <Typography variant="body2">Parameter was created.</Typography>
        </Box>
      )}
      {history.eventType.endsWith('entry.replaced') && (
        <Box
          sx={{ pb: theme => theme.spacing(0.5) }}
          aria-label="entry-replaced-details"
        >
          <Typography variant="body2" sx={{ pb: theme => theme.spacing(0.5) }}>
            {`${'Value'}: ${history.value}`}
          </Typography>
          {history.unit?.symbol && (
            <Typography
              variant="body2"
              sx={{ pb: theme => theme.spacing(0.5) }}
            >
              {`${'Unit'}: ${history.unit.symbol}`}
            </Typography>
          )}
          <Typography variant="body2" sx={{ pb: theme => theme.spacing(0.5) }}>
            {`${'Source'}: ${history.source.title}`}
          </Typography>
          {isCommentLoaded ? (
            <Typography
              variant="caption"
              fontWeight="500"
              sx={{ pb: theme => theme.spacing(0.5) }}
            >
              {`${'Comment'}: ${commentContent || ''}`}
            </Typography>
          ) : (
            <Skeleton variant="rectangular" width={800} height={20} />
          )}
        </Box>
      )}
      {history.eventType.endsWith('entry.revision.updated') && (
        <Box
          sx={{ pb: theme => theme.spacing(0.5) }}
          aria-label="entry-revision-updated-details"
        >
          <Typography variant="body2" sx={{ pb: theme => theme.spacing(0.5) }}>
            {`${'QA status change'}: ${history.status}`}
          </Typography>
          {isCommentLoaded ? (
            <Typography
              variant="caption"
              fontWeight="500"
              aria-label="revision-comment"
              sx={{ pb: theme => theme.spacing(0.5) }}
            >
              {`${'Comment'}: ${commentContent || ''}`}
            </Typography>
          ) : (
            <Skeleton
              aria-label="revision-comment"
              variant="rectangular"
              width={800}
              height={20}
            />
          )}
        </Box>
      )}
      {history.eventType.endsWith('parameter.removed') && (
        <Box
          sx={{ pb: theme => theme.spacing(0.5) }}
          aria-label="parameter-deleted-details"
        >
          <Typography variant="body2">Parameter was deleted.</Typography>
        </Box>
      )}
    </Box>
  );
};

ParameterAccordionDetails.propTypes = {
  expanded: PT.bool.isRequired,
  history: parameterEventsPropTypes.isRequired,
};

export default ParameterAccordionDetails;
