import { Menu as MenuIcon } from '@mui/icons-material';
import { Menu } from '@mui/material';
import { useIsAuthenticated } from '@azure/msal-react';
import { StyledIconButton } from 'components/buttons';
import Feedback from './feedback';
import { handleMobileMenu } from './hooks';
import Docs from './docs';
import Bugs from './bugs';
import Contact from './contact';
import ManageAccount from './manage-account';
import Login from './login';

function MobileMenu() {
  const isAuthenticated = useIsAuthenticated();
  const { anchorEl, handleMenu, handleClose } = handleMobileMenu();

  return (
    <div data-testid="mobileMenu">
      <StyledIconButton
        title="Open Menu"
        aria-label="Open Menu"
        onClick={handleMenu}
        icon={<MenuIcon />}
      />
      <Menu
        id="menu-mobile"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {isAuthenticated ? (
          <>
            <Docs handleClose={handleClose} title="Documentation" />
            <Feedback title="Provide Feedback" handleClose={handleClose} />
            <Bugs title="Report Issue" handleClose={handleClose} />
          </>
        ) : (
          <>
            <Contact title="Contact" handleClose={handleClose} />
            <ManageAccount title="Settings" handleClose={handleClose} />
            <Login title="Log In" handleClose={handleClose} />
          </>
        )}
      </Menu>
    </div>
  );
}

export default MobileMenu;
