export const emojiValidation = value => {
  return /[^\w\d\-_\s.,#!€£$%^&*;:@{}?=\-_`~'"()|\\/[\]<>±+]+/gi.test(value);
};

export const numberValidation = value => {
  return /^[+-]?(?=\.\d|\d)(?:0|[1-9]\d*)?(?:\.\d+)?(?:(?<=\d)(?:[eE][+-]?\d+))?$/.test(
    value
  );
};
export const intValidation = value => {
  return /^[-+]?\d*$/.test(value);
};

export const stringValidation = value => {
  return typeof value === 'string' && !/^\s*$/.test(value);
};

export const taxonomyNameValidation = value => {
  return !/['?"]/.test(value);
};
