import PT from 'prop-types';
import { Helmet } from 'react-helmet';
import isEmpty from 'lodash/isEmpty';

export default function MetaTitle({ title }) {
  const text = title;
  return <Helmet>{!isEmpty(title) && <title>{text || null}</title>}</Helmet>;
}

MetaTitle.propTypes = {
  title: PT.string,
};

MetaTitle.defaultProps = {
  title: null,
};
