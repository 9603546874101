import PropTypes from 'prop-types';
import { Typography, Grid } from '@mui/material';

function SnackbarMessage({ errorMessage, errorCode, defaultMessage }) {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      sx={{ paddingLeft: '20px' }}
    >
      <Typography variant="h6">
        {defaultMessage ||
          'An error occurred, please refresh the page and try again'}
      </Typography>
      <Typography>{`${errorCode}: ${errorMessage}`}</Typography>
    </Grid>
  );
}
SnackbarMessage.propTypes = {
  errorCode: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  defaultMessage: PropTypes.string,
};

SnackbarMessage.defaultProps = { defaultMessage: null };

export default SnackbarMessage;
