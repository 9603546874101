import config from 'config';
import WarningDialog from 'components/dialogs/warning';
import { Grid, Typography, Link } from '@mui/material';
import { Cookie } from '@mui/icons-material';
import useGetCookies from 'routes/user-settings/cookies/hooks/use-get-cookies';

function CookieWarning() {
  const { openCookiesWarning, onAcceptAnalyticCookies } = useGetCookies();

  return (
    <WarningDialog
      open={openCookiesWarning === true}
      disableClose
      handleAction={onAcceptAnalyticCookies}
      title={
        <Grid container aria-label="cookies-header">
          <Grid item xs={1}>
            <Cookie fontSize="large" aria-label="cookie-icon" />
          </Grid>
          <Grid item xs={11}>
            <Typography variant="h4" component="h2">
              Our use of cookies
            </Typography>
          </Grid>
        </Grid>
      }
      body={
        <Grid container aria-label="cookies-body">
          <Grid>
            Analytical/Performance cookies are required for internal Arup users.
            These have been changed automatically. Visit the User Settings page
            to view or update your cookie preferences.
          </Grid>
          <Grid sx={{ paddingTop: theme => theme.spacing(1) }}>
            We use these cookies to collect information about how visitors use
            the Service. This data helps us improve the functionality and
            performance of the Service.
          </Grid>
          <Typography sx={{ paddingTop: theme => theme.spacing(1) }}>
            {'For more information about the cookies we use, see our '}{' '}
            <Link
              aria-label="link-to-cookies"
              underline="always"
              color="primary"
              target="_blank"
              variant="body2"
              href={config.url.policiesCookies}
            >
              Cookie Policy
            </Link>
          </Typography>
        </Grid>
      }
      primaryButtonText="Accept"
    />
  );
}
export default CookieWarning;
