/* eslint-disable no-nested-ternary */
import { StyledIconButton } from 'components/buttons';
import PT from 'prop-types';
import { Badge } from '@mui/material';
import { FilterList } from '@mui/icons-material';

function FiltersButton({ onClick, isActive, disabled, filterCount }) {
  return (
    <Badge
      aria-label="filters-badge"
      color="error"
      invisible={disabled && true}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      badgeContent={filterCount}
      overlap="circular"
    >
      <StyledIconButton
        title={disabled ? 'Unavailable' : 'Filter and Sort'}
        onClick={onClick}
        aria-label="Filter and Sort"
        disabled={disabled}
        icon={
          <FilterList
            data-testid="filterIcon"
            sx={{
              // eslint-disable-next-line no-confusing-arrow
              color: theme =>
                disabled
                  ? theme.palette.text.disabled
                  : isActive
                  ? theme.palette.action.active
                  : theme.palette.primary.main,
            }}
          />
        }
      />
    </Badge>
  );
}
FiltersButton.propTypes = {
  onClick: PT.func,
  isActive: PT.bool,
  disabled: PT.bool,
  filterCount: PT.number,
};

FiltersButton.defaultProps = {
  onClick: () => {},
  isActive: false,
  disabled: false,
  filterCount: 0,
};

export default FiltersButton;
