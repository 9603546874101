import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { FORM_ERROR } from 'final-form';
import { parametersApi } from 'api';
import { ParametersContext, AssetContext } from 'context';
import {
  emojiValidation,
  numberValidation,
  intValidation,
  stringValidation,
} from 'utils';

export default handleToggleOpen => {
  const { setParameters } = useContext(ParametersContext);
  const { theAsset } = useContext(AssetContext);
  const { projectId, assetId } = useParams();

  const validatePostedParameter = values => {
    const errors = values?.parameterTypes?.map(parameter => {
      let dateYear = '';
      if (parameter.value instanceof Date)
        dateYear = parameter.value.getFullYear();
      const parameterErrors = {};
      if (
        parameter.value !== false &&
        !parameter.value &&
        !parameter.unit &&
        !parameter.source
      ) {
        return parameterErrors;
      }
      if (
        parameter.dataType === 'float' &&
        !numberValidation(parameter.value)
      ) {
        parameterErrors.value = 'Invalid';
      }
      if (parameter.dataType === 'integer' && !intValidation(parameter.value)) {
        parameterErrors.value = 'Invalid';
      }
      if (emojiValidation(parameter.value)) {
        parameterErrors.value = 'Invalid';
      }
      if (parameter.value !== false && !parameter.value) {
        parameterErrors.value = 'Required';
      }
      if (parameter.value === null || parameter.value === undefined) {
        parameterErrors.value = 'Required';
      }
      if (parameter.unitTypeId && !parameter.unit) {
        parameterErrors.unit = 'Required';
      }
      if (!parameter.source) {
        parameterErrors.source = 'Required';
      }
      if (
        parameter.dataType === 'string' &&
        parameter.value !== undefined &&
        !stringValidation(parameter.value)
      ) {
        parameterErrors.value = 'Invalid';
      }
      if (
        parameter.dataType === 'date' &&
        !(
          parameter.value instanceof Date &&
          // eslint-disable-next-line no-restricted-globals
          !isNaN(parameter.value)
        )
      ) {
        parameterErrors.value = 'Invalid Date';
      }
      if (
        parameter.dataType === 'date' &&
        parameter.value instanceof Date &&
        (dateYear < 1000 || dateYear > 2500)
      ) {
        parameterErrors.value =
          'Invalid year - it needs to be between 1000 and 2500';
      }
      return parameterErrors;
    });
    return { parameterTypes: errors };
  };

  const submit = async values => {
    let error;

    const { itemsImport, parameterTypesImport } = values;

    if (parameterTypesImport?.length) {
      const parameterTypesToPost = parameterTypesImport.map(type => {
        return {
          id: type.id,
          name: type.name,
          data_type: type.dataType,
          default_unit_id: type.defaultUnitId || null,
          scope_id: projectId,
        };
      });
      const postParameterTypes = { parameter_types: [...parameterTypesToPost] };
      try {
        await parametersApi('postParameterTypes', {
          body: postParameterTypes,
        });
      } catch (err) {
        error = {
          [FORM_ERROR]: err?.response?.data,
        };
      }
    }

    if (itemsImport?.length) {
      const typesToPost = itemsImport.map(item => {
        return {
          parameter_type_id: item.parameterTypeId,
          asset_type_id: theAsset?.assetType?.id || null,
          asset_sub_type_id: theAsset?.assetSubType?.id || null,
          scope_id: projectId,
        };
      });

      const postItemTypes = { item_types: [...typesToPost] };

      try {
        await parametersApi('postItemTypes', {
          body: postItemTypes,
        });
      } catch (err) {
        error = {
          [FORM_ERROR]: err?.response?.data,
        };
      }
    }

    const newParameters = values.parameterTypes.map(newType => {
      const body = {
        project_id: projectId,
        parameter_type_id: newType.parameterTypeId,
      };

      if (assetId) body.parent_ids = [assetId];
      if (newType?.value || newType?.value === false)
        body.entries = {
          source_id: newType.source.id,
          values: [
            { value: newType.value, unit_id: newType?.unit?.id || null },
          ],
        };

      if (newType?.disciplines?.length)
        body.discipline_id = newType.disciplines.map(({ id }) => id);
      if (newType?.tags?.length)
        body.category_id = newType.tags.map(({ id }) => id);
      return body;
    });
    const response = await parametersApi('postParameters', {
      body: { parameters: newParameters },
    }).catch(err => {
      error = {
        [FORM_ERROR]: err?.response?.data,
      };
    });
    if (error) return error;

    const { parameters } = response;
    setParameters(curr => [...parameters, ...curr]);
    handleToggleOpen();
    return newParameters;
  };

  return {
    submit,
    validatePostedParameter,
  };
};
