import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Container, Link, useMediaQuery } from '@mui/material';
import AppFooter from 'components/footer';
import ContactOnboarding from 'components/contact-onboarding';
import ImageInformationSection from 'components/information-sections/image-information-section';
import { StyledDivider } from '../../home/styles';

const ProjectsLink = forwardRef((props, ref) => (
  <Link to="/projects" ref={ref} {...props} component={RouterLink} />
));

ProjectsLink.displayName = 'ProjectsLink';

const DataIntegration = () => {
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));

  const sections = [
    {
      title: 'Seamless Data Integration',
      description:
        'With DDB, effortlessly connect to other design software and applications. Our powerful connectors and flexible API integrate seamlessly with tools like Grasshopper, PowerBI, DesignCheck, and Zero, ensuring smooth data flow across platforms.',
      imageSrc: '/illustrations/landing-page/integration.svg',
      imageAlt: 'integration',
      buttonText: 'Go To Projects',
      titleSection: true,
    },
    {
      title: 'Connected Platforms',
      description:
        'DDB supports Power BI, Grasshopper, Python and more, meaning you can seamlessly integrate live data into your workflows and eliminate the need to copy and paste project information to your various processes. The DDB ecosystem keeps growing with new tools being developed.',
      secondaryDescription: 'Learn more on connectors ',
      imageSrc: '/illustrations/landing-page/platforms.svg',
      imageAlt: 'platforms',
      link: 'https://docs.ddb.arup.com/connectors/overview.html',
      linkText: 'here.',
      leftSection: true,
    },
    {
      title: 'Application Programming Interface (API)',
      description:
        "DDB's API allows developers to integrate our powerful features into your own applications. With robust documentation and continuous updates, our API ensures that your integrations are always up-to-date and capable of supporting your evolving needs.",
      imageSrc: '/illustrations/landing-page/hub.svg',
      imageAlt: 'hub',
    },
    {
      title: 'Seamless Data Transfer',
      description:
        "DDB's robust connectors allow for smooth data flow from diverse sources into the system. It can be integrated into automated workflows, calculations and dashboards.",
      imageSrc: '/illustrations/landing-page/workflow.svg',
      imageAlt: 'workflow',
      leftSection: true,
    },
  ];

  return (
    <>
      <main id="main-content" tabIndex="-1" style={{ minHeight: 650 }}>
        <Container maxWidth="lg">
          {sections.map((section, index) => (
            <div key={section.title}>
              <ImageInformationSection
                title={section.title}
                description={section.description}
                secondaryDescription={section.secondaryDescription}
                imageSrc={section.imageSrc}
                imageAlt={section.imageAlt}
                buttonText={section.buttonText}
                titleSection={section.titleSection}
                isMdUp={isMdUp}
                link={section.link}
                linkText={section.linkText}
                leftSection={section.leftSection}
              />
              {index < sections.length - 1 && <StyledDivider />}
            </div>
          ))}
          <ContactOnboarding isMdUp={isMdUp} />
        </Container>
      </main>
      <AppFooter />
    </>
  );
};

export default DataIntegration;
