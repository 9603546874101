import { Link, Typography } from '@mui/material';
import { links } from './links';

function Community() {
  return (
    <>
      <Typography component="h2" gutterBottom>
        Community
      </Typography>
      <ul data-cy="community-links">
        {links.map(({ name, href, ...rest }) => (
          <li key={name}>
            <Link
              underline="none"
              color="textSecondary"
              variant="body2"
              href={href}
              data-cy={rest.text || name}
              {...rest}
            >
              {name}
            </Link>
          </li>
        ))}
      </ul>
    </>
  );
}

export default Community;
