/* eslint-disable no-nested-ternary */
import { Suspense } from 'react';
import PT from 'prop-types';
import { isEmpty } from 'lodash';
import { List as ListIcon } from '@mui/icons-material';
import {
  useLocation,
  useNavigate,
  createSearchParams,
  useParams,
} from 'react-router-dom';
import EmptyState from 'components/empty-state';
import qs from 'qs';
import InfiniteList from 'components/infinite-list';
import { DataSetCard } from 'components/cards';

export default function DataSetsList({ rows, loading, keyAsset }) {
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchString = { ...qs.parse(search, { ignoreQueryPrefix: true }) };
  const { dataSetId, dataTemplateId } = useParams();

  return !loading && isEmpty(rows) ? (
    <Suspense fallback={null}>
      <EmptyState
        Icon={ListIcon}
        title="No data sets"
        subtitle={
          dataTemplateId
            ? 'No existing data set instances for the selected template. Add a data set using the wizard above.'
            : 'Please select a template to display data sets on this project.'
        }
      />
    </Suspense>
  ) : (
    <InfiniteList
      itemSize={70}
      list={rows}
      loading={loading}
      loaderNumber={50}
      loader={<DataSetCard loading />}
      hasNextPage={false}
      loadMoreRows={() => {}}
    >
      {rows.map(row => {
        return (
          <DataSetCard
            key={row.id}
            loading={loading}
            keyAsset={keyAsset}
            row={row}
            selected={row.id === dataSetId}
            handleClick={() => {
              const searchParams = `?${createSearchParams({
                ...searchString,
              })}`;
              navigate({
                pathname: `set/${row.id}`,
                search: searchParams,
              });
            }}
          />
        );
      })}
    </InfiniteList>
  );
}

DataSetsList.propTypes = {
  rows: PT.arrayOf(PT.shape({})).isRequired,
  loading: PT.bool,
  keyAsset: PT.shape({ name: PT.string }),
};

DataSetsList.defaultProps = {
  keyAsset: { name: '' },
  loading: false,
};
