import { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMsal, useAccount } from '@azure/msal-react';
import {
  InteractionStatus,
  InteractionRequiredAuthError,
} from '@azure/msal-browser';
import { Providers, ProviderState } from '@microsoft/mgt-element';
import { loginRequest } from 'auth/auth-provider';
import { getUserInformation } from 'api';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { instance, inProgress, accounts } = useMsal();
  const activeAccount = useAccount(accounts[0] || {});

  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);

  if (activeAccount) instance.setActiveAccount(activeAccount);

  const login = () => {
    instance
      .ssoSilent({
        ...loginRequest,
        loginHint: activeAccount?.username,
      })
      .catch(err => {
        if (err instanceof InteractionRequiredAuthError) {
          instance.loginRedirect(loginRequest);
          Providers.globalProvider.setState(ProviderState.SignedIn);
        }
      });
    Providers.globalProvider.setState(ProviderState.SignedIn);
  };

  const logout = () => {
    instance.logoutRedirect({ account: activeAccount || accounts[0] });
    Providers.globalProvider.setState(ProviderState.SignedOut);
  };

  useEffect(() => {
    const getUserDetails = async () => {
      const userData = await getUserInformation();

      const userObj = {
        employeeId: userData.id,
        firstName: userData.givenName,
        lastName: userData.surname,
        userName: userData.displayName,
        office: userData.officeLocation,
        jobTitle: userData.jobTitle,
        email: userData.mail || userData.userPrincipalName,
        roles: userData.roles,
      };
      setUser(userObj);
      setLoading(false);
    };

    if (instance.getActiveAccount() && inProgress === InteractionStatus.None) {
      Providers.globalProvider.setState(ProviderState.SignedIn);
      getUserDetails();
    }
  }, [inProgress, instance]);

  useEffect(() => {
    if (inProgress !== InteractionStatus.None)
      Providers.globalProvider.setState(ProviderState.Loading);
  });

  return (
    <UserContext.Provider
      value={{
        user,
        login,
        logout,
        loading,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
