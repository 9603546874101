import { format, isValid, isToday } from 'date-fns';
import { enGB } from 'date-fns/locale';

export default rawDate => {
  const date = new Date(rawDate);
  if (!isValid(date)) {
    return '-';
  }

  if (isToday(date)) {
    return format(date, `'Today', HH:mm`, { locale: enGB });
  }

  return format(date, 'dd MMM yyyy, HH:mm', { locale: enGB });
};
