import { Suspense } from 'react';
import { isEmpty } from 'lodash';
import { withAppInsights } from 'app/app-insights';
import { ScrollTop } from 'components/buttons';
import InfinityScroll from 'components/infinite-scroll';
import { Stack, Divider, Grid } from '@mui/material';
import NavigationMenu from 'components/drawer/responsive-container/navigation-menu';
import ProjectCardGrid from './card-grid';
import ActionBar from './action-bar';
import handleProjects from './hooks';
import NoProjects from './card-grid/no-projects';
import SearchedResultMessage from './searched-result-message';

function ProjectsPage() {
  const {
    projects,
    loading,
    addProject,
    searchValue,
    handleSearch,
    error,
    hasMore,
    paging,
    allLoaded,
    setHasMore,
    loadFavourites,
    tabList,
  } = handleProjects();

  return (
    <main data-testid="projects-page">
      <Stack
        aria-label="projects-layout"
        display="flex"
        flexDirection="row"
        flexWrap="nowrap"
        justifyContent="stretch"
        alignContent="stretch"
        divider={<Divider orientation="vertical" flexItem />}
      >
        <Suspense fallback={null}>
          <Grid flexDirection="column" sx={{ width: '20vw' }}>
            <NavigationMenu
              navigationListTitle="Projects"
              drawerOpen
              toggleDrawerOpen={() => {}}
              navigationList={tabList}
            />
          </Grid>
        </Suspense>
        <Grid sx={{ width: '80vw' }}>
          <ActionBar
            addProject={addProject}
            searchValue={searchValue}
            handleSearch={handleSearch}
            loading={loading}
            loadFavourites={loadFavourites}
          />
          <Grid container display="grid" justifyContent="center">
            {searchValue && !isEmpty(projects) && !error && (
              <SearchedResultMessage searchValue={searchValue} />
            )}
          </Grid>
          <Suspense fallback={null}>
            <InfinityScroll
              setHasMore={setHasMore}
              hasMore={paging?.next && !!hasMore}
              allLoaded={!isEmpty(projects) && allLoaded}
              loadFavourites={loadFavourites}
              isNotFound={isEmpty(projects)}
              notFoundComponent={<NoProjects />}
            >
              <ProjectCardGrid
                projects={projects}
                error={error}
                searchValue={searchValue}
              />
            </InfinityScroll>
          </Suspense>

          <ScrollTop />
        </Grid>
      </Stack>
    </main>
  );
}

export default withAppInsights(ProjectsPage, 'ProjectsPage');
