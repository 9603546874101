import config from 'config';

const setFavicon = () => {
  const { faviconPath } = config;

  const favicon = document.getElementById('favicon');
  if (favicon) {
    favicon.href = faviconPath;
  }
};

export default setFavicon;
