/* eslint-disable react/forbid-prop-types */
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridToolbarExportContainer,
  gridFilteredSortedRowIdsSelector,
  gridVisibleColumnFieldsSelector,
  useGridApiContext,
  GridExcelExportMenuItem,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-premium';
import { MenuItem, FormControlLabel, Switch, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import PT from 'prop-types';
import qs from 'qs';

const getJson = apiRef => {
  // Select rows and columns
  const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
  const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);

  // Format the data. Here we only keep the value
  const data = filteredSortedRowIds.map(id => {
    const row = { id };
    visibleColumnsField.forEach(field => {
      row[field] = apiRef.current.getCellParams(id, field).value;
    });
    return row;
  });

  // Stringify with some indentation
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#parameters
  return JSON.stringify(data, null, 2);
};

const exportBlob = (blob, filename) => {
  // Save the blob in a json file
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();

  setTimeout(() => {
    URL.revokeObjectURL(url);
  });
};

const JsonExportMenuItem = props => {
  const apiRef = useGridApiContext();

  const { hideMenu, fileName } = props;

  return (
    <MenuItem
      onClick={() => {
        const jsonString = getJson(apiRef);
        const blob = new Blob([jsonString], {
          type: 'text/json',
        });
        exportBlob(blob, `${fileName}.json`);
        // Hide the export menu after the export
        hideMenu?.();
      }}
    >
      Export JSON
    </MenuItem>
  );
};

JsonExportMenuItem.propTypes = {
  // eslint-disable-next-line react/require-default-props
  hideMenu: PT.func,
  fileName: PT.string.isRequired,
};

const csvOptions = {
  delimiter: ';',
  utf8WithBom: true,
  allColumns: true,
};

const printOptions = { hideFooter: true, hideToolbar: true, allColumns: true };

const CustomExportButton = ({ fileName, ...other }) => (
  <GridToolbarExportContainer {...other}>
    <GridCsvExportMenuItem options={{ fileName, ...csvOptions }} />
    <JsonExportMenuItem fileName={fileName} />
    <GridPrintExportMenuItem options={{ fileName, ...printOptions }} />
    <GridExcelExportMenuItem />
  </GridToolbarExportContainer>
);

CustomExportButton.propTypes = {
  fileName: PT.string.isRequired,
};

function CustomToolbar({ exportName, searchToolbar, toggleToolbar, ...rest }) {
  const fileName = `${new Date().toISOString()}-${exportName}`;
  const [searchParams, setSearchParams] = useSearchParams();
  const { ...parsedSearch } = qs.parse(searchParams.toString());

  const handleSwitch = e => {
    searchParams.set(
      'show_deleted_sources',
      e.target.checked ? 'true' : 'false'
    );
    setSearchParams(searchParams);
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <CustomExportButton fileName={fileName} {...rest} />
      {toggleToolbar && (
        <FormControlLabel
          control={<Switch size="small" aria-label="sources-toggle-switch" />}
          label={
            <Typography
              variant="body2"
              sx={{
                textTransform: 'uppercase',
              }}
            >
              Show removed
            </Typography>
          }
          checked={parsedSearch?.show_deleted_sources === 'true'}
          onChange={handleSwitch}
        />
      )}
      {searchToolbar && (
        <GridToolbarQuickFilter
          sx={{
            position: 'absolute',
            right: '0',
            marginRight: theme => theme.spacing(1.5),
          }}
        />
      )}
    </GridToolbarContainer>
  );
}

CustomToolbar.propTypes = {
  exportName: PT.string.isRequired,
  searchToolbar: PT.bool,
  toggleToolbar: PT.bool,
};

CustomToolbar.defaultProps = {
  searchToolbar: false,
  toggleToolbar: false,
};

export default CustomToolbar;
