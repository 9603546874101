import { Link as RouterLink } from 'react-router-dom';
import { Typography, Link } from '@mui/material';
import { version } from '../../../package.json';

function Release() {
  const currentVersion = (
    <Link
      underline="always"
      color="textPrimary"
      component={RouterLink}
      to={`docs/changelog/web#v${version.replace(/\./g, '')}`}
      variant="body2"
    >
      {` v${version}`}
    </Link>
  );

  return (
    <Typography color="textSecondary" variant="body2">
      {'Current version'}
      {currentVersion}
      {` © Arup ${new Date().getFullYear()} All rights reserved `}
      {' / Digital Design Brief. '}
    </Typography>
  );
}

export default Release;
