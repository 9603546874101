/* eslint-disable react/no-array-index-key */
import PT from 'prop-types';
import { Grid, useMediaQuery } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import StatusCircle from 'components/status-circle';
import { parameterType } from 'types';
import WarningDialog from 'components/dialogs/warning';
import { GoBackButton } from 'components/buttons';
import ActionHeader from 'components/cards/action-header';
import { formatTitle } from 'utils';
import useHeaders from './hooks';
import HeaderBreadcrumbs from '../../common/header-breadcrumb-tree/headerBreadcrumbs';
import HeaderButtons from '../../common/header-buttons/headerButtons';

function DataCardHeader({ loading, parameter, setError }) {
  const screenXs = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const screenMd = useMediaQuery(theme => theme.breakpoints.down('lg'));
  const { parameterId } = useParams();
  const navigate = useNavigate();
  const {
    assetList,
    assetsLoading,
    deleteLoading,
    displayWarning,
    warningClose,
    warningAccept,
    headerActions,
  } = useHeaders(parameter, parameterId, setError);

  return (
    <ActionHeader
      dataCy="parameterHeader"
      renderAvatar={
        <Grid container>
          {screenXs && (
            <Grid item xs={6} sm={6}>
              <GoBackButton
                sx={{ marginTop: theme => theme.spacing(2.5) }}
                onClick={() => {
                  navigate(-1);
                }}
              />
            </Grid>
          )}
          <Grid item xs={6} sm={12} md={12}>
            <StatusCircle
              status={parameter?.selectedEntry?.status || 'unanswered'}
              size={screenMd ? 40 : 60}
            />
          </Grid>
        </Grid>
      }
      renderTitle={formatTitle(parameter?.parameterType?.name)}
      titleTypographyProps={{
        sx: {
          paddingLeft: theme => theme.spacing(1),
          paddingBottom: theme => theme.spacing(1),
          marginRight: theme => theme.spacing(1),
          fontSize: '20px',
        },
      }}
      renderSubheader={
        <Grid container>
          <Grid item xs={6} sm={12} md={12}>
            <HeaderBreadcrumbs
              assetsLoading={assetsLoading}
              assetList={assetList}
            />
          </Grid>
        </Grid>
      }
      subheaderTypographyProps={{
        sx: { paddingLeft: theme => theme.spacing(1) },
      }}
      isLoading={loading}
      renderAction={
        <Grid
          container
          justifyContent="flex-end"
          sx={{ marginTop: theme => theme.spacing(2.5) }}
        >
          <Grid container>
            <HeaderButtons
              headerActions={headerActions}
              deleteLoading={deleteLoading}
            />
          </Grid>
          <WarningDialog
            open={displayWarning}
            handleAction={warningAccept}
            handleClose={warningClose}
            title="Are you sure you want to remove this parameter"
            body="All the information you entered for the parameter will be lost, are you sure you want to remove it?"
            secondaryButtonText="Cancel"
            primaryButtonText="Delete"
          />
        </Grid>
      }
    />
  );
}

DataCardHeader.propTypes = {
  loading: PT.bool.isRequired,
  setError: PT.func.isRequired,
  parameter: parameterType.isRequired,
};

export default DataCardHeader;
