/* eslint-disable max-depth */
import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Done } from '@mui/icons-material/';
import StyledIconButton from 'components/buttons/icons/styled';
import { parametersApi, handleApiError } from 'api';
import {
  DeleteButton,
  CopyButton,
  EditButton,
  RejectButton,
  RestoreButton,
} from 'components/buttons';
import { ParametersContext, AssetContext } from 'context';
import { useSearchParams } from 'react-router-dom';
import { findIndex } from 'lodash';

export default (
  setError,
  asset,
  setAsset,
  openEditingForm,
  setOpenEditingForm
) => {
  const { getParameters, setParameters } = useContext(ParametersContext);
  const { setAllAssets, setIsLoading } = useContext(AssetContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [assetsLoading, setAssetsLoading] = useState(true);
  const [assetList, setAssetList] = useState([]);
  const [deletedParent, setDeletedParent] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [displayWarning, setDisplayWarning] = useState({});

  useEffect(() => {
    let didCancel = false;
    const source = axios.CancelToken.source();
    let parents = [];
    setAssetList(parents);
    setAssetsLoading(true);
    setDeletedParent(false);

    const getParentAssets = async () => {
      let parentId = asset.parent;
      while (parentId && !didCancel) {
        try {
          // eslint-disable-next-line no-await-in-loop
          const response = await parametersApi(
            'getAsset',
            {
              asset_id: parentId,
              show_deleted: true,
            },
            source.token
          );
          if (!didCancel && response) {
            const { asset: parentAsset } = response;
            if (parentAsset.deletedAt) {
              setDeletedParent(true);
            }
            parents = [parentAsset, ...parents];
            parentId = parentAsset.parent;
            setAssetList(parents);
            setAssetsLoading(false);
          }
        } catch (err) {
          handleApiError(err, []);
        }
      }
    };

    if (asset?.parent && !asset.new) getParentAssets();
    else setAssetsLoading(false);

    return () => {
      didCancel = true;
      source.cancel();
    };
  }, [asset.parent, asset.new, searchParams]);

  const deleteAsset = async () => {
    setError(undefined);
    setParameters([]);
    setDeleteLoading(true);
    try {
      await parametersApi('deleteAsset', {
        asset_id: asset.id,
      });

      setAsset({ ...asset, deletedAt: 'deleted' });
      setAllAssets(curr => {
        const indexOfUpdate = findIndex(
          curr,
          existing => existing.id === asset.id
        );
        curr[indexOfUpdate] = { ...curr[indexOfUpdate], deletedAt: 'deleted' }; // eslint-disable-line no-param-reassign
      });
      searchParams.set('show_deleted_assets', true);
      searchParams.set('show_deleted_parameters', true);
      setSearchParams(searchParams);
    } catch (err) {
      setError(err?.response?.data);
    }
    getParameters();
    setDeleteLoading(false);
    setIsLoading(false);
  };

  const restoreAsset = async () => {
    setParameters([]);
    setError(undefined);
    setDeleteLoading(true);
    try {
      const response = await parametersApi('patchAsset', {
        asset_id: asset.id,
        body: {
          name: null,
          restore: true,
        },
      });
      if (response) {
        setAsset(response.asset);
        setSearchParams(searchParams);
        setAllAssets(curr => {
          const indexOfUpdate = findIndex(
            curr,
            existing => existing.id === asset.id
          );
          curr[indexOfUpdate] = { ...curr[indexOfUpdate], deletedAt: null }; // eslint-disable-line no-param-reassign
        });
      }
    } catch (err) {
      setError(err?.response?.data);
    }
    getParameters();
    setDeleteLoading(false);
    setIsLoading(false);
  };

  const warningClose = () => {
    setDisplayWarning(false);
  };

  const headerActions = asset?.deletedAt
    ? [
        <RestoreButton
          key="restore"
          onClick={() => {
            setDisplayWarning({
              open: true,
              handleAccept: () => {
                restoreAsset();
                warningClose();
              },
              handleClose: warningClose,
              title: 'Are you sure you want to restore this Asset',
              body: 'All parameters of this asset will be restored also, do you want to restore this asset and its parameters?',
              secondaryButtonText: 'Cancel',
              primaryButtonText: 'Restore',
            });
          }}
          disabled={assetsLoading || deletedParent}
          tooltip="Cannot restore Asset with deleted parent"
        />,
      ]
    : [
        <EditButton
          aria-label="edit"
          data-cy="editAssetButton"
          key="edit"
          onClick={() => {
            if (openEditingForm) {
              setOpenEditingForm(false);
            } else {
              setOpenEditingForm(true);
            }
          }}
          disabled={!asset?.id ? true : asset?.assetSubType || asset?.deletedAt}
          isEditing={openEditingForm}
          type="Asset"
        />,
        <StyledIconButton
          key="accept-button"
          title={"You don't have permission to do this"}
          variant="outlined"
          onClick={() => {}}
          disabled
          aria-label="update"
          icon={<Done />}
        />,
        <RejectButton
          aria-label="reject"
          disabled
          key="reject-button"
          status="unanswered"
          onClick={() => {}}
        />,
        <CopyButton
          aria-label="copy"
          key="copy"
          toBeCopied={asset?.id}
          toolTipText="Asset ID"
        />,
        <DeleteButton
          aria-label="delete"
          disabled={!asset?.id ? true : !!asset?.deletedAt}
          key="delete"
          type="asset"
          onClick={() => {
            setDisplayWarning({
              open: true,
              handleAccept: () => {
                deleteAsset();
                warningClose();
              },
              handleClose: warningClose,
              title: 'Are you sure you want to remove this Asset?',
              body: 'You can restore it later',
              secondaryButtonText: 'Cancel',
              primaryButtonText: 'Remove',
            });
          }}
        />,
      ];

  return {
    assetList,
    assetsLoading,
    deleteLoading,
    displayWarning,
    headerActions,
    openEditingForm,
    setOpenEditingForm,
    deletedParent,
    deleteAsset,
    restoreAsset,
    setDisplayWarning,
    warningClose,
  };
};
