import { useMediaQuery, Grid } from '@mui/material';
import { useContext } from 'react';
import { ProjectContext } from 'context';
import Alert from 'components/alerts';
import { StyledTypography } from '../reporting-page-styles';

const ReportingTitle = () => {
  const screenSm = useMediaQuery(theme => theme.breakpoints.down('md'));
  const { project } = useContext(ProjectContext);

  return (
    <Grid container direction="column" justifyContent="center">
      <Grid item>
        <StyledTypography
          aria-label="drawer-title-reporting"
          variant={screenSm ? 'h6' : 'h5'}
          pt={1.2}
        >
          Automated Reporting - {project?.shortTitle}
        </StyledTypography>
      </Grid>
      <Grid item sx={{ gap: '10px' }} display="grid">
        <Alert
          title="Automated Reporting is in Beta Testing. Responses from the Hotdocs Server may be slow. Please report bugs or provide feedback using the buttons in the navigation bar"
          severity="warning"
        />
        <Alert
          title="Automated reporting currently uses a Hotdocs server in Australia. Please check our data security level is compatible with your project requirements."
          severity="warning"
        />
      </Grid>
    </Grid>
  );
};

export default ReportingTitle;
