import { Link, Typography } from '@mui/material';
import { legal } from './links';

function Legal() {
  return (
    <>
      <Typography component="h2" gutterBottom>
        Legal
      </Typography>
      <ul data-cy="legal-links">
        {legal.map(({ text, name, ...rest }) => (
          <li key={text}>
            <Link
              underline="none"
              color="textSecondary"
              variant="body2"
              data-cy={name}
              {...rest}
            >
              {text}
            </Link>
          </li>
        ))}
      </ul>
    </>
  );
}

export default Legal;
