/* eslint-disable no-nested-ternary */
import PT from 'prop-types';
import {
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useMediaQuery,
  Skeleton,
  Grid,
} from '@mui/material';
import { Warning } from '@mui/icons-material';

const BasicTable = ({ headers, data, skippingHeaders, dense, loading }) => {
  const screenMd = useMediaQuery(theme => theme.breakpoints.down('lg'));
  const screenXs = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 300 }}
        aria-label="simple table"
        size="small"
        dense={dense.toString()}
      >
        {headers.every(header => header.label) && (
          <TableHead>
            <TableRow>
              {headers.map(header => {
                return (
                  // skipping headers on smaller screen will be omitted
                  !(
                    (screenXs || screenMd) &&
                    skippingHeaders.some(skipped => skipped === header.key)
                  ) && (
                    <TableCell
                      align={header.align || 'left'}
                      key={header.key}
                      aria-label={header.label}
                      style={{
                        width: header.key === 'parameter' ? 300 : header.width,
                        paddingLeft: header.paddingLeft,
                        backgroundColor: header.backgroundColor,
                      }}
                    >
                      {loading ? (
                        <Skeleton width="80%" height="100%" />
                      ) : (
                        header.label
                      )}
                    </TableCell>
                  )
                );
              })}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {data.map(row => {
            return (
              <TableRow
                aria-label="row"
                key={row.key}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {headers.map(column => {
                  return (
                    // skipping headers on smaller screen will be omitted
                    !(
                      (screenXs || screenMd) &&
                      skippingHeaders.some(skipped => skipped === column.key)
                    ) && (
                      <TableCell
                        align={column.align || 'left'}
                        key={column.key}
                        aria-label={column.label}
                        style={{
                          width: column.width,
                          paddingLeft: column.paddingLeft,
                          backgroundColor: column.backgroundColor,
                        }}
                      >
                        {loading ? (
                          <Skeleton width="100%" height="100%" />
                        ) : row.key.includes('forImport') &&
                          column.key === 'parameter' ? (
                          <Grid container>
                            <Tooltip
                              title={`This parameter has not been imported to this ${row?.assetTypeName} yet. Import will be completed once the Finish button is pressed in the last step`}
                            >
                              <Grid item>
                                <Warning
                                  fontSize="small"
                                  sx={{
                                    color: theme => theme.palette.grey[500],
                                    marginRight: theme => theme.spacing(0.75),
                                  }}
                                />
                              </Grid>
                            </Tooltip>
                            <Grid item>{row[column.key]}</Grid>
                          </Grid>
                        ) : (
                          row[column.key]
                        )}
                      </TableCell>
                    )
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
BasicTable.propTypes = {
  headers: PT.arrayOf(
    PT.shape({
      key: PT.string.isRequired,
      label: PT.oneOfType([PT.shape({}), PT.node]),
    })
  ).isRequired,
  data: PT.arrayOf(
    PT.shape({
      key: PT.string.isRequired,
    })
  ).isRequired,
  skippingHeaders: PT.arrayOf(PT.string),
  dense: PT.bool,
  loading: PT.bool,
};

BasicTable.defaultProps = {
  skippingHeaders: [],
  dense: false,
  loading: false,
};

export default BasicTable;
