const flatAssetList = (assets = {}) =>
  Object.values(assets)
    .reduce((acc, curr) => {
      const currAssets = curr.reduce((currAcc, { id, name, children }) => {
        if (children) {
          const childAssets = flatAssetList(children);
          currAcc.push(...childAssets);
        }
        currAcc.push({ id, name });
        return currAcc;
      }, []);

      acc.push(...currAssets);
      return acc;
    }, [])
    .sort((a, b) => a.id - b.id);

export default flatAssetList;
