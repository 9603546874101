import { useIsAuthenticated } from '@azure/msal-react';
import { Person } from 'components/microsoft';
import { StyledIconButton } from 'components/buttons';
import handleUser from './hooks';
import UserMenu from './menu';

function User() {
  const isAuthenticated = useIsAuthenticated();

  const { anchorEl, userLogout, userLogin, handleMenu, handleClose, navigate } =
    handleUser();

  return (
    isAuthenticated && (
      <>
        <StyledIconButton
          title="Manage Account"
          data-testid="userAvatar"
          color="inherit"
          onClick={handleMenu}
          aria-label="Manage Account"
          size="small"
          icon={<Person personQuery="me" />}
          data-cy="manage-account-icon"
        />
        <UserMenu
          anchorEl={anchorEl}
          userLogout={userLogout}
          navigate={navigate}
          handleClose={handleClose}
          userLogin={userLogin}
        />
      </>
    )
  );
}

export default User;
