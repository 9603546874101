import {
  DataSetsContext,
  DataSetsWizardContext,
  DataTemplatesContext,
} from 'context';
import { useContext, useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import useSummary from 'routes/templates/template-details/asset-types/hooks/use-summary';
import CreateDataSet from '../create-data-set';
import AssetsTree from '../assets-tree';
import Instructions from '../instructions';
import DataSetParametersList from '../data-set-parameters-list';

export default toggleOpen => {
  const { assetNodes, templateAssets, selectedAssets } = useContext(
    DataSetsWizardContext
  );
  const { dataTemplate } = useContext(DataTemplatesContext);
  const { setDataSets } = useContext(DataSetsContext);
  const { formattedAssets, templateParameters } = useSummary();

  const [suggestedName, setSuggestedName] = useState('');

  useEffect(() => {
    if (!isEmpty(selectedAssets)) {
      const keyTemplateAsset = templateAssets.find(
        templateAsset => templateAsset.isKey
      );
      let keySelectedAsset = {};
      if (!isEmpty(keyTemplateAsset.assetType)) {
        keySelectedAsset = selectedAssets.find(
          selectedAsset =>
            selectedAsset.assetType.id === keyTemplateAsset.assetType.id
        );
      } else {
        keySelectedAsset.name = 'Project level';
      }
      setSuggestedName(`${dataTemplate.name} - ${keySelectedAsset?.name}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAssets, templateAssets, dataTemplate]);

  const handleClose = () => {
    toggleOpen();
  };

  const submitDataSet = values => {
    const { dataSet } = values;
    setDataSets(curr => [...dataSet, ...curr]);
    toggleOpen();
  };

  const steps = [
    {
      label: 'Instructions',
      optional: false,
      header: ' Read these instructions before creating a data set',
      content: () => <Instructions />,
    },
    {
      label: 'Select required assets',
      optional: false,
      header: '',
      content: props => (
        <AssetsTree
          loading={false}
          assetNodes={assetNodes}
          formattedAssets={formattedAssets}
          {...props}
        />
      ),
    },
    {
      label: 'Confirm parameters to add',
      optional: false,
      header: 'Review parameters',
      content: props => (
        <DataSetParametersList
          formattedAssets={formattedAssets}
          templateParameters={templateParameters}
          {...props}
        />
      ),
    },
    {
      label: 'Create data set',
      optional: false,
      header: 'Create data set',
      content: props => <CreateDataSet {...props} />,
    },
  ];

  return {
    steps,
    handleClose,
    submitDataSet,
    suggestedName,
  };
};
