import PT from 'prop-types';
import { Grid, Paper, Typography } from '@mui/material';

function Selection({ selectedProject }) {
  return (
    <>
      <br />
      <Paper
        variant="outlined"
        sx={{ padding: theme => theme.spacing(1) }}
        data-cy="selected-project"
      >
        <Grid container direction="column">
          <Typography variant="subtitle2">
            {selectedProject.shortTitle}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {`${'Project Director Name'}: ${
              selectedProject.projectDirectorName
            }`}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {`${'Project Manager Name'}: ${selectedProject.projectManagerName}`}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {`${'Centre Code'}: ${selectedProject.centreCode}`}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {`${'Scope of Works'}: ${selectedProject.scopeOfWorks || ''}`}
          </Typography>
        </Grid>
      </Paper>
    </>
  );
}

Selection.propTypes = {
  selectedProject: PT.shape({
    shortTitle: PT.string.isRequired,
    projectDirectorName: PT.string.isRequired,
    projectManagerName: PT.string.isRequired,
    centreCode: PT.string.isRequired,
    scopeOfWorks: PT.oneOfType([PT.string, PT.number]),
  }).isRequired,
};

export default Selection;
