import { isValid, formatDistance, differenceInDays } from 'date-fns';
import { enGB } from 'date-fns/locale';
import formatDate from './format-date';

export default (rawDate, limit = 6) => {
  const from = new Date();
  const date = new Date(rawDate);
  if (isValid(date)) {
    if (differenceInDays(from, date) > limit)
      return formatDate(rawDate, undefined);
    return formatDistance(date, from, { locale: enGB });
  }
  return '-';
};
