import PT from 'prop-types';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import StyledIconButton from '../styled';

const ExpandOrCollapseButton = ({
  open,
  onClick,
  icon,
  title,
  loading,
  ...rest
}) => {
  return (
    <StyledIconButton
      {...rest}
      type="expandOrCollapseButton"
      title={title || (open ? 'Collapse' : 'Expand')}
      variant="outlined"
      onClick={onClick}
      icon={
        open ? (
          <>
            {loading ? (
              <CircularProgress size={25} />
            ) : (
              <KeyboardArrowDown aria-label="Collapse button icon" />
            )}
            {icon}
          </>
        ) : (
          <>
            <KeyboardArrowRight aria-label="Expand button icon" />

            {icon}
          </>
        )
      }
    />
  );
};

ExpandOrCollapseButton.propTypes = {
  open: PT.bool,
  onClick: PT.func.isRequired,
  icon: PT.node,
  title: PT.string,
  loading: PT.bool,
};
ExpandOrCollapseButton.defaultProps = {
  open: false,
  icon: null,
  title: null,
  loading: false,
};
export default ExpandOrCollapseButton;
