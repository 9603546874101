import PT from 'prop-types';
import { IconButton, Tooltip as ToolTip } from '@mui/material';
import {
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon,
} from '@mui/icons-material';

export default function ExpandButton({
  open,
  toggleDrawerOpen,
  title,
  disabled,
  ...rest
}) {
  const Icon = () => {
    return open ? (
      <ChevronLeftIcon data-testid="expand-button-left" />
    ) : (
      <ChevronRightIcon data-testid="expand-button-right" />
    );
  };

  return (
    <ToolTip title={open ? 'Show Less' : 'Show More'}>
      {open ? (
        <IconButton size="small" onClick={toggleDrawerOpen} {...rest}>
          <Icon />
        </IconButton>
      ) : (
        <IconButton
          onClick={toggleDrawerOpen}
          disabled={disabled}
          size="small"
          {...rest}
        >
          <Icon />
        </IconButton>
      )}
    </ToolTip>
  );
}

ExpandButton.propTypes = {
  toggleDrawerOpen: PT.func.isRequired,
  open: PT.bool.isRequired,
  title: PT.string,
  disabled: PT.bool.isRequired,
};

ExpandButton.defaultProps = {
  title: '',
};
