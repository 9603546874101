import { useCookies } from 'react-cookie';
import { useNavigate, createSearchParams } from 'react-router-dom';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  Grid,
  Avatar,
  Paper,
  ListSubheader,
} from '@mui/material';
import ConfidentialChip from '../../chips/confidential';
import FavouritesButton from '../button/index';

function FavoritesList() {
  const navigate = useNavigate();
  const [cookies] = useCookies(['ddbFavoriteProjects']);
  const params = {};
  const favorites = Object.entries(cookies?.ddbFavoriteProjects || {}).map(
    ([k, v]) => ({ id: k, ...v })
  );

  return (
    <Paper variant="outlined" elevation={0} data-testid="favourites">
      <List
        role="list"
        dense
        subheader={<ListSubheader>Favourites</ListSubheader>}
        sx={{
          maxHeight: 275,
          position: 'relative',
          overflow: 'auto',
          '& ul': {
            margin: 0,
            padding: 0,
            listStyle: 'none',
          },
        }}
        aria-label="Favourites"
        data-cy="favList"
      >
        {favorites.length ? (
          favorites.map(fav => (
            <ListItem
              sx={{ width: '100%', padding: 0 }}
              alignItems="flex-start"
              key={fav.id}
              data-cy="favGoToProject"
              secondaryAction={
                <FavouritesButton
                  loading={false}
                  project={{
                    shortTitle: fav?.jobName,
                    number: fav?.number?.toString(),
                    projectId: fav?.id,
                  }}
                />
              }
            >
              <ListItemButton
                onClick={() => {
                  navigate({
                    pathname: `/projects/${fav.id}`,
                    search: `?${createSearchParams({ ...params })}`,
                  });
                }}
              >
                <ListItemAvatar aria-label="Job name">
                  <Avatar>{fav.jobName}</Avatar>
                </ListItemAvatar>
                <ListItemText primary={fav.jobName} secondary={fav.number} />
                {fav.confidential && (
                  <Grid sx={{ paddingRight: theme => theme.spacing(1) }}>
                    <ConfidentialChip />
                  </Grid>
                )}
              </ListItemButton>
            </ListItem>
          ))
        ) : (
          <ListItem key="noFavourites">
            <ListItemText
              primary="You currently have no favourites"
              secondary="Add favourites by selecting the star icon on the projects page"
            />
          </ListItem>
        )}
      </List>
    </Paper>
  );
}

export default FavoritesList;
