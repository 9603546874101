import { useEffect, useState } from 'react';
import axios from 'axios';
import { commentsApi, handleApiError, parametersApi } from 'api';
import { formatDate } from 'utils';

export default (parameter, setOpenRejectedAlert) => {
  const [isLoading, setIsLoading] = useState(true);
  const [rejectedAlert, setRejectedAlert] = useState({
    name: '',
    date: '',
    status: '',
    comment: '',
  });

  const getSelectedEntryComment = async () => {
    const response = await parametersApi('getEntryRevisions', {
      entry_id: parameter?.selectedEntry?.id,
    }).catch(err => handleApiError(err, []));

    if (response) {
      const { revisions } = response;
      return revisions[0].comment;
    }
    return null;
  };

  useEffect(() => {
    let didCancel = false;
    const source = axios.CancelToken.source();

    const getRejectedAlert = async () => {
      let comment;
      const commentId = await getSelectedEntryComment();
      const response = await commentsApi(
        'getCommentById',
        {
          comment_id: commentId,
        },
        source.token
      ).catch(handleApiError);
      if (response && !didCancel) {
        const { comment: newComment } = response;
        comment = newComment;
        const newAlert = {
          name: comment?.createdBy?.staffName,
          date: formatDate(comment?.createdAt, undefined),
          comment: comment?.content,
        };
        setIsLoading(false);
        setRejectedAlert(newAlert);
        setOpenRejectedAlert(true);
      }
    };
    getRejectedAlert();

    return () => {
      didCancel = true;
      source.cancel();
    };
  }, [parameter]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    rejectedAlert,
    isLoading,
  };
};
