import { createContext, useMemo, useState, useEffect } from 'react';
import PT from 'prop-types';
import { useCookies } from 'react-cookie';
import { isUndefined } from 'lodash';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import baseTheme from 'theme';

export const ThemeContext = createContext();

export const Theme = ({ children }) => {
  const [cookies, setCookie] = useCookies([
    'paletteType',
    'ddbCookiesPolicyAccepted',
  ]);
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const [localTheme, setLocalTheme] = useState(
    // eslint-disable-next-line no-nested-ternary
    cookies?.paletteType
      ? cookies?.paletteType
      : prefersDarkMode
      ? 'dark'
      : 'light'
  );
  const [paletteType, setPaletteType] = useState(localTheme === 'light');

  if (
    isUndefined(cookies?.paletteType) &&
    cookies?.ddbCookiesPolicyAccepted?.functional
  ) {
    setCookie('paletteType', localTheme, {
      path: '/',
      maxAge: 31536000,
    });
  }

  const theme = useMemo(
    () =>
      baseTheme(
        cookies?.paletteType === 'dark' || localTheme === 'dark'
          ? 'dark'
          : 'light'
      ),
    [cookies?.paletteType, localTheme]
  );

  const handleThemeChange = () => {
    if (cookies?.ddbCookiesPolicyAccepted?.functional) {
      setCookie(
        'paletteType',
        cookies?.paletteType === 'dark' ? 'light' : 'dark',
        {
          path: '/',
          maxAge: 31536000,
        }
      );
    }
    setLocalTheme(curr => (curr === 'dark' ? 'light' : 'dark'));
  };

  useEffect(() => {
    setPaletteType(
      cookies?.paletteType
        ? cookies.paletteType !== 'dark'
        : localTheme !== 'dark'
    );
  }, [cookies, localTheme]);

  return (
    <ThemeContext.Provider
      value={{
        handleThemeChange,
        paletteType,
        localTheme,
      }}
    >
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export const ThemeProvider = Theme;

Theme.propTypes = {
  children: PT.oneOfType([PT.arrayOf(PT.node), PT.node]).isRequired,
};

Theme.defaultProps = {};
