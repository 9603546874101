import { useContext, Suspense, useState } from 'react';
import { Divider } from '@mui/material';
import { List as ListIcon } from '@mui/icons-material';
import { DataTemplatesContext } from 'context';
import { useParams } from 'react-router-dom';
import { FoldingDrawer } from 'components/drawer';
import PageLayoutBox from 'components/layout/page-layout-styles';
import { StyledBox } from 'routes/parameters-page/parameters-page-styles';
import useTemplatesPage from 'routes/templates/hooks/use-templates-page';
import EmptyState from 'components/empty-state';
import config from 'config';
import AssetTypes from './asset-types';
import Tools from '../../../components/tools';
import Workstage from '../../../components/workstage';
import { getTemplateDetails } from './hooks';
import TemplateTags from '../../../components/tags';
import Versions from '../../../components/versions';
import DataTemplateHeader from './header';

function TemplateDetails() {
  const { templateTags, setTemplateTags, isTagLoading } = getTemplateDetails();
  const { dataTemplateId } = useParams();
  const { loading, dataTemplate } = useContext(DataTemplatesContext);
  const {
    openTemplatePanels,
    numberOfTemplatePanelsOpen,
    handleTemplateDetailsChange,
  } = useTemplatesPage();
  const [openTools, setOpenTools] = useState(false);

  return (
    <FoldingDrawer
      width={numberOfTemplatePanelsOpen > 2 ? '10%' : '30%'}
      open={openTemplatePanels.openTemplateDetails}
      tooltip="Template details"
      onClick={handleTemplateDetailsChange}
    >
      <PageLayoutBox>
        <StyledBox aria-label="template-details">
          <Suspense fallback={null}>
            <DataTemplateHeader dataTemplate={dataTemplate} loading={loading} />
            {!dataTemplateId ? (
              <>
                <Divider />
                <EmptyState
                  Icon={ListIcon}
                  subtitle="No template selected. Use a template to apply a defined group of assets and parameters to your project"
                />
              </>
            ) : (
              <>
                <Divider />
                <AssetTypes />
                {config.featureFlags.areTemplateFeaturesEnabled && (
                  <Tools openTools={openTools} setOpenTools={setOpenTools} />
                )}
                {config.featureFlags.areTemplateFeaturesEnabled && (
                  <Workstage />
                )}
                <TemplateTags
                  templateId={dataTemplateId}
                  templateTags={templateTags}
                  setTemplateTags={setTemplateTags}
                  loading={isTagLoading}
                />
                {config.featureFlags.areTemplateFeaturesEnabled && <Versions />}
              </>
            )}
          </Suspense>
        </StyledBox>
      </PageLayoutBox>
    </FoldingDrawer>
  );
}

export default TemplateDetails;
