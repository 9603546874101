import PT from 'prop-types';
import { Grid, Typography } from '@mui/material';
import Assets from 'routes/parameters-page/asset-navigation/assets';
import { DataSetsWizardContext } from 'context';
import { useContext } from 'react';
import AssetRequirements from '../asset-requirements';
import useDataSetTree from './hooks/use-data-set-tree';

const AssetsTree = ({ assetNodes, loading, formattedAssets, change }) => {
  const {
    allAssets,
    setAllAssets,
    expanded,
    setExpanded,
    selectedAssets,
    setSelectedAssets,
    templateAssets,
  } = useContext(DataSetsWizardContext);

  const {
    onAddAssetClick,
    onEditClick,
    updateSelection,
    onDoneClick,
    onClearClick,
    textFieldError,
    onRemoveAssetClick,
  } = useDataSetTree(
    assetNodes,
    formattedAssets,
    setExpanded,
    selectedAssets,
    setSelectedAssets,
    allAssets,
    setAllAssets,
    templateAssets
  );

  return (
    <Grid
      container
      display="flex"
      direction="row"
      alignContent="left"
      spacing={2}
      paddingBottom={10}
    >
      <Grid item container direction="column" xs={6} paddingLeft={0}>
        <Grid item>
          <Typography
            color={theme => theme.palette.secondary}
            variant="body1"
            mb={theme => theme.spacing(1)}
          >
            Select assets to apply the template parameters to
          </Typography>
        </Grid>
        <Grid item>
          <Typography color={theme => theme.palette.secondary} variant="body2">
            A template is a way to quickly apply a group of parameters to your
            project (a data set). Select assets based on the template
            requirements on the right. The data set will be created after step
            4.
          </Typography>
        </Grid>
        <Grid item>
          <Assets
            loading={loading}
            assetNodes={assetNodes}
            isProjectAssetHierarchy
            setExpanded={setExpanded}
            expanded={expanded}
            dataSet
            updateSelection={updateSelection}
            selectedAssets={selectedAssets}
            onAddAssetClick={onAddAssetClick}
            onEditClick={onEditClick}
            onDoneClick={onDoneClick}
            textFieldError={textFieldError}
            onClearClick={onClearClick}
            onRemoveAssetClick={onRemoveAssetClick}
          />
        </Grid>
      </Grid>
      <AssetRequirements
        formattedAssets={formattedAssets}
        selectedAssets={selectedAssets}
        assetNodes={assetNodes}
        updateSelection={updateSelection}
        change={change}
      />
    </Grid>
  );
};

AssetsTree.propTypes = {
  assetNodes: PT.arrayOf(
    PT.shape({
      id: PT.string,
      name: PT.string,
      isAssetInstance: PT.bool,
      children: PT.arrayOf(PT.shape({})),
    })
  ).isRequired,
  loading: PT.bool.isRequired,
  formattedAssets: PT.shape({}),
  change: PT.func.isRequired,
};
AssetsTree.defaultProps = {
  formattedAssets: {},
};
export default AssetsTree;
