import { useState, useEffect } from 'react';
import { MoreVert } from '@mui/icons-material';
import {
  StyledIconButton,
  EditButton,
  RejectButton,
  UpdateStatusButton,
} from 'components/buttons';
import { useParams } from 'react-router-dom';
import useButton from 'components/buttons/hooks/use-button';
import { MenuItem, Menu } from '@mui/material';

export default (parameter, setParameter, setError) => {
  const [openDetails, setOpenDetails] = useState(true);
  const [openRejectForm, setOpenRejectForm] = useState(false);
  const [openResetForm, setOpenResetForm] = useState(false);
  const [openApproveForm, setOpenApproveForm] = useState(false);
  const [openCheckForm, setOpenCheckForm] = useState(false);
  const [openEditingForm, setOpenEditingForm] = useState(false);
  const [openSourceDetail, setOpenSourceDetail] = useState(false);
  const [openAddSource, setOpenAddSource] = useState(false);
  const [openRejectedAlert, setOpenRejectedAlert] = useState(false);
  const [loading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { parameterId } = useParams();
  const [selectedSource, setSelectedSource] = useState({});
  const [formValues, setFormValues] = useState({});
  const [heldFormValues, setHeldFormValues] = useState({});

  const {
    renderTooltipEditCloseIcon,
    renderTooltipDoneIcon,
    renderTooltipReject,
  } = useButton();

  useEffect(() => {
    setError(undefined);
    setOpenResetForm(false);
    setOpenApproveForm(false);
    setOpenCheckForm(false);
    setOpenRejectForm(false);
    setOpenSourceDetail(false);
    setOpenEditingForm(false);
    setOpenAddSource(false);
    setHeldFormValues({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameterId]);

  const openActionMenu = event => setAnchorEl(event.currentTarget);

  const handleOpen = () => setOpenDetails(!openDetails);

  const handleOpenRejectForm = () => {
    setError(undefined);
    setOpenRejectForm(true);
    setOpenApproveForm(false);
    setOpenCheckForm(false);
    setOpenSourceDetail(false);
    setOpenEditingForm(false);
    setOpenAddSource(false);
  };

  const handleOpenResetForm = () => {
    setError(undefined);
    setOpenResetForm(true);
    setOpenRejectForm(false);
    setOpenSourceDetail(false);
    setOpenEditingForm(false);
    setOpenAddSource(false);
  };

  const handleOpenApproveForm = () => {
    setError(undefined);
    setOpenApproveForm(true);
    setOpenRejectForm(false);
    setOpenCheckForm(false);
    setOpenSourceDetail(false);
    setOpenEditingForm(false);
    setOpenAddSource(false);
  };

  const handleOpenCheckForm = () => {
    setError(undefined);
    setOpenApproveForm(false);
    setOpenCheckForm(true);
    setOpenRejectForm(false);
    setOpenSourceDetail(false);
    setOpenEditingForm(false);
    setOpenAddSource(false);
  };

  const handleOpenSourceDetails = () => {
    if (!openSourceDetail) {
      setOpenRejectForm(false);
      setOpenResetForm(false);
      setOpenApproveForm(false);
      setOpenCheckForm(false);
      setOpenSourceDetail(false);
      setOpenEditingForm(false);
      setOpenAddSource(false);
    }
    setOpenSourceDetail(!openSourceDetail);
  };

  const getActionButtons = () => {
    const open = Boolean(anchorEl);
    const handleClose = () => {
      setAnchorEl(null);
    };

    const editClick = () => {
      setError(undefined);
      setOpenEditingForm(true);
      setOpenSourceDetail(false);
      setOpenRejectForm(false);
      setOpenResetForm(false);
      setOpenApproveForm(false);
      setOpenCheckForm(false);
    };

    const statusClick = () => {
      if (parameter?.selectedEntry?.status === 'answered')
        handleOpenCheckForm();
      if (parameter?.selectedEntry?.status === 'checked') {
        handleOpenApproveForm();
      }
      if (parameter?.selectedEntry?.status === 'rejected')
        handleOpenResetForm();
    };

    return (
      <>
        <StyledIconButton
          title="Actions"
          data-cy="moreIcon"
          size="small"
          icon={<MoreVert />}
          onClick={openActionMenu}
        />
        <Menu
          aria-label="more-actions-menu"
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          disableGutters
          open={open}
          onClose={handleClose}
          onClick={handleClose}
        >
          {!openEditingForm && (
            <MenuItem
              sx={{ height: 40, p: 0 }}
              dense
              onKeyDown={event => {
                if (!renderTooltipEditCloseIcon(openEditingForm, false)[1]) {
                  if (event.key === ' ' || event.key === 'Enter') {
                    editClick();
                    handleClose();
                  }
                }
              }}
            >
              <EditButton
                type="Parameter"
                key="edit-button"
                onClick={() => editClick()}
                isEditing={openEditingForm}
              />
            </MenuItem>
          )}
          {parameter?.selectedEntry?.values &&
            (parameter?.selectedEntry?.status === 'answered' ||
              parameter?.selectedEntry?.status === 'checked' ||
              parameter?.selectedEntry?.status === 'rejected') && (
              <MenuItem
                data-cy="updateStatus"
                sx={{ height: 40, p: 0 }}
                dense
                onKeyDown={event => {
                  if (
                    !renderTooltipDoneIcon(parameter?.selectedEntry?.status)[1]
                  ) {
                    if (event.key === ' ' || event.key === 'Enter') {
                      statusClick();
                      handleClose();
                    }
                  }
                }}
              >
                <UpdateStatusButton
                  key="update-button"
                  parameter={parameter}
                  setParameter={setParameter}
                  setError={setError}
                  handleOpenResetForm={handleOpenResetForm}
                  handleOpenApproveForm={handleOpenApproveForm}
                  handleOpenCheckForm={handleOpenCheckForm}
                />
              </MenuItem>
            )}
          {parameter?.selectedEntry?.status !== 'rejected' &&
            parameter?.selectedEntry !== null && (
              <MenuItem
                sx={{ height: 40, p: 0 }}
                dense
                onKeyDown={event => {
                  if (
                    !renderTooltipReject(parameter?.selectedEntry?.status)[1]
                  ) {
                    if (event.key === ' ' || event.key === 'Enter') {
                      handleOpenRejectForm();
                      handleClose();
                    }
                  }
                }}
              >
                <RejectButton
                  key="reject-button"
                  status={parameter?.selectedEntry?.status}
                  onClick={handleOpenRejectForm}
                />
              </MenuItem>
            )}
        </Menu>
      </>
    );
  };

  const entries = parameter
    ? [parameter].map(entry => ({
        id: entry?.selectedEntry?.id || 'loading',
        status: entry?.selectedEntry?.status || 'unanswered',
        value: entry?.selectedEntry?.values?.[0],
        source: entry.selectedEntry?.source,
        units: entry?.selectedEntry?.values?.[0]?.unit?.symbol,
      }))
    : [];

  return {
    entries,
    handleOpen,
    openRejectForm,
    setOpenRejectForm,
    setOpenResetForm,
    openResetForm,
    setOpenApproveForm,
    openApproveForm,
    setOpenCheckForm,
    openCheckForm,
    openEditingForm,
    openSourceDetail,
    openDetails,
    loading,
    getActionButtons,
    setOpenEditingForm,
    handleOpenSourceDetails,
    handleOpenRejectForm,
    openAddSource,
    setOpenAddSource,
    selectedSource,
    setSelectedSource,
    openRejectedAlert,
    setOpenRejectedAlert,
    formValues,
    setFormValues,
    heldFormValues,
    setHeldFormValues,
    handleOpenResetForm,
    handleOpenApproveForm,
    handleOpenCheckForm,
  };
};
