import { Grid, Typography, TextField, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useContext } from 'react';
import { ReportingContext, ProjectContext } from 'context';
import { isEmpty } from 'lodash';
import Alert from 'components/alerts';

const Summary = () => {
  const { report, downloadReport, downloadLoading, error, content } =
    useContext(ReportingContext);
  const { project } = useContext(ProjectContext);

  return (
    <Grid
      container
      display="flex"
      direction="column"
      alignContent="left"
      spacing={2}
      paddingTop={5}
      paddingBottom={10}
    >
      <Grid item container direction="row" alignItems="center">
        <Typography variant="h5">Report generation</Typography>
        <Grid
          sx={{ marginLeft: 'auto' }}
          color="primary"
          display="flex"
          alignItems="center"
        >
          <Typography variant="h6" paddingRight={1}>
            Powered by
          </Typography>

          <img src="/logos/hotdocs.png" alt="hotdocs logo" />
        </Grid>
      </Grid>
      <Grid item pb={1}>
        <Grid item xs={6}>
          <Typography color="GrayText">
            Generation of report may take up to 2 minutes
          </Typography>
        </Grid>
      </Grid>
      <Grid item pb={2}>
        {error && (
          <Grid item sx={{ mb: 1 }}>
            <Box>
              <Alert
                title={
                  error.name === 'ApiError' && !error.msg
                    ? error.name
                    : error?.msg
                }
                text={
                  error.name === 'ApiError' && !error.msg
                    ? 'Automated Reporting is currently unavailable for large project datasets due to Hotdocs server limitations. We are working to resolve this'
                    : error?.details
                }
              />
            </Box>
          </Grid>
        )}
        <Grid container direction="row" padding={0.5} spacing={1}>
          <Grid item xs={3} sm={3}>
            <Typography align="right" justifySelf="center">
              Report Name
            </Typography>
          </Grid>
          <Grid paddingLeft="10" item xs={9} sm={9}>
            <TextField
              aria-label="reportName"
              disabled
              fullWidth
              variant="standard"
              size="small"
              value={report.name}
            />
          </Grid>
          <Grid item xs={3} sm={3}>
            <Typography align="right" justifySelf="center">
              Project name
            </Typography>
          </Grid>
          <Grid paddingLeft="10" item xs={9} sm={9}>
            <TextField
              aria-label="projectName"
              disabled
              fullWidth
              variant="standard"
              size="small"
              value={project.shortTitle}
            />
          </Grid>
          <LoadingButton
            disabled={
              isEmpty(content.repeatRegions) &&
              isEmpty(content.nonRepeatRegions)
            }
            aria-label="downloadButton"
            loading={
              (downloadLoading && !error) ||
              (isEmpty(content.repeatRegions) &&
                isEmpty(content.nonRepeatRegions) &&
                !error)
            }
            variant="contained"
            sx={{ width: '100%', marginTop: 2 }}
            data-cy="download-report-button"
            onClick={() => {
              downloadReport();
            }}
          >
            Download Report
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Summary;
