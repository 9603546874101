export default {
  integrations: {
    automatedReporting:
      'https://api.github.com/repos/arup-group/ddb-hotdocs-ui/contents/README-DDB-WEB.md',

    grasshopper:
      'https://api.github.com/repos/arup-group/ddb-grasshopper/readme',
    cadTools:
      'https://arup.sharepoint.com/sites/bim-cadmgmtnextgen/sitepages/kb%20articles/8.%20ddb%20link/ddb-link-user-guide.aspx',
    poweredDDBImage:
      'https://api.github.com/repos/arup-group/ddb-documentation/contents/src/.vuepress/public/Powered%20by%20DDB_large%20text.svg',
    policiesMarkdownAUP:
      'https://api.github.com/repos/arup-group/ddb-documentation/contents/src/overview/acceptable-use-policy.md',
    policiesAUPCommits:
      'https://api.github.com/repos/arup-group/ddb-documentation/commits?path=src/overview/acceptable-use-policy.md&sha=main',
    policiesCookiesCommits:
      'https://api.github.com/repos/arup-group/ddb-documentation/commits?path=src/overview/cookie-policy.md&sha=main',
  },
  sdks: {
    javascript: 'https://api.github.com/repos/arup-group/ddb-js/readme',
    dotnet: 'https://api.github.com/repos/arup-group/ddb-dotnet/readme',
    python: 'https://api.github.com/repos/arup-group/ddb-python/readme',
  },
  changelogs: {
    web: 'https://api.github.com/repos/arup-group/ddb-web/contents/CHANGELOG.md',
    'comments-service':
      'https://api.github.com/repos/arup-group/ddb-microservices/contents/apis/comments-service/CHANGELOG.md',
    'environment-context-service':
      'https://api.github.com/repos/arup-group/ddb-microservices/contents/apis/environment-context-service/CHANGELOG.md',
    'parameter-metadata-service':
      'https://api.github.com/repos/arup-group/ddb-microservices/contents/apis/parameter-metadata-service/CHANGELOG.md',
    'parameter-service':
      'https://api.github.com/repos/arup-group/ddb-microservices/contents/apis/parameter-service/CHANGELOG.md',
    'qa-service':
      'https://api.github.com/repos/arup-group/ddb-microservices/contents/apis/qa-service/CHANGELOG.md',
    'reference-data-service':
      'https://api.github.com/repos/arup-group/ddb-microservices/contents/apis/reference-data-service/CHANGELOG.md',
    'user-service':
      'https://api.github.com/repos/arup-group/ddb-microservices/contents/apis/user-service/CHANGELOG.md',
  },
  url: {
    repoBackend: 'https://github.com/arup-group/ddb-microservices',
    repoFrontend: 'https://github.com/arup-group/ddb-web/',
    globalAutoSharepoint:
      'https://arup.sharepoint.com/teams/global-design-automation-team/SitePages/Digital-Design-Brief.aspx',
    sharepoint:
      'https://arup.sharepoint.com/sites/DigitalDesignBriefApplication',
    yammerGroup:
      'https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIzMTM0MDIyNDUxMiJ9/all',
    yammerGroupIFrame:
      'https://web.yammer.com/embed/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIzMTM0MDIyNDUxMiJ9?header=false&footer=true&theme=light&includeFeedInformation=true',
    devDiaries:
      'https://arup.sharepoint.com/teams/global-design-automation-team/_layouts/15/SeeAll.aspx?Page=%2Fteams%2Fglobal-design-automation-team%2FSitePages%2FDigital-Design-Brief.aspx&InstanceId=0b816542-4c66-4e5e-b6ff-afe31db192db',
    feedback: 'https://feedback.arup.com/digital-design-brief/22',
    bugs: 'https://arup.service-now.com/sp/?id=sc_cat_item&sys_id=7266ac471b34651494b920e0b24bcb64',
    newParameterTypeForm:
      'https://forms.office.com/Pages/ResponsePage.aspx?id=QYvkSjcBmUWGYfxkH-d76r9J3o0lT8pGqmvgG3s2bKBUOVNGVU5VTFMwN0wxOEI4U0Q0STJFUDYzOSQlQCN0PWcu',
    mailingList: 'https://forms.office.com/r/kv6PHGjiac',

    docs: `https://docs.ddb.arup.com/`,
    docsConnectors: `https://docs.ddb.arup.com/connectors/overview.html`,
    docsUserGuide: `https://docs.ddb.arup.com/guide/getting-started.html`,
    docsDataDictionary: `https://docs.ddb.arup.com/guide/data-dictionary.html`,
    policiesAUP:
      'https://docs.ddb.arup.com/overview/acceptable-use-policy.html',
    policiesPrivacy: 'https://docs.ddb.arup.com/overview/privacy-policy.html',
    policiesCookies: 'https://docs.ddb.arup.com/overview/cookie-policy.html',
    policiesDataSecurity:
      'https://docs.ddb.arup.com/overview/where-is-my-data.html#data-security',
    policiesDataResidency:
      'https://docs.ddb.arup.com/overview/where-is-my-data.html#data-residency',
    policiesModernSlavery: 'https://www.arup.com/our-firm/legal',
  },
};
