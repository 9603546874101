import { Routes, Route, Navigate } from 'react-router-dom';
import { Suspense, useEffect } from 'react';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { MetaHeader } from 'components/meta';
import HeaderToolbar from 'components/header';
import ProjectsPage from 'routes/projects-page';
import AcceptableUseCookiesPopup from 'routes/home/cookies';
import config from 'config';
import UserSettings from 'routes/user-settings';
import ErrorPage from 'routes/error-page';
import ProjectPages from 'routes/project-pages';
import HomePage from 'routes/home';
import CentraliseData from 'routes/information-pages/centralise-data';
import DataIntegration from 'routes/information-pages/data-integration';
import TrackChanges from 'routes/information-pages/track-changes';
import { setFavicon } from 'utils';
import withRoot from './with-root';
import PrivateRoute from './private';

posthog.init(process.env.REACT_APP_POSTHOG_API_KEY, {
  api_host: process.env.REACT_APP_POSTHOG_API_URL,
});

export const App = () => {
  useEffect(() => {
    setFavicon();
  }, []);

  return (
    <PostHogProvider client={posthog}>
      <MetaHeader />
      <Suspense fallback={null}>
        <AcceptableUseCookiesPopup />
      </Suspense>
      <HeaderToolbar />
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={null}>
              <HomePage />
            </Suspense>
          }
          index
        />

        <Route
          path="/projects"
          element={
            <PrivateRoute>
              <ProjectsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/projects/:projectId/*"
          element={
            <PrivateRoute>
              <ProjectPages />
            </PrivateRoute>
          }
        />
        <Route path="/user-settings/*" element={<UserSettings />} />
        <Route
          path="/centralise-data"
          element={
            <Suspense fallback={null}>
              <CentraliseData />
            </Suspense>
          }
        />
        <Route
          path="/data-integration"
          element={
            <Suspense fallback={null}>
              <DataIntegration />
            </Suspense>
          }
        />
        <Route
          path="/track-changes"
          element={
            <Suspense fallback={null}>
              <TrackChanges />
            </Suspense>
          }
        />
        <Route
          path="/error"
          element={
            <Suspense fallback={null}>
              <ErrorPage />
            </Suspense>
          }
        />
        <Route
          path="/chatbot"
          element={
            <PrivateRoute>
              {() => {
                window.location.href = `${config.url.api.base}/chatbot/`;
              }}
            </PrivateRoute>
          }
        />
        <Route path="*" element={<Navigate to="/error" replace />} />
      </Routes>
    </PostHogProvider>
  );
};

export default withRoot(App);
