import { Suspense } from 'react';
import { Route, Routes, useParams, Navigate } from 'react-router-dom';
import { Stack, Divider, Grid, useMediaQuery } from '@mui/material';
import { withAppInsights } from 'app/app-insights';
import NavigationMenu from 'components/drawer/responsive-container/navigation-menu';
import Sidebar from 'components/sidebar';
import ProjectRoles from './roles';
import ProjectSecurity from './security';
import tabList from './tabs-list';
import StyledGrid from './container-styles';

function ProjectSettings() {
  const { projectId } = useParams();

  const screenSm = useMediaQuery(theme => theme.breakpoints.down('md'));

  return (
    <Stack
      aria-label="setting-layout"
      display="flex"
      flexDirection="row"
      flexWrap="nowrap"
      justifyContent="stretch"
      alignContent="stretch"
      divider={<Divider orientation="vertical" flexItem />}
    >
      <Suspense fallback={null}>
        <Sidebar id="project-settings" />
      </Suspense>
      <Suspense fallback={null}>
        <Grid flexDirection="column">
          <NavigationMenu
            navigationListTitle="Project Settings"
            drawerOpen={!screenSm}
            toggleDrawerOpen={() => {}}
            navigationList={tabList}
          />
        </Grid>
      </Suspense>
      <StyledGrid>
        <Routes>
          <Route
            path="roles"
            element={
              <Suspense fallback={null}>
                <ProjectRoles projectId={projectId} />
              </Suspense>
            }
          />
          <Route
            path="security"
            element={
              <Suspense fallback={null}>
                <ProjectSecurity />
              </Suspense>
            }
          />
          <Route path="*" element={<Navigate to="/error" replace />} />
        </Routes>
      </StyledGrid>
    </Stack>
  );
}

export default withAppInsights(ProjectSettings, 'ProjectSettingsPage');
