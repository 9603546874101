import { PermIdentity, VpnKey, Palette, Cookie } from '@mui/icons-material/';

const tabs = [
  {
    path: '/user-settings/details',
    text: 'Details',
    icon: <PermIdentity />,
    userAccess: true,
    'data-cy': 'user-details',
    requiresAuthentication: true,
  },
  {
    path: '/user-settings/permissions',
    text: 'Permissions',
    icon: <VpnKey />,
    userAccess: true,
    'data-cy': 'user-permissions',
    requiresAuthentication: true,
  },
  {
    path: '/user-settings/theme',
    text: 'Theme',
    icon: <Palette />,
    userAccess: true,
    'data-cy': 'user-theme',
    requiresAuthentication: false,
  },
  {
    path: '/user-settings/cookies',
    text: 'Cookies',
    icon: <Cookie />,
    userAccess: true,
    'data-cy': 'user-cookies',
    requiresAuthentication: false,
  },
];

export default tabs;
