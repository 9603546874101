import { Grid, Typography } from '@mui/material';
import Alert from 'components/alerts/index';
import PT from 'prop-types';
import { useEffect } from 'react';
import useAssetsSelected from './hooks/use-assets-selected';
import AssetRequirementsCard from './asset-requirements-card';

const AssetRequirements = ({
  formattedAssets,
  selectedAssets,
  assetNodes,
  updateSelection,
  change,
}) => {
  const { requirementsCount, requiredAssetsSelected, validationError } =
    useAssetsSelected(assetNodes, selectedAssets);

  let prefixCounter = 0;
  if (formattedAssets?.assetType === null) prefixCounter -= 1;

  useEffect(() => {
    change('requirementsCount', requirementsCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requirementsCount]);

  useEffect(() => {
    change('validationErrorDetails', validationError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationError]);

  return (
    <Grid
      item
      container
      direction="column"
      xs={6}
      aria-label="asset-requirements-panel"
    >
      <Grid item aria-label="asset-requirements-title">
        <Typography
          color={theme => theme.palette.secondary}
          variant="body1"
          mb={theme => theme.spacing(1)}
        >
          Asset Requirements
        </Typography>
      </Grid>
      <Grid item aria-label="asset-requirements-list-narative">
        <Typography color={theme => theme.palette.secondary} variant="body2">
          All items in list below are required
        </Typography>
      </Grid>
      <Grid item aria-label="alert-info">
        {requirementsCount > 0 ? (
          <Alert
            ariaLabel="incomplete-asset-selection"
            severity="info"
            text={`${requirementsCount} minimum required asset(s) missing. Create/select assets following the requirements below.`}
            boxStyle={{
              paddingLeft: 1,
              paddingRight: 0,
              paddingTop: 0,
              paddingBottom: 0,
              marginBottom: 1,
            }}
          />
        ) : (
          <Alert
            severity="success"
            ariaLabel="complete-asset-selection"
            text="All minimum asset requirements fulfilled"
            boxStyle={{
              paddingLeft: 1,
              paddingRight: 0,
              paddingTop: 0,
              paddingBottom: 0,
              marginBottom: 1,
            }}
          />
        )}
      </Grid>
      <AssetRequirementsCard
        formattedAssets={formattedAssets}
        updateSelection={updateSelection}
        requiredAssetsSelected={requiredAssetsSelected}
        prefixCounter={prefixCounter}
        validationError={validationError}
      />
    </Grid>
  );
};

AssetRequirements.propTypes = {
  formattedAssets: PT.shape({
    assetType: PT.shape({}),
    children: PT.arrayOf(PT.shape({})),
  }),
  selectedAssets: PT.arrayOf(PT.shape({})),
  assetNodes: PT.arrayOf(
    PT.shape({
      id: PT.string,
      name: PT.string,
      isAssetInstance: PT.bool,
      children: PT.arrayOf(PT.shape({})),
    })
  ).isRequired,
  updateSelection: PT.func,
  change: PT.func.isRequired,
};

AssetRequirements.defaultProps = {
  formattedAssets: {},
  selectedAssets: [],
  updateSelection: () => {},
};

export default AssetRequirements;
