import PT from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
} from '@mui/material';
import handleAddProject from './hooks';
import Title from './title';
import Actions from './actions';
import ErrorHandling from './errors';
import Selection from './selection';
import Search from './search';

function AddProjectModal({ open, toggleOpen, addProject }) {
  const fullScreen = useMediaQuery(theme => theme.breakpoints.down('md'));
  const {
    selectedProject,
    addingProject,
    projectSearch,
    options,
    loading,
    addedProject,
    addingProjectErrors,
    postProject,
    handleInputChange,
    handleSelection,
    handleClose,
    projectExists,
  } = handleAddProject(addProject, toggleOpen);

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      aria-labelledby="Add a new Project"
      data-testid="addNewProjectModal"
    >
      <DialogTitle
        sx={{
          paddingBottom: theme => theme.spacing(1),
          paddingTop: theme => theme.spacing(1),
        }}
      >
        <Title handleClose={handleClose} />
      </DialogTitle>
      <DialogContent dividers sx={{ minHeight: 450 }}>
        <Search
          projectSearch={projectSearch}
          options={options}
          loading={loading}
          handleInputChange={handleInputChange}
          handleSelection={handleSelection}
          addedProject={addedProject}
        />
        {selectedProject && <Selection selectedProject={selectedProject} />}
        {addingProjectErrors && (
          <ErrorHandling addingProjectErrors={addingProjectErrors} />
        )}
      </DialogContent>
      <DialogActions>
        <Actions
          toggleOpen={toggleOpen}
          handleClose={handleClose}
          addedProject={addedProject}
          postProject={postProject}
          addingProject={addingProject}
          selectedProject={selectedProject}
          addingProjectErrors={addingProjectErrors}
          projectExists={projectExists}
        />
      </DialogActions>
    </Dialog>
  );
}

AddProjectModal.propTypes = {
  open: PT.bool.isRequired,
  toggleOpen: PT.func.isRequired,
  addProject: PT.func.isRequired,
};

export default AddProjectModal;
