import { useContext, useEffect } from 'react';
import PT from 'prop-types';
import {
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  Box,
  Chip,
  useTheme,
  Typography,
} from '@mui/material';
import {
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
  Search,
} from '@mui/icons-material';
import { lighten, darken } from '@mui/material/styles';
import { ListBoxComponent } from 'components/pickers';
import Alert from 'components/alerts';
import { Autocomplete } from 'components/pickers/final-form-fields';
import config from 'config';
import { reorderArray } from 'utils';
import { ThemeContext } from 'context';
import ParameterTypeImporter from '../import-params';
import getTypes from './hooks/use-parameter-types';

const ParameterSelector = ({ open, values, ...rest }) => {
  const { setFormAttribute } = rest;
  const { localTheme } = useContext(ThemeContext);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const theme = useTheme();
  const {
    availableParameterTypesLoading,
    availableParameterTypes,
    openImportDrawer,
    handleOpen,
    setOpenImportDrawer,
    inputValue,
    setInputValue,
    error,
  } = getTypes(open);

  useEffect(() => {
    const orderedParameterTypes = reorderArray(values.parameterTypes);
    setFormAttribute('parameterTypes', orderedParameterTypes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.parameterTypes]);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      height={availableParameterTypesLoading ? '40vh' : 'auto'}
    >
      {availableParameterTypesLoading && (
        <CircularProgress size={100} aria-label="loadingSkeleton" />
      )}
      {error && (
        <Box sx={{ padding: theme.spacing(3) }} aria-label="import-alert">
          <Alert title={error.msg} text={error.details} />
        </Box>
      )}
      {!error && !availableParameterTypesLoading && !openImportDrawer && (
        <Grid item xs={config.featureFlags.isCreateItemEnabled ? 10 : 12}>
          <Autocomplete
            name="parameterTypes"
            data-cy="select-parameter-box"
            aria-label="parameter-selector"
            variant="outlined"
            disableCloseOnSelect
            disableListWrap
            required
            value={values.parameterTypes || []}
            ListboxComponent={ListBoxComponent}
            options={availableParameterTypes}
            getOptionValue={option => option}
            getOptionLabel={option => option.name}
            isOptionEqualToValue={(option, value) =>
              option.parameterTypeId === value.parameterTypeId
            }
            onChange={(event, newValue) => {
              const newValues = newValue.map(value => value.parameterTypeId);
              const filteredItems = values.itemsImport?.filter(item =>
                newValues.includes(item.parameterTypeId)
              );
              setFormAttribute('itemsImport', filteredItems);
            }}
            loading={availableParameterTypesLoading}
            multiple
            label="Add Parameters"
            renderTags={value =>
              value.map(option => (
                <Chip
                  key={option.parameterTypeId}
                  sx={{
                    height: theme.spacing(5),
                    margin: theme.spacing(0.25),
                    marginTop: theme.spacing(1),
                    backgroundColor:
                      localTheme === 'dark'
                        ? darken(theme.palette.primary.main, 0.3)
                        : lighten(theme.palette.primary.main, 0.8),
                  }}
                  label={option.name}
                  icon={
                    option?.isNewParameterType ? (
                      <Chip
                        sx={{
                          marginTop: 2,
                          marginBottom: 2,
                          backgroundColor: theme.palette.background.paper,
                        }}
                        label={
                          <Typography variant="subTitle1" fontWeight="bold">
                            Custom
                          </Typography>
                        }
                      />
                    ) : null
                  }
                  onDelete={() => {
                    const newSelectionModel = value.filter(
                      param => param.parameterTypeId !== option.parameterTypeId
                    );
                    setFormAttribute('parameterTypes', newSelectionModel);
                    const filteredItems = values.itemsImport?.filter(
                      item => item.parameterTypeId !== option.parameterTypeId
                    );
                    setFormAttribute('itemsImport', filteredItems);
                    const filteredParams = values.parameterTypesImport?.filter(
                      param => param.id !== option.parameterTypeId
                    );
                    setFormAttribute('parameterTypesImport', filteredParams);
                  }}
                />
              ))
            }
            renderOption={(props, option, { selected }) => (
              <li {...props} data-cy="selected-parameter">
                <Checkbox
                  data-cy="selected-parameter"
                  icon={icon}
                  checkedIcon={checkedIcon}
                  sx={{
                    marginRight: theme.spacing(2),
                    backgroundColor: 'primary',
                  }}
                  checked={selected}
                />
                {option.name}
              </li>
            )}
            onInputChange={(event, newInputValue, reason) => {
              if (reason === 'input') setInputValue(newInputValue);
              if (event?.type === 'blur') setInputValue('');
            }}
            inputValue={inputValue}
          />
        </Grid>
      )}
      {!error &&
        !availableParameterTypesLoading &&
        !openImportDrawer &&
        config.featureFlags.isCreateItemEnabled && (
          <Grid item xs={2}>
            <Button
              data-cy="advanced-search-button"
              disabled={availableParameterTypesLoading}
              onClick={handleOpen}
              sx={{ margin: theme.spacing(2) }}
              endIcon={<Search />}
            >
              Can&apos;t Find What You Need?
            </Button>
          </Grid>
        )}
      {!availableParameterTypesLoading && openImportDrawer && (
        <Grid item xs={12}>
          <ParameterTypeImporter
            values={values}
            setFormAttribute={setFormAttribute}
            open={openImportDrawer}
            setOpenImportDrawer={setOpenImportDrawer}
            availableParameterTypes={availableParameterTypes}
            {...rest}
          />
        </Grid>
      )}
    </Grid>
  );
};

ParameterSelector.propTypes = {
  open: PT.bool.isRequired,
  values: PT.shape({
    parameterTypes: PT.arrayOf(PT.shape({})),
    itemsImport: PT.arrayOf(PT.shape({})),
    parameterTypesImport: PT.arrayOf(PT.shape({})),
  }).isRequired,
};

export default ParameterSelector;
