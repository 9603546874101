/* eslint-disable no-restricted-imports */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import PT from 'prop-types';
import WarningIcon from '@mui/icons-material/Warning';
import { Grid, Collapse, Checkbox, Alert, Typography } from '@mui/material';
import { ExpandOrCollapseButton } from 'components/buttons';
import { useContext } from 'react';
import { ReportingContext } from 'context';
import Parameter from '../parameter';
import {
  StyledGrid,
  StyledTypography,
  StyledGridItem,
} from './mapped-region-styles';

const MappedRegion = ({
  name,
  open,
  selected,
  parameters,
  handleSelection,
  assets,
  setOpen,
  id,
  valuesMissing,
  parametersMissing,
  missingRepeatRegionAssets,
}) => {
  const { updateSelection } = useContext(ReportingContext);
  const nameListRemoved = name.replace(' list', '').replace(' repeat', '');
  return (
    <StyledGrid container pb={1} aria-label="mapped-region">
      {selected !== undefined && (
        <Grid item xs={1}>
          <Checkbox
            checked={selected}
            onChange={handleSelection}
            aria-label="checkbox"
          />
        </Grid>
      )}
      <StyledGridItem item xs={11} p={1} sx={{ borderRadius: 1 }}>
        <Grid container spacing={1} alignItems="center">
          <Grid
            item
            display="flex"
            sx={{
              columnGap: theme => theme.spacing(0.5),
            }}
            alignItems="center"
          >
            <ExpandOrCollapseButton
              data-cy="expand-or-collapse"
              open={open[id]}
              onClick={() =>
                setOpen(curr => {
                  curr[id] = !open[id];
                })
              }
            />
            {missingRepeatRegionAssets === true && (
              <WarningIcon color="warning" />
            )}
            <StyledTypography>{nameListRemoved}</StyledTypography>
          </Grid>
          <Grid
            display="flex"
            sx={{
              ml: 'auto',
              paddingRight: theme => theme.spacing(0.5),
              columnGap: theme => theme.spacing(0.5),
            }}
            item
          >
            {valuesMissing !== undefined && valuesMissing.length !== 0 && (
              <Alert severity="warning" data-testid="value-missing-alert">
                {`${valuesMissing.length} of ${
                  parameters.filter(param => {
                    return !param.name.includes('_ units');
                  }).length
                } values missing`}
              </Alert>
            )}
            {parametersMissing !== undefined &&
              parametersMissing.length !== 0 && (
                <Alert severity="warning" data-testid="params-missing-alert">
                  {` "${parametersMissing.length} of ${
                    parameters.filter(param => {
                      return !param.name.includes('_ units');
                    }).length
                  } parameters not found in DDB}`}
                </Alert>
              )}
          </Grid>

          {missingRepeatRegionAssets === true && (
            <Grid display="flex" item sx={{ ml: 'auto' }}>
              <Typography
                severity="warning"
                sx={{
                  paddingRight: theme => theme.spacing(0.5),
                }}
              >
                No asset instances found
              </Typography>
            </Grid>
          )}
        </Grid>
        <Collapse in={open[id]}>
          <Grid container flex direction="row">
            {parameters !== undefined
              ? parameters.map(parameter => {
                  return (
                    <Parameter
                      key={`${parameter.name}-${parameter.value}`}
                      parameterName={parameter.name}
                      parameterValue={parameter.value}
                      parametersMissing={parametersMissing}
                      valuesMissing={valuesMissing}
                    />
                  );
                })
              : assets?.map(
                  ({
                    id: assetId,
                    name: assetName,
                    parameters: assetParams,
                    checked: assetChecked,
                    valuesMissing: paramValuesMissing,
                    parametersMissing: paramsMissing,
                  }) => (
                    <MappedRegion
                      key={assetId}
                      id={assetId}
                      name={assetName}
                      open={open}
                      setOpen={setOpen}
                      parameters={assetParams}
                      selected={assetId && assetChecked}
                      handleSelection={() => {
                        updateSelection(assetId, name);
                      }}
                      valuesMissing={paramValuesMissing}
                      parametersMissing={paramsMissing}
                    />
                  )
                )}
          </Grid>
        </Collapse>
      </StyledGridItem>
    </StyledGrid>
  );
};

MappedRegion.propTypes = {
  id: PT.string.isRequired,
  name: PT.string.isRequired,
  open: PT.shape({}).isRequired,
  selected: PT.bool,
  parameters: PT.arrayOf(
    PT.shape({ name: PT.string, value: PT.oneOfType([PT.string, PT.number]) })
  ),
  assets: PT.arrayOf(
    PT.shape({
      name: PT.string,
      parameters: PT.arrayOf(
        PT.shape({
          name: PT.string,
          value: PT.oneOfType([PT.string, PT.number]),
        })
      ),
    }).isRequired
  ),
  setOpen: PT.func.isRequired,
  handleSelection: PT.func,
  valuesMissing: PT.number,
  parametersMissing: PT.arrayOf(PT.shape({})),
  missingRepeatRegionAssets: PT.bool,
};

MappedRegion.defaultProps = {
  selected: undefined,
  parameters: undefined,
  assets: undefined,
  handleSelection: () => {},
  valuesMissing: undefined,
  parametersMissing: undefined,
  missingRepeatRegionAssets: false,
};
export default MappedRegion;
