/* eslint-disable import/no-unresolved */
import { Suspense, useContext } from 'react';
import config from 'config';
import PageLayout from 'components/layout';
import Sidebar from 'components/sidebar';
import { Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext } from 'context';
import WarningDialog from 'components/dialogs/warning';
import { StyledGrid, Container } from './reporting-page-styles';
import HorizontalStepper from './stepper';
import ReportingTitle from './stepper/reporting-title';

function ReportingPage() {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { user } = useContext(UserContext);
  const handleAction = () => {
    navigate(`/projects/${projectId}/parameters`);
  };

  const handleOpen = () => {
    if (config.env === 'production' && !user?.roles?.includes('HotDocs.Prod')) {
      return true;
    }
    if (
      (config.env === 'ut' || config.env === 'development') &&
      !user?.roles?.includes('HotDocs.UAT')
    ) {
      return true;
    }
    return false;
  };

  return (
    <PageLayout ariaLabel="reporting-page">
      <Suspense fallback={null}>
        <Sidebar id="reporting" />
      </Suspense>
      <Container aria-label="reporting-drawer">
        <StyledGrid item>
          <Suspense fallback={null}>
            <ReportingTitle />
          </Suspense>
        </StyledGrid>
        <Grid item container p={1}>
          <Suspense fallback={null}>
            <HorizontalStepper />
          </Suspense>
        </Grid>
      </Container>
      <Suspense fallback={null}>
        <WarningDialog
          open={handleOpen()}
          title="Do you have a HotDocs licence?"
          body="This feature can only be used if you have a HotDocs licence on the correct environment (production or sandbox). To get a licence, please request the HotDocs application from the Arup Apps."
          primaryButtonText="Return to parameters"
          handleAction={handleAction}
          disableClose
        />
      </Suspense>
    </PageLayout>
  );
}
export default ReportingPage;
