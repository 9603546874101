/* eslint-disable react/prop-types */
import { OutputOutlined } from '@mui/icons-material';
import { Typography, Skeleton } from '@mui/material';
import Alert from 'components/alerts';
import { isEmpty } from 'lodash';
import DataDrawerGrid from 'routes/parameters-page/common/grid';
import SetInstanceOutputsSummary from './summary';
import useDataSetDetailsPanel from './hooks/use-data-set-outputs';

const DataSetOutputs = () => {
  const { dataSetValid, validityLoading } = useDataSetDetailsPanel();

  return (
    <DataDrawerGrid
      alert={
        validityLoading ? (
          <Skeleton width="100%" height={50} />
        ) : (
          !dataSetValid.valid && (
            <Alert
              text={
                // eslint-disable-next-line no-nested-ternary
                !isEmpty(dataSetValid.missingParameters) &&
                !isEmpty(dataSetValid.incorrectAssetCount)
                  ? 'This is an invalid data set. Invalid number of assets and parameters.'
                  : !isEmpty(dataSetValid.missingParameters)
                  ? 'This is an invalid data set. Invalid number of parameters.'
                  : 'This is an invalid data set. Invalid number of assets.'
              }
              severity="error"
              variant="outlined"
            />
          )
        )
      }
      icon={
        <OutputOutlined
          data-testid="set-outputs-icon"
          sx={{
            color: theme => theme.palette.primary.black,
          }}
        />
      }
      caption={
        <Typography
          variant="caption"
          color="textPrimary"
          data-testid="asset-types-label"
        >
          Set outputs
        </Typography>
      }
      content={<SetInstanceOutputsSummary />}
    />
  );
};

export default DataSetOutputs;
