/* eslint-disable no-nested-ternary */
import PT from 'prop-types';
import { useContext } from 'react';
import { Box, Typography, Grid, Tooltip, Skeleton } from '@mui/material';
import { ParametersContext } from 'context';
import { WarningAmber } from '@mui/icons-material';
import { capitalize, isEmpty } from 'lodash';
import StyledProgressBar from './statistics-styles';

const Statistics = ({ title }) => {
  const { statsLoading, statistics, statsError } =
    useContext(ParametersContext);

  const statuses = [
    {
      status: 'rejected',
      value: statistics?.qa?.rejected,
      tooltip: 'Rejected',
    },
    {
      status: 'unanswered',
      value: statistics?.qa?.unanswered,
      tooltip: 'Unanswered',
    },
    {
      status: 'answered',
      value: statistics?.qa?.answered,
      tooltip: 'Answered',
    },
    {
      status: 'checked',
      value: statistics?.qa?.checked,
      tooltip: 'Checked',
    },
    {
      status: 'approved',
      value: statistics?.qa?.approved,
      tooltip: 'Approved',
    },
  ];

  const result = value =>
    Number(((value / statistics?.filteredCount) * 100).toFixed(1));

  return (
    <Box width="100%" aria-label="statistics">
      {title && (
        <Typography variant="caption" color="textSecondary">
          {title}
        </Typography>
      )}
      {statsLoading ? (
        statuses.map(({ status }) => (
          <Skeleton
            height={30}
            key={status}
            width="100%"
            display="inline"
            aria-label="statsLoading"
          />
        ))
      ) : !isEmpty(statsError) ? (
        <Box
          sx={{
            padding: theme => theme.spacing(3),
            backgroundColor: theme => theme.palette.secondary.light,
          }}
        >
          <Grid container>
            <WarningAmber
              sx={{
                color: theme => theme.palette.grey[500],
                marginRight: theme => theme.spacing(0.75),
              }}
            />
            <Typography color="textSecondary">{statsError?.msg}</Typography>
            <Typography variant="caption" color="textSecondary">
              {statsError?.details}
            </Typography>
          </Grid>
        </Box>
      ) : (
        <Grid item sm={12}>
          {statuses.map(({ status, tooltip, value }) => (
            <Box
              aria-label={`${status}Stats`}
              key={status}
              sx={{ display: 'flex', alignItems: 'flex-end', p: 1 }}
            >
              <Box
                key={`progress-bar-box-${status}`}
                sx={{ width: '100%', mr: 1 }}
              >
                {!statsLoading && (
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    key={`label-${status}`}
                  >
                    {`${capitalize(status)} [${value || '0'}]`}
                  </Typography>
                )}
                <Tooltip title={tooltip}>
                  <StyledProgressBar
                    key={`progress-bar-${status}`}
                    status={status}
                    variant="determinate"
                    value={result(value) || 0}
                  />
                </Tooltip>
              </Box>
              <Box sx={{ minWidth: 45 }} key={`label-box-${status}`}>
                {!statsLoading && (
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    key={`label-${status}`}
                  >
                    {`${result(value) || 0}%`}
                  </Typography>
                )}
              </Box>
            </Box>
          ))}
        </Grid>
      )}
    </Box>
  );
};

Statistics.propTypes = {
  title: PT.string,
};

Statistics.defaultProps = {
  title: null,
};

export default Statistics;
