/* eslint-disable no-param-reassign */
import PT from 'prop-types';
import {
  TextField,
  MenuItem,
  CircularProgress,
  ListItemSecondaryAction,
  ListItem,
  Typography,
  Tooltip,
} from '@mui/material';
import { Done, Clear } from '@mui/icons-material';
import { isEmpty } from 'lodash';
import useAddInstance from './hooks/use-add-instance';

const AddInstance = ({ type, subTypes, index, columnIndex }) => {
  const { isPosting, newAsset, setNewAsset, error, onClick, onClearClick } =
    useAddInstance(index, columnIndex, type, subTypes);

  return (
    <ListItem
      aria-label="add-instance"
      data-cy="add-instance"
      sx={{ paddingTop: 0 }}
    >
      {!isEmpty(subTypes) ? (
        <TextField
          select
          data-cy={`wizard-column-select-subType-${columnIndex}`}
          id="add-sub-type"
          aria-label="add-sub-type"
          sx={{ width: 150 }}
          error={!!error}
          color="primary"
          variant="standard"
          label={
            <Typography noWrap sx={{ width: 130 }}>
              {'Add'} {type.name}
            </Typography>
          }
          value={newAsset}
          onChange={e => setNewAsset(e.target.value)}
          helperText={error}
        >
          {subTypes.map(subType => (
            <MenuItem
              data-cy={`wizard-column-selected-subType-${columnIndex}`}
              key={subType.id}
              value={subType}
            >
              {subType.name}
            </MenuItem>
          ))}
        </TextField>
      ) : (
        <TextField
          aria-label="add-free-text"
          id="asset-sub-type"
          error={error}
          autoFocus
          color="primary"
          variant="standard"
          label={`${'Add'} ${type.name}`}
          value={newAsset}
          inputProps={{ 'data-cy': `wizard-column-add-text-${columnIndex}` }}
          onChange={e => setNewAsset(e.target.value)}
          helperText={error}
        />
      )}
      <ListItemSecondaryAction>
        {isPosting ? (
          <CircularProgress size={24} />
        ) : (
          <>
            <Tooltip title="Save">
              <Done
                aria-label="accept-icon"
                sx={{ color: theme => theme.palette.secondary.dark }}
                onClick={onClick}
                data-cy="wizard-save-new"
              />
            </Tooltip>
            <Tooltip title="Cancel">
              <Clear
                aria-label="cancel-icon"
                sx={{ color: theme => theme.palette.secondary.dark }}
                onClick={onClearClick}
                data-cy="wizard-clear-new"
              />
            </Tooltip>
          </>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

AddInstance.propTypes = {
  columnIndex: PT.number.isRequired,
  type: PT.shape({
    id: PT.string,
    name: PT.string,
    isAssetSubType: PT.bool,
    children: PT.arrayOf(PT.shape({ parent: PT.string })),
    parent: PT.string,
    isSubType: PT.bool,
  }).isRequired,
  subTypes: PT.arrayOf(
    PT.shape({
      id: PT.string,
      name: PT.string,
    })
  ),
  index: PT.number.isRequired,
};

AddInstance.defaultProps = {
  subTypes: undefined,
};

export default AddInstance;
