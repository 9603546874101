import { Warning } from '@mui/icons-material';
import { Tooltip, useMediaQuery, Chip } from '@mui/material/';
import PT from 'prop-types';

function ConfidentialChip({ iconOnly }) {
  const screenSm = useMediaQuery(theme => theme.breakpoints.down('md'));
  return (
    <Tooltip title="Confidential">
      <span aria-hidden="true">
        <Chip
          color="error"
          aria-label="confidential-chip"
          icon={<Warning fontSize="small" />}
          label={!iconOnly && !screenSm && 'Confidential'}
          sx={{ paddingLeft: (screenSm || iconOnly) && 1.5 }}
          data-cy="confidential-project-chip"
        />
      </span>
    </Tooltip>
  );
}

ConfidentialChip.propTypes = {
  iconOnly: PT.bool,
};

ConfidentialChip.defaultProps = {
  iconOnly: false,
};

export default ConfidentialChip;
