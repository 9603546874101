import { useContext, useState } from 'react';
import { SourcesContext } from 'context';
import { referenceDataApi } from 'api';
import { findIndex } from 'lodash';
import { useSearchParams } from 'react-router-dom';

const useHeaderActions = () => {
  const {
    setOpenEditingForm,
    selectedSource,
    setSelectedSource,
    setAllSources,
    setSourceErrorOrSuccess,
  } = useContext(SourcesContext);

  const [deleteSourceOpen, setDeleteSourceOpen] = useState(false);
  const [searchParams] = useSearchParams();

  const deleteSelectedSource = () => {
    setSourceErrorOrSuccess({});
    const deleteSource = async () => {
      try {
        const response = await referenceDataApi('deleteSource', {
          id: selectedSource.id,
        });
        if (response) {
          if (searchParams.get('show_deleted_sources') === 'true') {
            setAllSources(curr => {
              const indexOfUpdate = findIndex(
                curr,
                existing => existing.id === selectedSource.id
              );
              // eslint-disable-next-line no-param-reassign
              curr[indexOfUpdate] = {
                ...curr[indexOfUpdate],
                deletedAt: new Date().toISOString(),
              };
            });
          } else {
            setAllSources(curr => {
              const updatedSources = curr.filter(
                existing => existing.id !== selectedSource.id
              );
              return updatedSources;
            });
          }
          setSelectedSource({});
          setSourceErrorOrSuccess({
            msg: 'Source Deleted',
            details: `Successfully deleted source: ${selectedSource.id}`,
            severity: 'success',
          });
          setDeleteSourceOpen(false);
          setOpenEditingForm(false);
        }
      } catch (error) {
        setSourceErrorOrSuccess({
          msg: error?.response?.data?.msg,
          details: error?.response?.data?.details,
        });
        setSelectedSource({});
        setOpenEditingForm(false);
      }
    };
    deleteSource();
  };

  return {
    deleteSelectedSource,
    deleteSourceOpen,
    setDeleteSourceOpen,
  };
};

export default useHeaderActions;
