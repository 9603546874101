import { useState } from 'react';
import { FormControl, Select, MenuItem } from '@mui/material';
import config from 'config';

function Environment() {
  const environmentMap = {
    ut: 'SANDBOX',
    production: 'PRODUCTION',
  };

  const [environment, setEnvironment] = useState(
    environmentMap[config.env] || config.env
  );

  const handleEnvironmentChange = event => {
    const selectedEnv = event.target.value;
    setEnvironment(selectedEnv);

    if (selectedEnv !== environment && selectedEnv !== '') {
      const targetUrl =
        selectedEnv === 'PRODUCTION'
          ? 'https://ddb.arup.com/'
          : 'https://sandbox.ddb.arup.com/';
      window.location.href = targetUrl;
    }
  };

  return (
    <FormControl sx={{ minWidth: theme => theme.spacing(20) }} size="small">
      <Select
        value={environment}
        displayEmpty
        data-testid="environment"
        // eslint-disable-next-line no-confusing-arrow
        renderValue={selected =>
          selected === 'development' ? 'ENVIRONMENT' : selected
        }
        onChange={handleEnvironmentChange}
        sx={{
          fontWeight: theme => theme.typography.fontWeightMedium,
          color: theme => theme.palette.primary.main,
          '& .MuiSvgIcon-root': {
            color: theme => theme.palette.primary.main,
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        }}
      >
        <MenuItem value="SANDBOX">SANDBOX</MenuItem>
        <MenuItem value="PRODUCTION">PRODUCTION</MenuItem>
      </Select>
    </FormControl>
  );
}

export default Environment;
