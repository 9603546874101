import { emojiValidation } from '.';

export default (newAssetName, parent, assetType, allAssets) => {
  if (emojiValidation(newAssetName)) {
    return 'Invalid Character';
  }
  if (
    !newAssetName ||
    newAssetName === '' ||
    newAssetName.match(/^ *$/) !== null
  )
    return 'Cannot be empty';
  if (
    allAssets
      .filter(
        asset =>
          asset.parent === parent &&
          assetType === asset.assetType.id &&
          !asset.deletedAt
      )
      .some(
        existingAsset =>
          existingAsset.name.toLowerCase().trim() ===
          newAssetName.toLowerCase().trim()
      )
  )
    return 'Asset name already exists. Please choose a different name or delete the existing asset';

  return null;
};
