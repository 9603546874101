import PT from 'prop-types';
import { TextSnippetOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useContext } from 'react';
import { ThemeContext } from 'context';
import DataDrawerGrid from '../../common/grid';

function Value({ parameterValue, valueWithUnit }) {
  const { localTheme } = useContext(ThemeContext);
  return (
    <div aria-label="value component">
      <DataDrawerGrid
        icon={<TextSnippetOutlined data-testid="icon" />}
        caption={
          <Typography
            variant="caption"
            color="textSecondary"
            data-testid="label"
          >
            Value
          </Typography>
        }
        content={
          <Typography
            data-testid="value"
            color={
              // eslint-disable-next-line no-nested-ternary
              parameterValue
                ? 'textPrimary'
                : localTheme === 'dark'
                ? theme => theme.palette.error.light
                : 'error'
            }
          >
            {valueWithUnit || 'Not defined'}
          </Typography>
        }
      />
    </div>
  );
}

Value.propTypes = {
  parameterValue: PT.oneOfType([PT.string, PT.bool, PT.number]).isRequired,
  valueWithUnit: PT.string.isRequired,
};

export default Value;
