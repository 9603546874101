import { useState, useContext } from 'react';
import PT from 'prop-types';
import { ProjectContext } from 'context';
import {
  Typography,
  IconButton,
  Tooltip,
  Popover,
  Chip,
  Stack,
  Link,
  Skeleton,
} from '@mui/material';
import { Person, PersonCard } from 'components/microsoft';

import { formatDateWithTime } from 'utils';
import { historyPropTypes } from '../history-content/prop-types/prop-types';
import getEventDetails from '../../../utils/get-event-details';

const AuditAccordionHeader = ({ isLoaded, history }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  let linkHref;
  const { project } = useContext(ProjectContext);

  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  if (history.parameterId !== undefined) {
    linkHref = history.parentId
      ? `/projects/${history.projectId}/asset/${history.parentId}/parameters/${history.parameterId}`
      : `/projects/${history.projectId}/parameters/${history.parameterId}`;
  } else if (history.assetId !== undefined) {
    linkHref = `/projects/${history.projectId}/asset/${history.assetId}`;
  } else if (history.sourceId !== undefined) {
    linkHref = `/projects/${history.projectId}/sources/${history.sourceId}`;
  }

  const popoverOpen = Boolean(anchorEl);
  const id = popoverOpen ? 'simple-popover' : undefined;

  const eventDetails = getEventDetails(history.eventType);

  return (
    <Stack
      sx={{ width: 1 }}
      alignItems="center"
      direction="row"
      aria-label="accordion-header"
      data-testid="accordion-header"
    >
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{ pl: theme => theme.spacing(2) }}
      >
        {isLoaded ? (
          <Tooltip title={history.triggeredBy.email}>
            <IconButton
              size="small"
              sx={{ p: theme => theme.spacing(0) }}
              aria-describedby={id}
              onClick={handleClick}
              aria-label="person-icon"
            >
              <Person
                personQuery={history.triggeredBy.email}
                fallbackDetails={{
                  userPrincipalName: history.triggeredBy.email,
                  mail: history.triggeredBy.email,
                }}
              />
            </IconButton>
          </Tooltip>
        ) : (
          <Skeleton variant="circular" width={50} height={50} />
        )}
        <Stack spacing={0.3}>
          <div aria-label="entity-header">
            {isLoaded ? (
              <Stack direction="row" spacing={2}>
                <Typography sx={{ fontWeight: 'bold' }} aria-label="event-type">
                  {eventDetails.title}
                </Typography>
                <Link
                  underline="hover"
                  color="inherit"
                  href={linkHref}
                  aria-label="entity-name-link"
                  onClick={event => {
                    event.stopPropagation();
                  }}
                >
                  <Typography
                    color={theme => theme.palette.primary.main}
                    sx={{ fontWeight: 'bold' }}
                  >
                    {history.parameterId && history.parameterName}
                    {history.sourceId &&
                      `${
                        history.eventType === 'reference.event.source.removed'
                          ? history.sourceName
                          : history.title
                      }`}
                    {history.assetId &&
                      `${
                        history.eventType === 'parameter.event.asset.removed'
                          ? history.currentAssetName
                          : history.name
                      } (${history.assetTypeName})`}
                  </Typography>
                </Link>
              </Stack>
            ) : (
              <Skeleton variant="rectangular" width={400} height={20} />
            )}
            {!history.sourceId && isLoaded ? (
              <Stack direction="row">
                {history.parentName ? (
                  <Typography
                    variant="body2"
                    sx={{ color: theme => theme.palette.text.secondary }}
                  >
                    ... / {history.parentName} /
                  </Typography>
                ) : (
                  <Typography
                    variant="body2"
                    sx={{ color: theme => theme.palette.text.secondary }}
                  >
                    ... / {project?.shortTitle} /
                  </Typography>
                )}
              </Stack>
            ) : (
              !history.sourceId && (
                <Skeleton variant="rectangular" width={300} height={20} />
              )
            )}
          </div>
        </Stack>
      </Stack>
      <Stack
        direction="row"
        spacing={5}
        alignItems="center"
        style={{ marginLeft: 'auto' }}
      >
        {isLoaded ? (
          <Chip
            label={eventDetails.category}
            aria-label="category-chip"
            sx={{ backgroundColor: eventDetails.color }}
          />
        ) : (
          <Skeleton variant="rectangular" width={100} height={30} />
        )}
        {isLoaded ? (
          <Typography aria-label="updated-at" variant="subtitle2">
            {formatDateWithTime(history.updatedAt)}
          </Typography>
        ) : (
          <Skeleton variant="rectangular" width={100} height={30} />
        )}
      </Stack>
      <Popover
        id={id}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={e => e.stopPropagation()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <PersonCard
          isExpanded={false}
          personQuery={history.triggeredBy.email}
        />
      </Popover>
    </Stack>
  );
};

AuditAccordionHeader.propTypes = {
  isLoaded: PT.bool.isRequired,
  history: historyPropTypes.isRequired,
};

export default AuditAccordionHeader;
