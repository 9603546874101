import { startCase, camelCase, unescape } from 'lodash';
import { Grid, Typography, TextField } from '@mui/material';
import PT from 'prop-types';
import { StyledGrid } from './parameter-styles';

const Parameter = ({
  parameterName,
  parameterValue,
  parametersMissing,
  valuesMissing,
}) => {
  const isParameterMissing = parametersMissing?.find(
    param => param.name === parameterName
  );
  const isValueMissing = valuesMissing?.find(
    param => param.name === parameterName
  );

  const textColour = () => {
    if (isParameterMissing) return 'red';
    if (isValueMissing) return 'darkOrange';
    return null;
  };
  const parameterText = () => {
    if (isParameterMissing) return `(${'parameter not found'})`;
    if (isValueMissing) return `(${'missing value'})`;
    return unescape(parameterValue) || '';
  };

  return (
    <StyledGrid
      item
      xs={12}
      sm={6}
      container
      spacing={1}
      padding={0.5}
      aria-label="parameter"
    >
      <Grid item xs={6} sm={6}>
        <Typography align="right" justifySelf="center" color={textColour}>
          {startCase(camelCase(parameterName))}
        </Typography>
      </Grid>
      <Grid item xs={6} sm={6}>
        <TextField
          disabled
          fullWidth
          variant="standard"
          size="small"
          value={parameterText()}
        />
      </Grid>
    </StyledGrid>
  );
};

Parameter.propTypes = {
  parameterName: PT.string.isRequired,
  parameterValue: PT.oneOfType([PT.string, PT.number]).isRequired,
  parametersMissing: PT.arrayOf(PT.shape({})).isRequired,
  valuesMissing: PT.arrayOf(PT.shape({})).isRequired,
};
export default Parameter;
